<template>
  <znl-dialog
    :visible="visible"
    width="850px"
    height="500px"
    :is-footer-show="false"
    class="dialog-stsunpayment-detail-box dialog-grid-footer-box"
    title="入库明细"
    @close="()=>{$emit('close')}"
  >
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :total-count="totalCount"
    ></znl-table>
  </znl-dialog>
</template>
<script>


const CONFIG = {
  searchURL: 'StsUnPayment/SearchBySupplierName'
}

import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { setTimeout } from 'timers';

export default {
  name: 'StsUnPaymentDetail',
  mixins: [getCommonDataMixin],
  config: CONFIG,
  components: {
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      filterFields: {},
      searchFields: {},
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      loading: true
    }
  },
  props: {
    onlyCountUnPayment: Boolean,
    stkSupplierName: String,
    stkReceiverName: String, // 收货人/采购员
    startTime: null, // 开始时间
    endTime: null, // 结束时间
    showstsDetail: Boolean, // 是否需要刷新页面
    visible: Boolean
  },
  async created() {

  },
  async mounted() {
    setTimeout(() => {
      this.onInit()
    }, 40)
  },
  methods: {
    onInit: async function () {
      let gridConfig = [
        { CTitle: '收货员', BindField: 'Receiver', IsShow: true, IsReadonly: true, ColumnWidth: 80, DisplayIndex: 1, DataType: 1, IsSystem: false, IsRequired: true },
        { CTitle: '入库单号', BindField: 'BillNo', IsShow: true, IsReadonly: true, ColumnWidth: 90, DisplayIndex: 1, DataType: 1, IsSystem: false, IsRequired: true },
        { CTitle: '入库时间', BindField: 'ReceiveTime', IsShow: true, IsReadonly: true, ColumnWidth: 72, DisplayIndex: 2, DataType: 1, IsSystem: true, IsRequired: true },
        { CTitle: '型号', BindField: 'Model', IsShow: true, IsReadonly: true, ColumnWidth: 96, DisplayIndex: 3, DataType: 1, IsSystem: false, IsRequired: true },
        { CTitle: '品牌', BindField: 'Brand', IsShow: true, IsReadonly: true, ColumnWidth: 60, DisplayIndex: 4, DataType: 1, IsSystem: false, IsRequired: true },
        { CTitle: '封装', BindField: 'Packaging', IsShow: true, IsReadonly: true, ColumnWidth: 60, DisplayIndex: 5, DataType: 1, IsSystem: false, IsRequired: true },
        { CTitle: '年份', BindField: 'MakeYear', IsShow: true, IsReadonly: true, ColumnWidth: 60, DisplayIndex: 6, DataType: 1, IsSystem: false, IsRequired: true },
        { CTitle: '数量', BindField: 'Qty', IsShow: true, IsReadonly: true, ColumnWidth: 60, DisplayIndex: 7, DataType: 1, IsSystem: false, IsRequired: true },
        { CTitle: '单价', BindField: 'Price', IsShow: true, IsReadonly: true, ColumnWidth: 60, DisplayIndex: 8, DataType: 1, IsSystem: false, IsRequired: true },
        { CTitle: '币种', BindField: 'CurrencyName', IsShow: true, IsReadonly: true, ColumnWidth: 48, DisplayIndex: 9, DataType: 1, IsSystem: false, IsRequired: true },
        { CTitle: '采购额', BindField: 'Amount', IsShow: true, IsReadonly: true, ColumnWidth: 60, DisplayIndex: 10, DataType: 1, IsSystem: false, IsRequired: true },
        { CTitle: '已付金额', BindField: 'PayAmount', IsShow: true, IsReadonly: true, ColumnWidth: 60, DisplayIndex: 11, DataType: 1, IsSystem: false, IsRequired: true },
        { CTitle: '折扣金额', BindField: 'DiscountAmount', IsShow: true, IsReadonly: true, ColumnWidth: 60, DisplayIndex: 12, DataType: 1, IsSystem: false, IsRequired: true },
        { CTitle: '未付金额', BindField: 'UnPayAmount', IsShow: true, IsReadonly: true, ColumnWidth: 60, DisplayIndex: 13, DataType: 1, IsSystem: false, IsRequired: true }
      ]
      let columns = gridConfig
      this.columns = columns

      this.$refs.table.init()
      await this.onBindData()
    },
    async onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      let data = _.extend({}, {
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }, {
          ReceiverName: this.stkReceiverName,
          SupplierName: this.stkSupplierName,
          OnlyCountUnPayment: this.onlyCountUnPayment,
          StartReceiveTime: this.startTime,
          EndReceiveTime: this.endTime
        })
      this.loading = true
      return await this.$post(CONFIG.searchURL, data, (datas) => {
        this.pageIndex = pageIndex
        this.itemSource = datas.ResultList
        this.totalCount = datas.TotalCount
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">
</style>
