<template>
  <znl-gridmodule ref="flexGrid"
              header="产品分析"
              height="100%"
              :columns="columns"
              :item-source="itemSource"
              :column-colors="columnColors"
              :row-colors="rowColors"
              :summary-columns="summaryColumns"
              :is-init="false"
              :has-znl-actions="true"
              :is-fields-search="true"
              :is-multi-rows-check="false"
              :search-fields="searchModel"
              :page-size="pageSize"
              :page-index="pageIndex"
              :total-count="totalCount"
              :role="role"
              :on-init="onInit"
              :on-refresh="onRefresh"
              :on-search="onSearch"
              :on-save-setting="onSaveSetting"
              :on-save-config='onSaveConfig'
              :on-reset-setting="onResetSetting"
              :on-page-changed="onBindData"
              :is-action-from='false'
              :is-btn='true'
              :loading="loading"
              @page-loading="d=>{$emit('page-loading', d)}"
              @selection-changed="d=>{$emit('selection-changed',d)}">

              <div slot="action-form">
                <!-- <el-button  type="default" class="form-group znl-btn-mini-auxiliary" size="mini">
               <i class="iconfont icon-sort_btn_ic"></i><span class="ver">排序方式</span>
            </el-button> -->
            <znl-input size="mini"
                        width='105px'
                        layout="left"
                        form-type="select"
                        placeholder="最近入库时间"
                        popper-class="popperClass"
                        :is-must-fill="false"
                        :clearable="true"
                         @change="OrderBychange"
                        :select-options="TimeData"
                        v-model="fieldsFilter.InLastDateSort">
            </znl-input>
                <znl-input size="mini"
                        width='105px'
                        layout="left"
                        form-type="select"
                        placeholder="最近出库时间"
                        popper-class="popperClass"
                        :is-must-fill="false"
                        :clearable="true"
                         @change="OrderBychange"
                        :select-options="TimeData"
                        v-model="fieldsFilter.OutLastDateSort">
                </znl-input>
                 <znl-input size="mini"
                        width='105px'
                        layout="left"
                        form-type="select"
                        placeholder="最近询价时间"
                        popper-class="popperClass"
                        :is-must-fill="false"
                        :clearable="true"
                         @change="OrderBychange"
                        :select-options="TimeData"
                        v-model="fieldsFilter.InqLastDateSort">
                </znl-input>
                 <!-- 因库存是统计结果，不好做排序
                   <znl-input size="mini"
                        width='105px'
                        layout="left"
                        form-type="select"
                        placeholder="库存量"
                        popper-class="popperClass"
                        :is-must-fill="false"
                        :clearable="true"
                          @change="OrderBychange"
                        :select-options="QtyData"
                        v-model="fieldsFilter.StkQtySort">
                </znl-input> -->
              <znl-input size="mini"
                        width='90px'
                        layout="left"
                        form-type="select"
                        placeholder="最近出库量"
                        popper-class="popperClass"
                        :is-must-fill="false"
                        :clearable="true"
                         @change="OrderBychange"
                        :select-options="QtyData"
                        v-model="fieldsFilter.OutLastQtySort">
            </znl-input>
                <znl-input size="mini"
                        width='90px'
                        layout="left"
                        form-type="select"
                        placeholder="最近入库量"
                        popper-class="popperClass"
                        :is-must-fill="false"
                        :clearable="true"
                         @change="OrderBychange"
                        :select-options="QtyData"
                        v-model="fieldsFilter.InLastQtySort">
                </znl-input>
                 <znl-input size="mini"
                        width='90px'
                        layout="left"
                        form-type="select"
                        placeholder="当月入库量"
                        popper-class="popperClass"
                        :is-must-fill="false"
                        :clearable="true"
                         @change="OrderBychange"
                        :select-options="QtyData"
                        v-model="fieldsFilter.InMonthQtySort">
                </znl-input>
                 <znl-input size="mini"
                        width='90px'
                        layout="left"
                        form-type="select"
                        placeholder="当月出库量"
                        popper-class="popperClass"
                        :is-must-fill="false"
                        :clearable="true"
                          @change="OrderBychange"
                        :select-options="QtyData"
                        v-model="fieldsFilter.OutMonthQtySort">
                </znl-input>

                <model-stk-in-chart
                        :visible="modelStkInChartVisible"
                        :searchModel="modelSummarySearchModel"
                        :refresh="refreshModel"
                        @close="()=>{modelStkInChartVisible=false}"
                        :initData="true"
                        ></model-stk-in-chart>
                <model-stk-out-chart
                        :visible="modelStkOutChartVisible"
                        :searchModel="stkOutDiagramJson"
                        :refresh="refreshModelStkOut"
                        @close="()=>{modelStkOutChartVisible=false}"
                        :initData="true"
                        ></model-stk-out-chart>
                <model-inquire-chart
                        :visible="modelInquireChartVisible"
                        :searchModel="InquireDiagramJson"
                        :refresh="refreshModelInquire"
                        @close="()=>{modelInquireChartVisible=false}"
                        :initData="true"
                        ></model-inquire-chart>
              </div>
  </znl-gridmodule>
</template>
<script>
import {mixin as znlMethodsMixin} from '@c_common/znlGrid/scripts/znlMethods'
import ModelStkInChart from '@c_modules/Rpt/ModelStkInChart'
import ModelStkOutChart from '@c_modules/Rpt/ModelStkOutChart'
import ModelInquireChart from '@c_modules/Rpt/ModelInquireChart'
const CONFIG = {
  configURL: 'AlyStock/GetConfig',
  saveConfigURL: 'AlyStock/SaveConfig',
  resetConfigURL: 'AlyStock/ResetConfig',
  searchURL: 'AlyStock/StkIn'
}

export default {
  name: 'StsModel',
  mixins: [znlMethodsMixin],
  config: CONFIG,
  components: {
    ModelStkInChart,
    ModelStkOutChart,
    ModelInquireChart
  },
  data () {
    return {
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {
        InLastDateSort: 0, // 最近入库时间
        OutLastDateSort: 0, // 最近出库时间
        InqLastDateSort: 0, // 最近询价时间
        StkQtySort: 0, // 库存量
        OutLastQtySort: 0, // 最近出库量
        InLastQtySort: 0, // 最近入库量
        InMonthQtySort: 0, // 当月入库量
        OutMonthQtySort: 0// 当月出库量
      },
      searchFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      loading: false,
      modelStkInChartVisible: false,
      modelSummarySearchModel: {},
      refreshModel: true,
      modelStkOutChartVisible: false,
      stkOutDiagramJson: {},
      refreshModelStkOut: true,
      modelInquireChartVisible: false,
      InquireDiagramJson: {},
      orderbyAsc: true,
      orderbyDesc: false,
      orderby: 0,
      refreshModelInquire: true,
      TimeData:
      [
        { key: 1, value: '由近到远' },
        { key: 2, value: '由远到近' }
      ],
      QtyData:
      [
        { key: 1, value: '由大到小' },
        { key: 2, value: '由小到大' }
      ]
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String
  },
  methods: {
    handleCommand (command) {
      this.command = command
      this.orderby = command
      this.changeType()
    },
    async changeType () {
      this.loading = true
      this.filterFields = _.extend({}, this.fieldsFilter, {OrderBy: this.orderby, IsAsc: this.orderbyAsc})
      await this.onBindData(1)
      this.loading = false
      return
    },
    async onInit (config, refresh) {
      if (!config || config === undefined) {
        // config = await this.$post(this.$options.config.configURL)
        config = await this.isSaveStorage('stsModel', this.$options.config.configURL)
      }
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      this.defaultConfig = config
      this.columns = columns
      _.each(columns, column => {
        if (column.binding === 'InLastAmount' ||
            column.binding === 'InMonthAmount' ||
            column.binding === 'InMonthAmountReturn' ||
            column.binding === 'InLTMAmount' ||
            column.binding === 'InLTMAmountReturn'
            ) {
          // console.log(column.binding, '123')
          column.buttons = [{
            'content': (row) => {
              if (column.binding === 'InLastAmount') {
                return row.InLastAmount || ''
              } else if (column.binding === 'InMonthAmount') {
                return row.InMonthAmount || ''
              } else if (column.binding === 'InMonthAmountReturn') {
                return row.InMonthAmountReturn || ''
              } else if (column.binding === 'InLTMAmount') {
                return row.InLTMAmount || ''
              } else if (column.binding === 'InLTMAmountReturn') {
                return row.InLTMAmountReturn || ''
              }
            },
            'className': 'znl-table-btn-success',
            'when': true,
            'click': (row) => {
              this.openStkIn(row)
            }
          }]
          column.dataType = 5
        } else if (column.binding === 'OutLastAmount' ||
            column.binding === 'OutMonthAmount' ||
            column.binding === 'OutMonthAmountReturn' ||
            column.binding === 'OutLTMAmount' ||
            column.binding === 'OutLTMAmountReturn'
            ) {
          column.buttons = [{
            'content': (row) => {
              if (column.binding === 'OutLastAmount') {
                return row.OutLastAmount || ''
              } else if (column.binding === 'OutMonthAmount') {
                return row.OutMonthAmount || ''
              } else if (column.binding === 'OutMonthAmountReturn') {
                return row.OutMonthAmountReturn || ''
              } else if (column.binding === 'OutLTMAmount') {
                return row.OutLTMAmount || ''
              } else if (column.binding === 'OutLTMAmountReturn') {
                return row.OutLTMAmountReturn || ''
              }
            },
            'className': 'znl-table-btn-success',
            'when': true,
            'click': (row) => {
              this.openStkOut(row)
            }
          }]
          column.dataType = 5
        } else if (column.binding === 'InqLastPriceAvg' ||
            column.binding === 'InqMonthPriceAvg' ||
            column.binding === 'InqLTMPriceAvg'
            ) {
          column.buttons = [{
            'content': (row) => {
              if (column.binding === 'InqLastPriceAvg') {
                return row.InqLastPriceAvg || ''
              } else if (column.binding === 'InqMonthPriceAvg') {
                return row.InqMonthPriceAvg || ''
              } else if (column.binding === 'InqLTMPriceAvg') {
                return row.InqLTMPriceAvg || ''
              }
            },
            'className': 'znl-table-btn-success',
            'when': true,
            'click': (row) => {
              this.openInquire(row)
            }
          }]
          column.dataType = 5
        }
      })
      this.role = config.Role
      this.pageSize = config.PageSize
      this.resourceList = config.ResourceList.map(item => item.Code)
      if (this.initData || refresh) {
        this.onBindData()
      }
    },
    async onBindData (pageIndex = 1) {
      let filterFields = {
        // 外部搜索条件
        InLastDateSort: this.fieldsFilter.InLastDateSort === '' ? 0 : this.fieldsFilter.InLastDateSort, // 最近入库时间
        OutLastDateSort: this.fieldsFilter.OutLastDateSort === '' ? 0 : this.fieldsFilter.OutLastDateSort, // 最近出库时间
        InqLastDateSort: this.fieldsFilter.InqLastDateSort === '' ? 0 : this.fieldsFilter.InqLastDateSort, // 最近询价时间
        StkQtySort: this.fieldsFilter.StkQtySort === '' ? 0 : this.fieldsFilter.StkQtySort, // 库存量
        OutLastQtySort: this.fieldsFilter.OutLastQtySort === '' ? 0 : this.fieldsFilter.OutLastQtySort, // 最近出库量
        InLastQtySort: this.fieldsFilter.InLastQtySort === '' ? 0 : this.fieldsFilter.InLastQtySort, // 最近入库量
        InMonthQtySort: this.fieldsFilter.InMonthQtySort === '' ? 0 : this.fieldsFilter.InMonthQtySort, // 当月入库量
        OutMonthQtySort: this.fieldsFilter.OutMonthQtySort === '' ? 0 : this.fieldsFilter.OutMonthQtySort // 当月出库量
      }

      if (this.filterFields) {
        _.each(this.filterFields, (value, key) => {
          filterFields[key.substring(0, 1).toUpperCase() + key.substring(1)] = value
        })
      }
      console.log(filterFields)
      let response = await this.$post(this.$options.config.searchURL, _.extend({
        FieldWhereString: JSON.stringify(this.searchFields),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }, filterFields))
      this.pageIndex = pageIndex
      this.itemSource = response.ResultList
      this.totalCount = response.TotalCount
      this.checkedRows = []
      this.checkedRowsCount = 0
      this.$emit('page-loading', false)
      this.gridShow = true
    },
    async onSearch (params = {}) {
      this.searchFields = {}
      _.extend(this.searchFields, params)
      await this.onBindData(1)
      this.checkedRows = []
      this.checkedRowsCount = 0
    },
    async onSaveSetting (columns, pageSize) {
      localStorage.removeItem('stsModel')
      await this.$post(this.$options.config.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.defaultConfig))
    },
    async onResetSetting () {
      localStorage.removeItem('stsModel')
      await this.$post(this.$options.config.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('stsModel')
      return this.$post(this.$options.config.saveConfigURL, this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig))
    },
    async onRefresh () {
      await this.onBindData(this.pageIndex)
    },
    onOrderChange (isAsc) {
      this.orderbyAsc = isAsc
      this.orderbyDesc = !isAsc
      this.changeType()
    },
    openStkIn (row) {
      this.modelSummarySearchModel = row.InDiagramJson
      this.refreshModel = !this.refreshModel
      this.modelStkInChartVisible = true
    },
    openStkOut (row) {
      this.stkOutDiagramJson = row.OutDiagramJson
      this.refreshModelStkOut = !this.refreshModelStkOut
      this.modelStkOutChartVisible = true
    },
    openInquire (row) {
      this.InquireDiagramJson = row.InqDiagramJson
      this.refreshModelInquire = !this.refreshModelInquire
      this.modelInquireChartVisible = true
    },
    OrderBychange () {
      setTimeout(() => {
        this.onBindData(1)// 查询
      }, 100)
    }
  }
}
</script>

<style lang="scss">
.znl-btn-mini-auxiliary{
  &>span{
    display: flex;
  }
}
</style>

