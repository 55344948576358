<template>
  <znl-gridmodule
    ref="flexGrid"
    :grid-show="gridShow"
    :columns="columns"
    height="100%"
    class="znl-stsunpayment-box"
    :has-znl-actions="true"
    :is-btn="false"
    :item-source="itemSource"
    :column-colors="columnColors"
    :row-colors="rowColors"
    :summary-columns="summaryColumns"
    :is-fields-search="isShowFiedsSearch"
    :search-fields="searchModel"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    :role="role"
    :on-init="onInit"
    :is-init="true"
    :on-refresh="onRefresh"
    :on-search="onSearch"
    :on-page-changed="onBindData"
    :on-save-setting="onSaveSetting"
    :on-save-config="onSaveConfig"
    :on-reset-setting="onResetSetting"
    :users="$store.state.users"
    @page-loading="d=>{$emit('page-loading', d)}"
    @page-changed="onBindData"
  >
    <div slot="heading-slot">
      <znl-input
        form-type="datepicker"
        :disabled="false"
        placeholder="入库日期"
        size="mini"
        layout="left"
        width="100px"
        :clearable="true"
        inp-perc="100%"
        :border="true"
        v-model="filterFields.StartReceiveTime"
      ></znl-input>
      <span>到</span>
      <znl-input
        form-type="datepicker"
        :disabled="false"
        placeholder="入库日期"
        size="mini"
        layout="left"
        width="100px"
        :clearable="true"
        inp-perc="100%"
        :border="true"
        v-model="filterFields.EndReceiveTime"
      ></znl-input>
      <znl-button type="multiple" :height="22" style-type="mac" class="box-left-btn search-btn">
        <znl-button-menu @click="$refs.flexGrid.search()">
          <i class="iconfont icon-search_ic"></i>
          <span>搜索</span>
        </znl-button-menu>
        <znl-button-menu :width="12" tip="高级搜索" @click="onShowFiedsSearch()">
          <i class="iconfont icon-arrow-drop-down_ic"></i>
        </znl-button-menu>
      </znl-button>
      <el-checkbox
        v-model="filterFields.OnlyCountUnPayment"
        size="small"
        @change="()=>{$refs.flexGrid.search()}"
      >仅显示未付</el-checkbox>
    </div>
    <div slot="znl-footer-slot" class="total-data-footer">
      <el-row>
        <el-col :span="12">
          <span>
            未付总金额:
            <span
              class="stsPurchaseIn-amount"
            >{{this.SumSearchList.UnPaymentAmountBySupplier}}</span>
          </span>
          <span style="padding:0 20px;">
            采购总金额:
            <span class="stsPurchaseIn-amount">{{this.SumSearchList.TotalAmountBySupplier}}</span>
          </span>
          <span>
            含税总金额:
            <span class="stsPurchaseIn-amount">{{this.SumSearchList.InTaxAmountBySupplier}}</span>
          </span>
        </el-col>
      </el-row>
    </div>
    <StsUnPaymentDetail
      ref="StkStockLog"
      v-if="showStsDetail"
      :visible="showStsDetail"
      :onlyCountUnPayment="filterFields.OnlyCountUnPayment"
      :stkSupplierName="stsSupplierName"
      :stkReceiverName="stkReceiverName"
      :startTime="filterFields.StartReceiveTime"
      :endTime="filterFields.EndReceiveTime"
      @close="()=>{showStsDetail=false}"
      :showstsDetail="refreshStsDetail"
    ></StsUnPaymentDetail>
  </znl-gridmodule>
</template>
<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import StsUnPaymentDetail from '@c_modules/Sts/StsUnPaymentDetail'
const CONFIG = {
  configURL: 'StsUnPayment/GetConfig',
  saveConfigURL: 'StsUnPayment/SaveConfig',
  resetConfigURL: 'StsUnPayment/ResetConfig',
  default: null,
  searchURL: 'StsUnPayment/Search',
  searchSumListURL: 'StsUnPayment/SumList'
}

export default {
  name: 'StsUnPayment',
  mixins: [znlMethodsMixin],
  config: CONFIG,
  components: {
    StsUnPaymentDetail
  },
  data () {
    return {
      isShowFiedsSearch: false,
      columns: [],
      itemSource: [],
      searchFields: {},
      columnColors: [],
      filterFields: {
        StartReceiveTime: '',
        EndReceiveTime: '',
        OnlyCountUnPayment: true
      },
      SumSearchList: [], // 统计集合,
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      showStsDetail: false, // 是否显示日志
      refreshStsDetail: true, // 是否刷新库存日志页面
      stsSupplierName: '', // 传递供应商名称
      stkReceiverName: '', // 收货员 采购员
      resourceList: [], // 权限列表，
      gridShow: false
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String
  },
  methods: {
    onInit: async function () {
      // let config = await this.$post(CONFIG.configURL)
      let config = await this.isSaveStorage('StsUnPayment', CONFIG.configURL)
      this.resourceList = config.ResourceList.map(item => item.Code)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      _.each(columns, column => {
        if (column.binding === 'TotalAmountBySupplier') {
          column.buttons = [{
            'content': (row) => {
              return row.TotalAmountBySupplier || ''
            },
            'className': 'znl-table-btn-success',
            'when': true,
            'click': (row) => {
              this.stsSupplierName = row.SupplierName
              this.stkReceiverName = row.Receiver
              this.showStsDetail = true
              this.refreshStsDetail = !this.refreshStsDetail// 改变状态进行刷新
            }
          }]
          column.dataType = 5
        }
      })

      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      this.initData && this.onBindData()
    },
    async onBindData (pageIndex = 1, type) {
      if (!type) {
        this.loading = true
      }
      let filterFields = {}
      if (this.filterFields) {
        _.each(this.filterFields, (value, key) => {
          filterFields[key.substring(0, 1).toUpperCase() + key.substring(1)] = value
        })
      }

      this.SumSearchList = await this.$post(CONFIG.searchSumListURL, _.extend({
        FieldWhereString: JSON.stringify(this.searchFields),
        PageIndex: pageIndex,
        PageSize: this.pageSize,
        OrderBy: this.OrderBySearchParams
      }, filterFields))
      let response = await this.$post(CONFIG.searchURL, _.extend({
        FieldWhereString: JSON.stringify(this.searchFields),
        PageIndex: pageIndex,
        PageSize: this.pageSize,
        OrderBy: this.OrderBySearchParams
      }, filterFields))

      this.pageIndex = pageIndex
      this.itemSource = response.ResultList
      this.totalCount = response.TotalCount
      this.checkedRows = []
      this.checkedRowsCount = 0
      this.loading = false
      this.gridShow = true
    },
    onSaveSetting (columns, pageSize) {
      localStorage.removeItem('StsUnPayment')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, CONFIG.default))
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('StsUnPayment')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler([column], this.pageSize, CONFIG.default))
    },
    onShowFiedsSearch () {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true
      } else {
        this.isShowFiedsSearch = false
      }
    },
    onResetSetting () {
      localStorage.removeItem('StsUnPayment')
      return this.$post(CONFIG.resetConfigURL)
    },
    hasRes (code) {
      return _.includes(this.resourceList, code)
    } }
}
</script>

<style lang="scss">
.znl-stsunpayment-box {
  .el-col-12 {
    width: 100%;
  }
  .stsPurchaseIn-amount {
    color: rgb(255, 0, 0);
  }
}
</style>

