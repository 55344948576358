<template>
  <znl-gridmodule ref="flexGrid"
              header="员工业绩统计"
              :height="height"
              :columns="columns"
              :item-source="itemSource"
              :column-colors="columnColors"
              :row-colors="rowColors"
              :summary-columns="summaryColumns"
              :is-fields-search="true"
              :is-multi-rows-check="false"
              :search-fields="searchModel"
              :page-size="pageSize"
              :page-index="pageIndex"
              :total-count="totalCount"
              :role="role"
              :on-init="onInit"
              :on-refresh="onRefresh"
              :on-search="onSearch"
              :on-save-setting="onSaveSetting"
              :on-save-config='onSaveConfig'
              :on-reset-setting="onResetSetting"
              :on-page-changed="onBindData"
              :is-action-from='false' 没有actionfrom时隐藏
              :is-btn='true'
              @selection-changed="d=>{$emit('selection-changed',d)}">

              <div slot="action-buttons">
                <el-row>
                  <el-dropdown @command="handleCommand">
                    <el-button  type="default" class="form-group" size="mini">
                        <i class="iconfont icon-sort_btn_ic"></i><span class="ver">排序方式</span>
                    </el-button>

                      <el-dropdown-menu slot="dropdown" >
                        <el-dropdown-item command=1>最近下单时间升序</el-dropdown-item>
                        <el-dropdown-item command=2>最近下单时间降序</el-dropdown-item>
                        <el-dropdown-item command=3>当月下单量升序</el-dropdown-item>
                        <el-dropdown-item command=4>当月下单量降序</el-dropdown-item>
                        <el-dropdown-item command=5>当月下单额升序</el-dropdown-item>
                        <el-dropdown-item command=6>当月下单额降序</el-dropdown-item>
                      </el-dropdown-menu>
                  </el-dropdown>
                </el-row>
              </div>
  </znl-gridmodule>
</template>
<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'

const CONFIG = {
  configURL: 'AlySales/GetConfig',
  saveConfigURL: 'AlySales/SaveConfig',
  resetConfigURL: 'AlySales/ResetConfig',
  searchURL: 'AlySales/Search'
}

export default {
  name: 'StsStaff',
  mixins: [znlMethodsMixin],
  config: CONFIG,
  components: {
  },
  data () {
    return {
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      searchFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1'
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String
  },
  methods: {
    handleCommand (command) {
      // console.log(command)
      this.command = command
      this.changeType(this.command)
    },
    changeType (params) {
      // this.fieldsFilter = _.extend({}, this.fieldsFilter, {command: params})
      this.filterFields = _.extend({}, this.fieldsFilter, { OrderBy: params - 0 })
      return this.onBindData(1)
    }
  }
}
</script>

<style lang="scss">
</style>

