<template>
  <znl-dialog
    :visible="visible"
    width="1100px"
    height="500px"
    :is-footer-show="false"
    class="dialog-stsunreceive-salesdetail-box dialog-grid-footer-box"
    title="发货明细"
    @close="()=>{$emit('close')}"
  >
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :total-count="totalCount"
    ></znl-table>
  </znl-dialog>
</template>
<script>


const CONFIG = {
  searchURL: 'StsUnReceive/SearchByCustomerName'
}

export default {
  name: 'StsUnReceiveDetail',
  mixins: [],
  config: CONFIG,
  components: {},
  data() {
    return {
      columns: [],
      itemSource: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      loading: true
    }
  },
  props: {
    onlyCountUnReceive: Boolean,
    stkCustomerName: String,
    stkSalesName: String,
    startTime: null, // 开始时间
    endTime: null, // 结束时间
    visible: Boolean
  },
  async created() {

  },
  async mounted() {
    setTimeout(() => {
      this.onInit()
    }, 40)
  },
  methods: {
    onInit: async function () {
      let gridConfig = [
        {
          CTitle: '销售员',
          BindField: 'ShipperName',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: '单号',
          BindField: 'BillNo',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 110,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        }, {
          CTitle: '发货日期',
          BindField: 'ShipTime',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 90,
          DisplayIndex: 2,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },

        {
          CTitle: '型号',
          BindField: 'Model',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 3,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: '品牌',
          BindField: 'Brand',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 40,
          DisplayIndex: 4,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: '封装',
          BindField: 'Packaging',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 40,
          DisplayIndex: 5,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: '年份',
          BindField: 'MakeYear',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 40,
          DisplayIndex: 6,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: '数量',
          BindField: 'Qty',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 7,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: '单价',
          BindField: 'Price',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 8,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: '币种',
          BindField: 'CurrencyName',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 9,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: '销售额',
          BindField: 'Amount',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 100,
          DisplayIndex: 10,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: '已收金额',
          BindField: 'ReceiveAmount',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 100,
          DisplayIndex: 11,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: '折扣金额',
          BindField: 'DiscountAmount',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 100,
          DisplayIndex: 12,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: '未收金额',
          BindField: 'UnReceiveAmount',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 100,
          DisplayIndex: 13,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        }
      ]
      let columns = gridConfig
      this.columns = columns
      this.$refs.table.init()
      await this.onBindData()
    },
    async onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      let data = _.extend({},
        {
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          CustomerName: this.stkCustomerName,
          ShipperName: this.stkSalesName,
          OnlyCountUnReceive: this.onlyCountUnReceive,
          StartOutOn: this.startTime,
          EndOutOn: this.endTime
        }
      )
      this.loading = true
      return await this.$post(CONFIG.searchURL, data, datas => {
        this.pageIndex = pageIndex
        this.itemSource = datas.ResultList
        this.totalCount = datas.TotalCount
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">
</style>
