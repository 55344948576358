<template>
  <znl-gridmodule
    ref="flexGrid"
    :columns="columns"
    height="100%"
    class="znl-stsunreceive-box"
    :grid-show="gridShow"
    :has-znl-actions="true"
    :is-btn="false"
    :item-source="itemSource"
    :column-colors="columnColors"
    :row-colors="rowColors"
    :summary-columns="summaryColumns"
    :is-fields-search="isShowFiedsSearch"
    :search-fields="searchModel"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    :role="role"
    :on-init="onInit"
    :is-init="true"
    :init-data="initData"
    :on-refresh="onRefresh"
    :on-search="onSearch"
    :on-page-changed="onBindData"
    :on-save-setting="onSaveSetting"
    :on-save-config="onSaveConfig"
    :on-reset-setting="onResetSetting"
    :users="$store.state.users"
    @page-loading="d=>{$emit('page-loading', d)}"
    @page-changed="onBindData"
  >
    <div slot="heading-slot">
      <znl-input
        form-type="datepicker"
        :disabled="false"
        placeholder="出货日期"
        size="mini"
        layout="left"
        width="100px"
        :clearable="true"
        :border="true"
        inp-perc="100%"
        v-model="filterFields.startOutOn"
      ></znl-input>
      <span>到</span>
      <znl-input
        form-type="datepicker"
        :disabled="false"
        placeholder="出货日期"
        size="mini"
        layout="left"
        width="100px"
        :border="true"
        :clearable="true"
        inp-perc="100%"
        v-model="filterFields.endOutOn"
      ></znl-input>
      <znl-button type="multiple" :height="22" style-type="mac" class="box-left-btn search-btn">
        <znl-button-menu @click="$refs.flexGrid.search()">
          <i class="iconfont icon-search_ic"></i>
          <span>搜索</span>
        </znl-button-menu>
        <znl-button-menu :width="12" tip="高级搜索" @click="onShowFiedsSearch()">
          <i class="iconfont icon-arrow-drop-down_ic"></i>
        </znl-button-menu>
      </znl-button>
      <el-checkbox
        v-model="filterFields.OnlyCountUnReceive"
        size="small"
        @change="()=>{$refs.flexGrid.search()}"
      >仅显示未收</el-checkbox>
    </div>
    <div slot="znl-footer-slot" class="total-data-footer">
      <el-row>
        <el-col :span="12">
          <span>
            未收总金额:
            <span
              class="stsPurchaseIn-amount"
            >{{this.SumSearchList.UnReceiveAmountByCustomer}}</span>
          </span>
          <span style="padding:0 20px;">
            销售总金额:
            <span class="stsPurchaseIn-amount">{{this.SumSearchList.TotalAmountByCustomer}}</span>
          </span>
          <span>
            含税总金额:
            <span class="stsPurchaseIn-amount">{{this.SumSearchList.InTaxAmountByCustomer}}</span>
          </span>
        </el-col>
      </el-row>
    </div>
    <StsUnReceiveSalesDetail
      ref="StkStockLog"
      v-if="showStsDetail"
      :visible="showStsDetail"
      :onlyCountUnReceive="filterFields.OnlyCountUnReceive"
      :stkCustomerName="stsCustomerName"
      :stkSalesName="stsSalesName"
      @close="()=>{showStsDetail=false}"
      :showstsDetail="refreshStsDetail"
    >
      <!-- :startTime="filterFields.startOutOn"
      :endTime="filterFields.endOutOn"-->
    </StsUnReceiveSalesDetail>
  </znl-gridmodule>
</template>

<script>

import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import StsUnReceiveSalesDetail from '@c_modules/Sts/StsUnReceiveSalesDetail'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
const CONFIG = {
  configURL: 'StsUnReceive/GetConfig',
  saveConfigURL: 'StsUnReceive/SaveConfig',
  resetConfigURL: 'StsUnReceive/ResetConfig',
  default: null,
  searchURL: 'StsUnReceive/Search',
  searchSumListURL: 'StsUnReceive/SumReceiveList'
}

export default {
  name: 'StsUnReceive',
  mixins: [znlMethodsMixin, getCommonDataMixin],
  config: CONFIG,
  components: {
    StsUnReceiveSalesDetail
  },
  data () {
    return {
      isShowFiedsSearch: false,
      columns: [],
      itemSource: [],
      searchFields: {},
      columnColors: [],
      filterFields: {
        startOutOn: '',
        endOutOn: '',
        OnlyCountUnReceive: true
      },
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      showStsDetail: false, // 是否显示日志
      refreshStsDetail: true, // 是否刷新库存日志页面
      stsCustomerName: '', // 传递客户名称
      resourceList: [], // 权限列表，
      SumSearchList: [], // 统计集合,
      gridShow: false,
      stsSalesName: '' // 传递销售员名称
    }
  },

  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String
  },
  methods: {
    onInit: async function () {
      // console.log('-------------------0---')
      let config = await this.isSaveStorage('StsUnReceive', CONFIG.configURL)
      // let config = await this.$post(CONFIG.configURL)
      this.resourceList = config.ResourceList.map(item => item.Code)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      _.each(columns, column => {
        if (column.binding === 'TotalAmountByCustomer') {
          column.buttons = [{
            'content': (row) => {
              return row.TotalAmountByCustomer || ''
            },
            'className': 'znl-table-btn-success',
            'when': true,
            'click': (row) => {
              this.stsCustomerName = row.CustomerName
              this.stsSalesName = row.Shipper
              this.showStsDetail = true
              this.refreshStsDetail = !this.refreshStsDetail// 改变状态进行刷新
            }
          }]
          column.dataType = 5
        }
      })
      this.gridShow = true
      // 销售员
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })

      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Shipper'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )
      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      this.initData && this.onBindData()
    },

    async onBindData (pageIndex = 1, type) {
      if (!type) {
        this.loading = true
      }
      let filterFields = {}
      if (this.filterFields) {
        _.each(this.filterFields, (value, key) => {
          filterFields[key.substring(0, 1).toUpperCase() + key.substring(1)] = value
        })
      }
      this.SumSearchList = await this.$post(CONFIG.searchSumListURL, _.extend({
        FieldWhereString: JSON.stringify(this.searchFields),
        PageIndex: pageIndex,
        PageSize: this.pageSize,
        OrderBy: this.OrderBySearchParams
      }, filterFields))
      let response = await this.$post(CONFIG.searchURL, _.extend({
        FieldWhereString: JSON.stringify(this.searchFields),
        PageIndex: pageIndex,
        PageSize: this.pageSize,
        OrderBy: this.OrderBySearchParams
      }, filterFields))
      this.pageIndex = pageIndex

      // this.SumSearchList = response.ResultList[response.ResultList.length - 1]
      // response.ResultList.pop()
      this.itemSource = response.ResultList
      this.totalCount = response.TotalCount
      this.checkedRows = []
      this.checkedRowsCount = 0
      this.loading = false
      // this.gridShow = true
    },
    onSaveSetting (columns, pageSize) {
      localStorage.removeItem('StsUnReceive')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, CONFIG.default))
    },
    onResetSetting () {
      localStorage.removeItem('StsUnReceive')
      return this.$post(CONFIG.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('StsUnReceive')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler([column], this.pageSize, CONFIG.default))
    },
    onShowFiedsSearch () {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true
      } else {
        this.isShowFiedsSearch = false
      }
    },
    hasRes (code) {
      return _.includes(this.resourceList, code)
    }
  }
}
</script>

<style lang="scss">
.znl-stsunreceive-box {
  .el-col-12 {
    width: 100%;
  }
  .stsPurchaseIn-amount {
    color: rgb(255, 0, 0);
  }
}
</style>

