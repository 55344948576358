<template>
  <znl-gridmodule ref="flexGrid"
      :columns="columns"
      :is-btn="false"
      height="100%"
      :grid-show="gridShow"
      :has-znl-actions="true"
      :item-source="itemSource"
      :column-colors="columnColors"
      :row-colors="rowColors"
      :summary-columns="summaryColumns"
      :is-fields-search="isShowFiedsSearch"
      :search-fields="searchModel"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :role="role"
      :on-init="onInit"
      :is-init="true"
      :on-refresh="onRefresh"
      :on-search="onSearch"
      :on-page-changed="onBindData"
      :on-save-setting="onSaveSetting"
      :on-save-config='onSaveConfig'
      :on-reset-setting="onResetSetting"
      :users="$store.state.users"
      :loading="loading"
      @page-loading="d=>{$emit('page-loading', d)}"
      @page-changed="onBindData">
      <div slot="heading-slot">

        <znl-input form-type="datepicker"
          :disabled="false"
          placeholder="询价日期"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width='100px'
          :clearable="true"
          :border="true"
          inp-perc="100%"
          v-model="filterFields.createdTimeStart">
        </znl-input>
        <span>到</span>

        <znl-input form-type="datepicker"
          :disabled="false"
          placeholder="询价日期"
          size="mini"
          value-format="yyyy-MM-dd"
          layout="left"
          width='100px'
          :border="true"
          :clearable="true"
          inp-perc="100%"
          v-model="filterFields.createdTimeEnd">
        </znl-input>
        <znl-button type="multiple" :height='22' style-type='mac' class="box-left-btn search-btn">
          <znl-button-menu @click="$refs.flexGrid.search()">
            <i class="iconfont icon-search_ic"></i><span>搜索</span>
          </znl-button-menu>
          <znl-button-menu :width='12' tip="高级搜索" @click="onShowFiedsSearch()">
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>
        <znl-button style-type="mac" :btns='printznlbtns' :height='22'  @click="onExport" tip="按搜索条件">
         <i class="iconfont icon-leading-out-wh_btn_i"></i>
          <span>导出</span>
        </znl-button>
        <znl-button style-type="mac" :height='22' @click="onImprot">
          <i class="iconfont icon-leading-in-wh_btn_ic"></i>
          <span>导入</span>
        </znl-button>
      </div>
      <export-column :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="setConfigColumns"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="()=>{setExportColumnVisible=false}"
      @confirm="(v)=>{setExportColumnVisible=false}">
    </export-column>
    <znl-data-import
      :type="zznlImportType"
      :show="zznlShowDataImport"
      @close="zznlShowDataImport = false"
    />
  </znl-gridmodule>
</template>

<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import ExportColumn from '@c_modules/Crm/setExportColumn'
import * as urls from '~/lib/urls'
const CONFIG = {
  configURL: 'StsInquire/GetConfig',
  saveConfigURL: 'StsInquire/SaveConfig',
  resetConfigURL: 'StsInquire/ResetConfig',
  default: null,
  searchURL: 'StsInquire/Search',
  apiBaseUrl: urls.getErpApiBaseUrl()
}

export default {
  name: 'StsInquire',
  mixins: [znlMethodsMixin, getCommonDataMixin],
  config: CONFIG,
  components: {
    ExportColumn
  },
  data () {
    return {
      isShowFiedsSearch: false,
      columns: [],
      itemSource: [],
      searchFields: {},
      columnColors: [],
      filterFields: {
        createdTimeStart: '',
        createdTimeEnd: ''
      },
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      resourceList: [], // 权限列表，
      gridShow: false,
      printznlbtns: [
        {
          name: '设置导出列',
          // iconName: 'icon-table_set_btn_n',
          click: () => {
            this.showExportColumn()
          }
        }
      ],
      setExportColumnVisible: false,
      addColumnsList: [],
      setConfigColumns: {},
      setSaveConfigURL: '',
      searchCondition: {},
      loading: false
    }
  },
  computed: {
    rightMenus () {
      return []
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String
  },
  methods: {
    onInit: async function () {
      let config = await this.isSaveStorage('stsInquire', CONFIG.configURL)
      // let config = await this.$post(CONFIG.configURL)
      this.resourceList = config.ResourceList.map(item => item.Code)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      // 采购员
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'CreatedName'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )

      // 币种
      let CurrencyNames = _.map(this.selectOptionsCurrencyCode, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'CurrencyName'
        }),
        { dataMap: CurrencyNames, showDropDown: true }
      )
      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      this.initData && this.onBindData()
    },
    onBindData (pageIndex = 1) {
      this.loading = true
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.filterFields),
        CreatedTimeStart: this.filterFields.createdTimeStart,
        CreatedTimeEnd: this.filterFields.createdTimeEnd,
        PageIndex: pageIndex,
        PageSize: this.pageSize
      })
      this.searchCondition = data
      this.$post(CONFIG.searchURL, data, (data) => {
        this.itemSource = data.ResultList
        this.pageIndex = pageIndex
        this.totalCount = data.TotalCount
        this.loading = false
      })
    },
    onSearch (params) {
      this.filterFields = _.extend({}, this.filterFields, params)
      return this.onBindData(1)
    },
    onSaveSetting (columns, pageSize) {
      localStorage.removeItem('stsInquire')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, CONFIG.default))
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('stsInquire')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler([column], this.pageSize, CONFIG.default))
    },
    onShowFiedsSearch () {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true
      } else {
        this.isShowFiedsSearch = false
      }
    },
    onResetSetting () {
      localStorage.removeItem('stsInquire')
      return this.$post(CONFIG.resetConfigURL)
    },
    onImprot: async function () { // 导入统计
      this.importData({ importType: 'inquire' })
    },
    onExport () {
      let exportColumns = []
      let selectedColumns = _.filter(this.columns, function (item, index) { return !item.isSystem && (_.isUndefined(item.hasRole) || item.hasRole) && item.IsExprot })
      _.each(selectedColumns, item => {
        exportColumns.push({
          'Name': item.binding,
          'DisplayName': item.header
        })
      })
      let postData = {
        'Name': 'StsInquire',
        'Conditions': this.searchCondition,
        'Columns': exportColumns
      }
      let that = this
      this.loading = true
      this.$post('export/excel', postData, (data, logic) => {
        if (logic.code === 200) {
          let downloadUrl = `${CONFIG.apiBaseUrl}export/download?id=` + data.Id
          if (this.isClient()) {
            that.clientDownLoadFile(data.Id, downloadUrl)
          } else {
            window.open(downloadUrl) // 下载文件
          }
          this.loading = false
        }
      }).catch(e => {
        this.loading = false
      })
    },
    clientDownLoadFile (fileName, url) {
      if (!url) { return }
      this.downFile({ FileName: '询价统计.xls', FileUrl: url },
        function (res) {
          // console.log(res)
          this.dialogShow = false
        }
      )
    },
    isClient () {
      return window.cefQuery !== undefined
    },
    showExportColumn () {
      this.setExportColumnVisible = true
      this.addColumnsList = this.columns
      this.setConfigColumns = this.defaultConfig
      this.setSaveConfigURL = this.$options.config.saveConfigURL
    }
  }
}
</script>

<style lang="scss">
</style>

