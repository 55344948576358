<template>
  <znl-gridmodule ref="flexGrid"
              :columns="columns"
              :is-btn="false"
              height="100%"
              :grid-show="gridShow"
              :has-znl-actions="true"
              :item-source="itemSource"
              :column-colors="columnColors"
              :row-colors="rowColors"
              :summary-columns="summaryColumns"
              :is-fields-search="true"
              :search-fields="searchModel"
              :page-size="pageSize"
              :page-index="pageIndex"
              :total-count="totalCount"
              :role="role"
              :on-init="onInit"
              :is-init="true"
              :on-refresh="onRefresh"
              :on-search="onSearch"
              :on-page-changed="onBindData"
              :on-save-setting="onSaveSetting"
              :on-save-config='onSaveConfig'
              :on-reset-setting="onResetSetting"
              :users="$store.state.users"
              @page-loading="d=>{$emit('page-loading', d)}"
              @page-changed="onBindData">
     <div slot="heading-slot">

          <el-radio-group v-model="filterFields.SearchType"
            @change="radioSearchType"
            class="form-group">
            <el-radio label="1">当天</el-radio>
            <el-radio label="2">本周</el-radio>
            <el-radio label="3">本月</el-radio>
          </el-radio-group>

       <!-- <el-button size="mini" type="primary"
            :isShow='true'
            class="znl-btn-mini"
            @click="$refs.flexGrid.search()">
        <i class="iconfont icon-search_ic"></i>搜索</el-button> -->

    </div>
  </znl-gridmodule>
</template>

<script>

import {mixin as znlMethodsMixin} from '@c_common/znlGrid/scripts/znlMethods'

const CONFIG = {
  configURL: 'StsHotInBillSupplierModel/GetConfig',
  saveConfigURL: 'StsHotInBillSupplierModel/SaveConfig',
  resetConfigURL: 'StsHotInBillSupplierModel/ResetConfig',
  searchURL: 'StsHotInBillSupplierModel/Search'
}

export default {

  name: 'StsHotInBillSupplierModel',
  mixins: [znlMethodsMixin],
  config: CONFIG,
  data () {
    return {
      columns: [],
      itemSource: [],
      searchFields: {},
      columnColors: [],
      filterFields: {
        SearchType: '1'
      },
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      resourceList: [], // 权限列表，
      gridShow: false
    }
  },
  computed: {
    rightMenus () {
      return []
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String
  },
  methods: {
    onInit: async function () {
      let config = await this.isSaveStorage('stsHotInBillSupplier', CONFIG.configURL)
      // let config = await this.$post(CONFIG.configURL)
      this.resourceList = config.ResourceList.map(item => item.Code)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      this.initData && this.onBindData()
    },
    onSaveSetting (columns, pageSize) {
      localStorage.removeItem('stsHotInBillSupplier')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, CONFIG.default))
    },
    onResetSetting () {
      localStorage.removeItem('stsHotInBillSupplier')
      return this.$post(CONFIG.resetConfigURL)
    },
     // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('stsHotInBillSupplier')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler([column], this.pageSize, CONFIG.default))
    },
    radioSearchType (val) {
      if (val) {
        this.onSearch()
      }
    }
  }
}
</script>

<style lang="scss">

</style>

