<template>
  <div class="module-wrapper">
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :is-fields-search="isShowFiedsSearch"
      :search-fields="onSearch"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :total-count="totalCount"
      :show-title-menus="true"
      :totalField="['Qty','Amount']"
      :header-menus="getResetTableHeaderMenu('StsPurchaseDetails')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="采购时间从"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="100px"
          :border="true"
          v-model="filterFields.StartPODate"
          :clearable="true"
        ></znl-input>
        <span>到</span>
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="采购时间止"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="100px"
          :border="true"
          :clearable="true"
          v-model="filterFields.EndPODate"
          inp-perc="100%"
        ></znl-input>

        <znl-input
          from-type="input"
          :disable="false"
          placeholder="请输入供应商名称"
          size="mini"
          layout="left"
          width="180px"
          inp-perc="100%"
          class="box_input_checkbox"
          :border="true"
          :clearable="true"
          v-model="filterFields.SupplierName"
          @keyup.enter.native="$refs.flexGrid.search()"
          type="text"
        ></znl-input>
        <el-checkbox class="accurate-checbox" size="small" v-model="filterFields.Accurate">精确</el-checkbox>

        <znl-button type="multiple" :height="22" style-type="mac" class="box-left-btn search-btn">
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
          <znl-button-menu :width="20" tip="高级搜索" @click="onShowFiedsSearch()">
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>

        <znl-button style-type="mac" :btns="exportBtns" :height="22" @click="onExport" tip="按搜索条件">
          <i class="iconfont icon-leading-out-wh_btn_i"></i>
          <span>导出</span>
        </znl-button>
      </div>
    </znl-table>
    <export-column
      :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="defaultConfig"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="()=>{setExportColumnVisible=false}"
      @confirm="(v)=>{setExportColumnVisible=false}"
    ></export-column>
  </div>
</template>

<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import ExportColumn from "@c_modules/Crm/setExportColumn";
import { exportData, erpTableSetHandler } from "@scripts/methods/common";

const CONFIG = {
  configURL: "StsPurchaseDetails/GetConfig",
  saveConfigURL: "StsPurchaseDetails/SaveConfig",
  configSTKURL: "StsPurchaseDetails/GetConfig",
  searchURL: "StsPurchaseDetails/Search",
};
export default {
  name: "StsPurchaseDetails",
  mixins: [getCommonDataMixin, exportData, erpTableSetHandler],
  config: CONFIG,
  components: {
    ExportColumn
  },
  props: {
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    initData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      isShowFiedsSearch: false,
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      loading: false,
      filterFields: {
        StartPODate: "",
        EndPODate: "",
        SupplierName: "",
        Accurate: false,
        StatusStr: []
      },
      exportBtns: [
        {
          name: "设置导出列",
          click: () => {
            this.showExportColumn();
          }
        }
      ],
      setExportColumnVisible: false,
      addColumnsList: [],
      defaultConfig: {},
      setSaveConfigURL: "",
      currencyList: [],
      userList: [],
      qtyUnitList: [],
      taxRateList: [],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    };
  },
  async created() {
    this.currencyList = _.map(await this.getCurrencyCodeListAsync, function(
      obj,
      index
    ) {
      return { key: obj.value, value: obj.key };
    });
    this.userList = _.map(this.selectOptionsAllUserURL, function(obj) {
      return { key: obj.value, value: obj.value };
    });
    this.qtyUnitList = await this.selectOptionsQtyUnit;
    this.taxRateList = await this.getTaxRateListAsync;
  },
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function(config) {
      if (!config) {
        config = await this.isSaveStorage("StsPurchaseDetails", CONFIG.configURL);
      }
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      let columns = config.ColumnConfigs;
      this.filterFields.StartPODate = this.getCurrentMonthFirst();
      this.filterFields.EndPODate = this.getCurrentMonthLast();

      let colIndex = _.findIndex(
        columns,
        item => item.BindField === "CurrencyCode"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.currencyList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "BuyerName");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.userList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "Unit");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.qtyUnitList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "StkInStatus");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          options: [
            { key: "未入", value: 1 },
            { key: "部分入", value: 2 },
            { key: "已入", value: 3 },
          ]
        });
      }

      // let viewBuyPrice = this.getSpecialResourceByCode("ViewBuyPrice");
      // let noSeeProfit = this.getSpecialResourceByCode("NoSeeProfit");
      // _.remove(columns, item => {
      //   if (!viewBuyPrice && item.BindField === "CostPrice") {
      //     return true;
      //   }
      //   if (noSeeProfit && item.BindField === "GrossProfit") {
      //     return true;
      //   }
      // });

      this.columns = columns;
      this.defaultConfig = config;
      this.$refs.table.init();
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      if (this.initData) {
        await this.onBindData();
      }
    },
    getSearchWhere(pageIndex) {
      let bindData = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.filterFields),
          PageIndex: pageIndex,
          PageSize: this.pageSize,
          StartPODate: this.filterFields.StartPODate,
          EndPODate: this.filterFields.EndPODate,
          SupplierName: this.filterFields.SupplierName,
          StatusStr: this.filterFields.StatusStr
        }
      );
      return bindData;
    },
    async onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      this.loading = true;
      let bindData = this.getSearchWhere(pageIndex);
      return await this.$post(CONFIG.searchURL, bindData, data => {
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
      }).finally(() => {
        this.$emit("page-loading", false);
        setTimeout(() => {
          this.loading = false;
        }, 800);
      });
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.filterFields = _.extend({}, this.filterFields, params);
      }
      return this.onBindData(1);
    },
    onSaveSetting(columns, pageSize) {
      localStorage.removeItem("StsPurchaseDetails");
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, CONFIG.default)
      );
    },
    onResetSetting() {
      localStorage.removeItem("StsPurchaseDetails");
      return this.$post(CONFIG.resetConfigURL);
    },
    // 右键点击表头设置列
    onSaveConfig(column) {
      localStorage.removeItem("StsPurchaseDetails");
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, CONFIG.default)
      );
    },
    onShowFiedsSearch() {
      this.isShowFiedsSearch = !this.isShowFiedsSearch;
    },
    getCurrentMonthFirst() {
      var date = new Date();
      date.setDate(1);
      var month = parseInt(date.getMonth() + 1);
      var day = date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      return date.getFullYear() + "-" + month + "-" + day;
    },
    getCurrentMonthLast() {
      var date = new Date();
      var currentMonth = date.getMonth();
      var nextMonth = ++currentMonth;
      var nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
      var oneDay = 1000 * 60 * 60 * 24;
      var lastTime = new Date(nextMonthFirstDay - oneDay);
      var month = parseInt(lastTime.getMonth() + 1);
      var day = lastTime.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      return new Date(date.getFullYear() + "-" + month + "-" + day);
    },
    showExportColumn() {
      this.setExportColumnVisible = true;
      this.addColumnsList = this.columns;
      this.setSaveConfigURL = this.$options.config.saveConfigURL;
    },

    async onExport() {
      this.loading = true;
      this.loading = await this.exportData(
        "StsPurchaseDetails",
        this.getSearchWhere(1),
        this.columns,
        "采购统计"
      );
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("StsPurchaseDetails", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("StsPurchaseDetails", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("StsPurchaseDetails", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("StsPurchaseDetails");
    }
  }
};
</script>

<style lang='scss'>
</style>
