<template>
  <znl-layout-spa :page-loading="pageLoading" height="100%" element-loading-text="页面加载中,请稍后...">
    <el-tabs
      value="StsDataStatistics"
      class="nav-common-ui tab-btnstyle-box tab-stsdatastatics-box grid-footer-right oustslide_padding-right"
      v-model="activeName"
      v-show="!pageLoading"
      @tab-click="pageSearch"
    >
      <!-- **************************************************************************** -->
      <!-- **************************** v-table *************************************** -->
      <!-- **************************************************************************** -->
      <el-tab-pane label="销售报价统计" name="HisOfferPrice" v-if="hasRight('HisOfferPrice')">
        <znl-bom-quote-details
          ref="offerPriceInitData"
          v-if="offerPriceInitData"
          :is-show-operate-col="false"
          :init-data="offerPriceInitData"
          @page-loading="setClientSelectLoading"
        ></znl-bom-quote-details>
      </el-tab-pane>

      <el-tab-pane label="采购报价进度统计" name="StsBuyerQuoteTask" v-if="hasRight('StsInquire')">
        <znl-buyer-quote-task
          v-if="buyerQuoteTaskInitData"
          :init-data="buyerQuoteTaskInitData"
          ref="StsBuyerQuoteTask"
          @page-loading="setClientSelectLoading"
        ></znl-buyer-quote-task>
      </el-tab-pane>

      <el-tab-pane label="出库统计" name="SalesOut" v-if="hasRight('StsSalesOut')">
        <znl-sale-out
          v-if="saleOutInitData"
          ref="stsSalesOut"
          :init-data="saleOutInitData"
          @page-loading="setClientSelectLoading"
        ></znl-sale-out>
      </el-tab-pane>

      <el-tab-pane label="销售统计" name="SalesOrder" v-if="hasRight('StsSalesOrder')">
        <znl-sales-order ref="salesOrder" v-if="salesOrderInitData" :init-data="salesOrderInitData"></znl-sales-order>
      </el-tab-pane>

      <el-tab-pane label="未收款统计" name="StsUnReceive" v-if="hasRight('StsUnReceive')">
        <znl-sts-un-receive
          ref="stsUnReceive"
          v-if="unReceiveinIntData"
          :init-data="unReceiveinIntData"
          @page-loading="setClientSelectLoading"
        ></znl-sts-un-receive>
      </el-tab-pane>

      <el-tab-pane label="热搜型号统计" name="StsHotSearchModels" v-if="hasRight('StsHotSearchModels')">
        <znl-sts-hot-search-models
          v-if="hotInitData"
          ref="stsHotSearchModels"
          :init-data="hotInitData"
          @page-loading="setClientSelectLoading"
        ></znl-sts-hot-search-models>
      </el-tab-pane>

      <el-tab-pane label="询价统计" name="StsInquire" v-if="hasRight('StsInquire')">
        <znl-inquire
          v-if="inquireInitData"
          :init-data="inquireInitData"
          ref="stsInquire"
          @page-loading="setClientSelectLoading"
        ></znl-inquire>
      </el-tab-pane>

      <el-tab-pane label="采购报价统计" name="PurchaseQuote" v-if="hasRight('StsPurchaseQuote')">
        <znl-sts-purchase-quote
          v-if="purchaseQuoteInitData"
          ref="stsPurchaseQuote"
          :init-data="purchaseQuoteInitData"
          @page-loading="setClientSelectLoading"
        ></znl-sts-purchase-quote>
      </el-tab-pane>

      <el-tab-pane label="采购统计" name="StsPurchaseDetails" v-if="hasRight('StsPurchaseIn')">
        <znl-sts-purchase
          v-if="purchaseDetailsInitData"
          ref="StsPurchaseDetails"
          :init-data="purchaseDetailsInitData"
          @page-loading="setClientSelectLoading"
        ></znl-sts-purchase>
      </el-tab-pane>

      <el-tab-pane label="入库统计" name="StsPurchaseIn" v-if="hasRight('StsPurchaseIn')">
        <znl-sts-purchase-in
          v-if="purchaseInitData"
          ref="stsPurchaseIn"
          :init-data="purchaseInitData"
          @page-loading="setClientSelectLoading"
        ></znl-sts-purchase-in>
      </el-tab-pane>

      <el-tab-pane label="型号报价统计" name="StsHotQuoteModels" v-if="hasRight('StsHotQuoteModels')">
        <znl-sts-hot-quote-models
          v-if="hotQuoteInitData"
          ref="stsHotQuoteModels"
          :init-data="hotQuoteInitData"
          @page-loading="setClientSelectLoading"
        ></znl-sts-hot-quote-models>
      </el-tab-pane>

      <el-tab-pane label="未付款统计" name="StsUnPayment" v-if="hasRight('StsUnPayment')">
        <znl-sts-un-payment
          v-if="unPaymentInitData"
          ref="stsUnPayment"
          :init-data="unPaymentInitData"
          @page-loading="setClientSelectLoading"
        ></znl-sts-un-payment>
      </el-tab-pane>

      <el-tab-pane label="拨号统计" name="StsOutDialHistory" v-if="hasRight('StsOutDialHistory')">
        <znl-sts-out-dial-history
          v-if="dialHistoryInitData"
          ref="stsOutDialHistory"
          :init-data="dialHistoryInitData"
          @page-loading="setClientSelectLoading"
        ></znl-sts-out-dial-history>
      </el-tab-pane>

      <a class="old-edition" @click="oldEdition()" v-if="isErpClient">切换到旧版</a>
    </el-tabs>
  </znl-layout-spa>
</template>
<script>
import { switchToLegacyPage } from "~/lib/native";
// 改版znl-table
import ZnlInquire from "@c_modules/Sts/StsInquire_v1";
import ZnlSaleOut from "@c_modules/Sts/StsSalesOut_v1";
import ZnlStsUnReceive from "@c_modules/Sts/StsUnReceive_v1";
import ZnlStsHotSearchModels from "@c_modules/Sts/StsHotSearchModels_v1";
import ZnlStsPurchaseIn from "@c_modules/Sts/StsPurchaseIn_v1";
import ZnlStsHotQuoteModels from "@c_modules/Sts/StsHotQuoteModels_v1";
import ZnlStsUnPayment from "@c_modules/Sts/StsUnPayment_v1";
import ZnlStsOutDialHistory from "@c_modules/Sts/StsOutDialHistory_v1";
import ZnlStsPurchaseQuote from "@c_modules/Sts/StsPurchaseQuote_v1";
import ZnlSalesOrder from "@c_modules/Sts/StsSalesOrder_v1";
import ZnlBomQuoteDetails from "@c_modules/BOM/BomQuoteDetails";
import ZnlBuyerQuoteTask from "@c_modules/Sts/StsBuyerQuoteTask";
import ZnlStsPurchase from "@c_modules/Sts/StsPurchaseDetails";
import { isClient } from "~/lib/runtime";

const tabs = [
  { name: "HisOfferPrice", ref: "offerPriceInitData" },
  { name: "StsBuyerQuoteTask", ref: "StsBuyerQuoteTask" },
  { name: "SalesOut", ref: "stsSalesOut" },
  { name: "SalesOrder", ref: "salesOrder" },
  { name: "StsUnReceive", ref: "stsUnReceive" },
  { name: "StsHotSearchModels", ref: "stsHotSearchModels" },
  { name: "StsInquire", ref: "stsInquire" },
  { name: "PurchaseQuote", ref: "stsPurchaseQuote" },
  { name: "StsPurchaseDetails", ref: "StsPurchaseDetails" },
  { name: "StsPurchaseIn", ref: "stsPurchaseIn" },
  { name: "StsHotQuoteModels", ref: "stsHotQuoteModels" },
  { name: "StsUnPayment", ref: "stsUnPayment" },
  { name: "StsOutDialHistory", ref: "stsOutDialHistory" }
];


export default {
  name: "VStsDataStatistics",
  components: {
    switchToLegacyPage,
    ZnlSalesOrder,
    ZnlInquire,
    ZnlSaleOut,
    ZnlStsUnReceive,
    ZnlStsHotSearchModels,
    ZnlStsHotQuoteModels,
    ZnlStsPurchaseIn,
    ZnlStsUnPayment,
    ZnlStsOutDialHistory,
    ZnlStsPurchaseQuote,
    ZnlBomQuoteDetails,
    ZnlBuyerQuoteTask,
    ZnlStsPurchase
  },
  watch: {
    pageLoading(val) {
      if (!val) {
        this.$el.classList.add("finishLoading");
      } else {
        this.$el.classList.remove("finishLoading");
      }
    },
    //监视窗口高度并处理
    "$store.state.domRect": {
      handler: function(val, oldVal) {
        this.tableResize();
      },
      deep: true
    }
  },
  data() {
    return {
      pageData: [],
      pageLoading: true,

      offerPriceInitData: false,
      inquireInitData: false,
      unReceiveinIntData: false,
      saleOutInitData: false,
      salesOrderInitData: false,
      statementInitData: false,
      hotInitData: false,
      purchaseInitData: false,
      hotQuoteInitData: false,
      unPaymentInitData: false,
      dialHistoryInitData: false,
      saleInitData: false,
      PurStaInitData: false,
      purchaseQuoteInitData: false,
      buyerQuoteTaskInitData: false,
      purchaseDetailsInitData: false,
      activeName: "HisOfferPrice",
      isErpClient: false
    };
  },
  methods: {
    tableResize() {
      //计算boxHeight高度
      const clientHeight = this.$store.state.domRect.clientHeight;
      const boxHeight = (isClient() ? clientHeight : (clientHeight - 50)) - 46;

      const idx = tabs.findIndex(item=>item.name==this.activeName)
      if(idx>-1)
      {
        //当前tab处理
        if(this.$refs[tabs[idx].ref]){
          this.$refs[tabs[idx].ref].$refs.table.boxHeight = boxHeight;
          // this.$refs[tabs[idx].ref].$refs.table.init();
          this.$refs[tabs[idx].ref].$refs.table.$forceUpdate();
        }

      }
    },
    onInit() {
      if (this.hasRight("HisOfferPrice")) {
        this.offerPriceInitData = true;
        this.activeName = "HisOfferPrice";
      } else if (this.hasRight("StsSalesOut")) {
        this.saleOutInitData = true;
        this.activeName = "SalesOut";
      } else if (this.hasRight("StsSalesOrder")) {
        this.salesOrderInitData = true;
        this.activeName = "SalesOrder";
      } else if (this.hasRight("StsUnReceive")) {
        this.activeName = "StsUnReceive";
        this.unReceiveinIntData = true;
      } else if (this.hasRight("StsHotSearchModels")) {
        this.activeName = "StsHotSearchModels";
        this.hotInitData = true;
      } else if (this.hasRight("StsInquire")) {
        this.activeName = "StsInquire";
        this.inquireInitData = true;
      } else if (this.hasRight("StsPurchaseQuote")) {
        this.activeName = "PurchaseQuote";
        this.purchaseQuoteitData = true;
      } else if (this.hasRight("StsPurchaseIn")) {
        this.activeName = "StsPurchaseIn";
        this.purchaseInitData = true;
      } else if (this.hasRight("StsHotQuoteModels")) {
        this.activeName = "StsHotQuoteModels";
        this.hotQuoteInitData = true;
      } else if (this.hasRight("StsUnPayment")) {
        this.activeName = "StsUnPayment";
        this.unPaymentInitData = true;
      } else if (this.hasRight("StsOutDialHistory")) {
        this.activeName = "StsOutDialHistory";
        this.dialHistoryInitData = true;
      }else{
        this.activeName = "StsPurchaseDetails";
        this.purchaseDetailsInitData = true;
      }
    },
    hasRight(code) {
      return _.includes(this.pageData, code);
    },
    async oldEdition() {
      await switchToLegacyPage("Statistics");
    },
    pageSearch() {
      if (this.activeName === "StsInquire") {
        this.inquireInitData = true;
      }
      if (this.activeName === "StsUnReceive") {
        this.unReceiveinIntData = true;
      }
      if (this.activeName === "StsHotSearchModels") {
        this.hotInitData = true;
      }
      if (this.activeName === "PurchaseQuote") {
        this.purchaseQuoteInitData = true;
      }
      if (this.activeName === "StsPurchaseIn") {
        this.purchaseInitData = true;
      }
      if (this.activeName === "StsHotQuoteModels") {
        this.hotQuoteInitData = true;
      }
      if (this.activeName === "StsUnPayment") {
        this.unPaymentInitData = true;
      }
      if (this.activeName === "StsOutDialHistory") {
        this.dialHistoryInitData = true;
      }
      if (this.activeName === "SalesOut") {
        this.saleOutInitData = true;
      }
      if (this.activeName === "SalesOrder") {
        this.salesOrderInitData = true;
      }
      if (this.activeName === "StsBuyerQuoteTask") {
        this.buyerQuoteTaskInitData = true;
      }
       if (this.activeName === "StsPurchaseDetails") {
        this.purchaseDetailsInitData = true;
      }
      setTimeout(() => {
        // console.log('table 改变...');
        this.tableResize();
      }, 300);
    },

    async getPageData() {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl;
      return await this.$post(pageUrl, { PageCode: "VDataStatistic" });
    },
    setClientSelectLoading(val) {
      this.pageLoading = val;
    }
  },

  async created() {
    this.isErpClient = isClient();
    this.getPageData()
      .then(data => {
        if (data && data.length > 0) {
          this.pageData = data.map(item => item.Code);
        }
        this.onInit();
      })
      .catch(e => {});
  }
};
</script>
<style lang="scss">
</style>

