<template>
  <div class="module-wrapper">
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :is-fields-search="isShowFiedsSearch"
      :search-fields="onSearch"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :total-count="totalCount"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('StsInquire')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="询价日期起"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="120px"
          :clearable="true"
          :border="true"
          inp-perc="100%"
          v-model="filterFields.createdTimeStart"
        ></znl-input>
        <span>到</span>

        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="询价日期止"
          size="mini"
          value-format="yyyy-MM-dd"
          layout="left"
          width="120px"
          :border="true"
          :clearable="true"
          inp-perc="100%"
          v-model="filterFields.createdTimeEnd"
        ></znl-input>

        <znl-input
          form-type="input"
          :disabled="false"
          placeholder="请输入型号"
          size="mini"
          layout="left"
          width="100px"
          :border="true"
          :clearable="true"
          inp-perc="100%"
          v-model="filterFields.Model"
          @keyup.enter.native="onSearch()"
        ></znl-input>

        <znl-input
          form-type="input"
          :disabled="false"
          placeholder="请输入供应商名称"
          size="mini"
          layout="left"
          width="150px"
          :border="true"
          :clearable="true"
          inp-perc="100%"
          v-model="filterFields.SupplierName"
          @keyup.enter.native="onSearch()"
        ></znl-input>

        <znl-button type="multiple" :height="22" style-type="mac" class="box-left-btn search-btn">
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
          <znl-button-menu :width="20" tip="高级搜索" @click="onShowFiedsSearch()">
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>
        <znl-button
          style-type="mac"
          :btns="printznlbtns"
          :height="22"
          @click="onExport"
          tip="按搜索条件"
        >
          <i class="iconfont icon-leading-out-wh_btn_i"></i>
          <span>导出</span>
        </znl-button>
        <znl-button style-type="mac" :height="22" @click="onImprot">
          <i class="iconfont icon-leading-in-wh_btn_ic"></i>
          <span>导入</span>
        </znl-button>
      </div>
    </znl-table>
    <export-column
      :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="defaultConfig"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="()=>{setExportColumnVisible=false}"
      @confirm="(v)=>{setExportColumnVisible=false}"
    ></export-column>
    <znl-data-import
      :type="zznlImportType"
      :show="zznlShowDataImport"
      @close="zznlShowDataImport = false"
    />
  </div>
</template>

<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import ExportColumn from "@c_modules/Crm/setExportColumn";
import { exportData, erpTableSetHandler } from "@scripts/methods/common";

const CONFIG = {
  configURL: "StsInquire/GetConfig",
  saveConfigURL: "StsInquire/SaveConfig",
  searchURL: "StsInquire/Search"
};

export default {
  name: "StsInquire",
  mixins: [getCommonDataMixin, exportData, erpTableSetHandler],
  config: CONFIG,
  components: {
    ExportColumn
  },
  data() {
    return {
      isShowFiedsSearch: false,
      columns: [],
      defaultConfig: {},
      itemSource: [],
      filterFields: {
        createdTimeStart: "",
        createdTimeEnd: ""
      },
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [], // 权限列表，
      printznlbtns: [
        {
          name: "设置导出列",
          click: () => {
            this.showExportColumn();
          }
        }
      ],
      setExportColumnVisible: false,
      addColumnsList: [],
      setSaveConfigURL: "",
      loading: false,
      currencyList: [],
      userList: [],
      qtyUnitList: [],
      taxRateList: [],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    };
  },

  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  async created() {
    this.currencyList = _.map(await this.getCurrencyCodeListAsync, function(
      obj,
      index
    ) {
      return { key: obj.value, value: obj.key };
    });
    this.userList = _.map(this.selectOptionsAllUserURL, function(obj) {
      return { key: obj.value, value: obj.value };
    });
    this.qtyUnitList = await this.selectOptionsQtyUnit;
    this.taxRateList = await this.getTaxRateListAsync;
  },
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function() {
      let config = await this.isSaveStorage("stsInquire", CONFIG.configURL);
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      let columns = config.ColumnConfigs;
      this.defaultConfig = config;
      this.parameterConfig = config.ParamJosn;

      let colIndex = _.findIndex(
        columns,
        item => item.BindField === "CurrencyCode"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.currencyList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "CreatedName");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.userList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "TaxRate");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.taxRateList
        });
      }

      this.columns = columns;
      this.defaultConfig = config;
      this.role = config.Role;
      this.$refs.table.init();
      if (this.initData) {
        this.onBindData(1);
      }
      this.$emit("page-loading", false);
    },
    onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      this.loading = true;
      let data = this.getSearchWhere(pageIndex);
      this.$post(CONFIG.searchURL, data, data => {
        this.itemSource = data.ResultList;
        this.pageIndex = pageIndex;
        this.totalCount = data.TotalCount;
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 800);
      });
    },
    getSearchWhere(pageIndex) {
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.filterFields),
          CreatedTimeStart: this.filterFields.createdTimeStart,
          CreatedTimeEnd: this.filterFields.createdTimeEnd,
          PageIndex: pageIndex,
          PageSize: this.pageSize
        }
      );
      return data;
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.filterFields = _.extend({}, this.filterFields, params);
      }
      return this.onBindData(1);
    },
    onShowFiedsSearch() {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true;
      } else {
        this.isShowFiedsSearch = false;
      }
    },
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    onImprot: async function() {
      // 导入统计
      this.importData({ importType: "inquire" });
    },
    async onExport() {
      this.loading = true;
      this.loading = await this.exportData(
        "StsInquire",
        this.getSearchWhere(1),
        this.columns,
        "询价统计"
      );
    },
    showExportColumn() {
      this.setExportColumnVisible = true;
      this.addColumnsList = this.columns;
      this.setSaveConfigURL = this.$options.config.saveConfigURL;
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("StsInquire", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("StsInquire", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("StsInquire", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("StsInquire");
    }
  }
};
</script>

<style lang="scss">
</style>

