<template>
  <div
  class="chartTest"
  :style="{height:height}">
  <znl-layout-spa @get-document-rect="getDocumentRect"  :page-loading="pageLoading">
    <znl-erp-module-chart
          slot="znlLayoutSpa"
          class="znlLayoutSpa"
          @page-loading="d=>{pageLoading=d}"
          >
    </znl-erp-module-chart>
  </znl-layout-spa>
</div>
</template>

<script>
import ZnlErpModuleChart from '~components/modules/Sts/StockWarningStatisticSearchView'

export default {
  name: 'VErpModuleChart',
  components: {
    ZnlErpModuleChart
  },
  data () {
    return {
      height: '',
      pageLoading: false
    }
  },
  methods: {
    getDocumentRect (win) { // 设置父元素高度
      let headerHeight = document.querySelector('#fw-right .el-tabs__header').offsetHeight
      this.height = win.height - headerHeight + 'px'
    }
  }
}
</script>
