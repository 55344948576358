<template>
  <div class="module-wrapper">
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :search-fields="onSearch"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :total-count="totalCount"
      @phone-click="dialphone"
      @on-show-trigger="e => {hideShowColTrigger('StsOutDialHistory', e.col)}"
      :drag-done="col=>setTableColWidth('StsOutDialHistory', col)"
      @drag-col-position="(cols)=>{columns = cols, saveErpTableConfig('StsOutDialHistory', cols,true)}"
      @on-config-save="(cols)=>{columns = cols, saveErpTableConfig('StsOutDialHistory', cols,false)}"
      @on-reset-click="resetTableConfig('StsOutDialHistory')"
    >
      <div slot="header" class="v-table-toolbar search-box">
        <znl-input
          form-type="input"
          :disabled="false"
          placeholder="请输入公司名称"
          size="mini"
          layout="left"
          width="180px"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="filterFields.cCompanyName"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>
        <znl-input
          form-type="input"
          :disabled="false"
          placeholder="请输入拨号人"
          size="mini"
          layout="left"
          width="80px"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="filterFields.CalledByName"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>
        <znl-input
          form-type="input"
          :disabled="false"
          placeholder="请输入电话号码"
          size="mini"
          layout="left"
          width="100px"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="filterFields.callNumber"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>

        <znl-input
          form-type="input"
          :disabled="false"
          class="ml5"
          title="时长："
          title-width="35px"
          width="75px"
          placeholder
          size="mini"
          layout="left"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="filterFields.CallDurationStart"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>
        <span style="margin-right: 0 !important; margin-left: 1px;">-</span>
        <znl-input
          form-type="input"
          :disabled="false"
          class="ml5"
          width="40px"
          placeholder
          size="mini"
          layout="left"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="filterFields.CallDurationEnd"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>

        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="来电日期起"
          size="mini"
          layout="left"
          width="100px"
          :clearable="true"
          inp-perc="100%"
          :border="true"
          v-model="filterFields.callTimeStart"
        ></znl-input>
        <span>到</span>
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="来电日期止"
          size="mini"
          layout="left"
          width="100px"
          :clearable="true"
          inp-perc="100%"
          :border="true"
          v-model="filterFields.callTimeEnd"
        ></znl-input>
        <znl-button type="multiple" :height="22" style-type="mac" class="box-left-btn search-btn">
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
        </znl-button>
      </div>
    </znl-table>
  </div>
</template>

<script>

import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { erpTableSetHandler } from '@scripts/methods/common'
import { dial } from '~/lib/native'
const CONFIG = {
  configURL: 'StsOutDialHistory/GetConfig',
  saveConfigURL: 'StsOutDialHistory/SaveConfig',
  resetConfigURL: 'StsOutDialHistory/ResetConfig',
  searchURL: 'StsOutDialHistory/Search'
}

export default {
  name: 'StsOutDialHistory',
  mixins: [getCommonDataMixin, erpTableSetHandler],
  config: CONFIG,
  components: {
    dial
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      searchFields: {},
      filterFields: {
        callTimeStart: '',
        callTimeEnd: '',
        callNumber: '',
        cCompanyName: ''
      },
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [], // 权限列表，
      loading: false
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  async created() {
  },
  async mounted() {
    await this.onInit()
  },
  methods: {
    onInit: async function () {
      let config = await this.isSaveStorage('stsOutDialHistory', CONFIG.configURL)
      if (this.hasValue(config.ResourceList) && config.ResourceList.length > 0) {
        this.resourceList = config.ResourceList.map(item => item.Code)
      }
      let columns = config.ColumnConfigs

      // 表格值的点击 start
      _.extend(columns[_.findIndex(columns, item => item.BindField === 'CallNumer')], {
        event: 'phone-click',
        className: 'v-cell-link'
      })

      this.columns = columns
      this.pageSize = config.PageSize
      this.$refs.table.init()
      if (this.initData) {
        await this.onBindData(1)
      }
      this.$emit('page-loading', false)
    },
    onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      let data = _.extend({}, {
        PageIndex: pageIndex,
        PageSize: this.pageSize,
        CallTimeStart: this.filterFields.callTimeStart,
        CallTimeEnd: this.filterFields.callTimeEnd,
        CCompanyName: this.filterFields.cCompanyName,
        CallNumber: this.filterFields.callNumber,
        CalledByName: this.filterFields.CalledByName,
        CallDurationStart: this.filterFields.CallDurationStart,
        CallDurationEnd: this.filterFields.CallDurationEnd
      })
      this.loading = true
      return this.$post(CONFIG.searchURL, data, (data) => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 800);
      })
    },
    dialphone(e) {
      if (e.field !== 'CallNumer') {
        return
      }
      console.log("e.row.CallNumer", e.row.CallNumer)
      if (this.hasValue(e.row.CallNumer)) {
        dial(e.row.CallNumer)
      }
    },
    onSearch(params) {
      return this.onBindData(1)
    },

  }
}
</script>

<style lang="scss">
</style>

