<template>
  <znl-gridmodule ref="flexGrid"
                  :columns="columns"
                  height="100%"
                  :grid-show="gridShow"
                  :has-znl-actions="true"
                  :is-btn="false"
                  :item-source="itemSource"
                  :column-colors="columnColors"
                  :row-colors="rowColors"
                  :summary-columns="summaryColumns"
                  :is-fields-search="isShowFiedsSearch"
                  :search-fields="searchModel"
                  :page-size="pageSize"
                  :page-index="pageIndex"
                  :total-count="totalCount"
                  :role="role"
                  :on-init="onInit"
                  :is-init="true"
                  :on-refresh="onRefresh"
                  :on-search="onSearch"
                  :on-page-changed="onBindData"
                  :users="$store.state.users"
                  @page-loading="d=>{$emit('page-loading', d)}"
                  @page-changed="onBindData">
    <div slot="heading-slot">

      <znl-input form-type="datepicker"
                 :disabled="false"
                 placeholder="开始日期"
                 size="mini"
                 layout="left"
                 width='100px'
                 :clearable="true"
                 inp-perc="100%"
                 :border="true"
                 v-model="filterFields.quotedTimeByStart">
      </znl-input>
      <span>到</span>
      <znl-input form-type="datepicker"
                 :disabled="false"
                 placeholder="结束日期"
                 size="mini"
                 layout="left"
                 width='100px'
                 :clearable="true"
                 :border="true"
                 inp-perc="100%"
                 v-model="filterFields.quotedTimeByEnd">
      </znl-input>
      <znl-button type="multiple"
                  :height='22'
                  style-type='mac'
                  class="box-left-btn search-btn">
        <znl-button-menu @click="$refs.flexGrid.search()">
          <i class="iconfont icon-search_ic"></i>
          <span>搜索</span>
        </znl-button-menu>
        <znl-button-menu :width='12'
                         tip="高级搜索"
                         @click="onShowFiedsSearch()">
          <i class="iconfont icon-arrow-drop-down_ic"></i>
        </znl-button-menu>
      </znl-button>
    </div>
  </znl-gridmodule>
</template>

<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'

const CONFIG = {
  configURL: 'StsHotQuoteModels/GetConfig',
  saveConfigURL: 'StsHotQuoteModels/SaveConfig',
  resetConfigURL: 'StsHotQuoteModels/ResetConfig',
  default: null,
  searchURL: 'StsHotQuoteModels/GetHotQStatistics'
}
export default {
  name: 'StsHotQuoteModels',
  mixins: [znlMethodsMixin],
  config: CONFIG,
  components: {
  },
  data () {
    return {
      isShowFiedsSearch: false,
      columns: [],
      itemSource: [],
      searchFields: {},
      columnColors: [],
      filterFields: {
        quotedTimeByStart: '',
        quotedTimeByEnd: ''
      },
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      resourceList: [], // 权限列表，
      gridShow: false
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String
  },
  methods: {
    onShowFiedsSearch () {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true
      } else {
        this.isShowFiedsSearch = false
      }
    },
    hasRes (code) {
      return _.includes(this.resourceList, code)
    }
  }
}
</script>

<style lang="scss">

</style>

