<template>
  <div class="module-wrapper">
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      box-class="znl-table-container"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :search-fields="onSearch"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :total-count="totalCount"
    >
      <div slot="header" class="v-table-toolbar">
        <!-- <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="开始日期"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="100px"
          :clearable="true"
          :border="true"
          inp-perc="100%"
          v-model="filterFields.DateStart"
        ></znl-input>
        <span>到</span>

      <znl-input
        form-type="datepicker"
        :disabled="false"
        placeholder="结束日期"
        size="mini"
        value-format="yyyy-MM-dd"
        layout="left"
        width="100px"
        :border="true"
        :clearable="true"
        inp-perc="100%"
        v-model="filterFields.DateEnd"
        ></znl-input>-->

        <znl-input
          form-type="input"
          :disabled="false"
          placeholder="请输入型号"
          size="mini"
          layout="left"
          width="150px"
          :clearable="true"
          inp-perc="100%"
          type="text"
          :border="true"
          v-model="filterFields.keyword"
          @keyup.enter.native="onSearch()"
        ></znl-input>
        <znl-button type="multiple" :height="22" style-type="mac" class="box-left-btn search-btn">
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
        </znl-button>
      </div>
    </znl-table>
  </div>
</template>

<script>
const CONFIG = {
  configURL: "StsHotSearchModels/GetConfig",
  saveConfigURL: "StsHotSearchModels/SaveConfig",
  resetConfigURL: "StsHotSearchModels/ResetConfig",
  searchURL: "StsHotSearchModels/GetHotStatistics"
};

export default {
  name: "stsHotSearchModels",
  mixins: [],
  config: CONFIG,
  components: {},
  data() {
    return {
      columns: [],
      itemSource: [],
      searchFields: {},
      filterFields: {
        keyword: ""
      },
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      loading: false
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    }
  },
  async created() {},
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function() {
      let config = await this.isSaveStorage(
        "StsHotSearchModels",
        CONFIG.configURL
      );
      let columns = config.ColumnConfigs;

      this.defaultConfig = config;
      this.columns = columns;
      this.$refs.table.init();
      if (this.initData) {
        this.onBindData(1);
      }
      this.$emit("page-loading", false);
    },

    onSearch(params) {
      return this.onBindData(1);
    },
    onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      let data = _.extend(
        {},
        {
          PageIndex: pageIndex,
          PageSize: this.pageSize,
          Keyword: this.filterFields.keyword,
          DateStart: this.filterFields.DateStart,
          DateEnd: this.filterFields.DateEnd
        }
      );
      this.loading = true;
      return this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 800);
      });
    }
  }
};
</script>

<style lang="scss">
</style>

