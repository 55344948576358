<template>
  <!-- 出库统计 -->
  <div class="module-wrapper m-stkout-sts">
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :is-fields-search="isShowFiedsSearch"
      :search-fields="onSearch"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :total-count="totalCount"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('StsSalesOut')"
      box-class="m-stkout-sts"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar search-box">
        <div v-if="true">
          <znl-input
            form-type="datepicker"
            :disabled="false"
            placeholder="发货日期"
            value-format="yyyy-MM-dd"
            size="mini"
            layout="left"
            width="90px"
            :border="true"
            :clearable="true"
            v-model="filterFields.startOutOn"
          ></znl-input>
          <span>到</span>
          <znl-input
            form-type="datepicker"
            class="ml5"
            :disabled="false"
            placeholder="发货日期"
            value-format="yyyy-MM-dd"
            size="mini"
            layout="left"
            width="90px"
            :border="true"
            :clearable="true"
            inp-perc="100%"
            v-model="filterFields.endOutOn"
          ></znl-input>
          <el-button type="text" class="underline font12" @click="setCurrentMonth">本月</el-button>
          <el-button type="text" class="underline font12" @click="setPreMonth">上个月</el-button>
          <el-button type="text" class="underline font12" @click="setLast3Month">近三个月</el-button>

          <znl-input
            from-type="input"
            :disable="false"
            placeholder="请输入型号"
            size="mini"
            layout="left"
            width="80px"
            inp-perc="100%"
            class="box_input_checkbox"
            :border="true"
            :clearable="true"
            v-model="filterFields.Model"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>

          <znl-input
            from-type="input"
            :disable="false"
            placeholder="请输入客户名称"
            size="mini"
            layout="left"
            width="130px"
            inp-perc="100%"
            class="box_input_checkbox"
            :border="true"
            :clearable="true"
            v-model="filterFields.CustomerName"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>
          <el-checkbox
            class="accurate-checbox"
            style="margin-right: 5px !important;"
            @change="onChange"
            size="small"
            v-model="accurateType"
          >精确</el-checkbox>

          <!-- 收款方式 -->
          <znl-input
            layout="left"
            form-type="select"
            :disabled="false"
            width="80px"
            :height="350"
            placeholder="收款方式"
            popper-class="popperClass"
            :clearable="false"
            :border="true"
            :multiple="true"
            :select-options="selectOptionsPaymentTypeOptions"
            @change="(val) => {filterEx.ReceiptTypeArray = val}"
            v-model="filterEx.ReceiptTypeArray"
          ></znl-input>

          <znl-input
            title-width="70px"
            layout="left"
            ref="CurrencyCode"
            form-type="select"
            width="55px"
            placeholder="币种"
            popper-class="popperClass"
            :border="true"
            :clearable="true"
            :select-options="selectOptionsCurrencyCode"
            v-model="filterFields.CurrencyCode"
          ></znl-input>

          <!-- 搜索关键值 -->
          <znl-input
            from-type="input"
            :disable="false"
            placeholder="搜索关键值"
            size="mini"
            layout="left"
            width="80px"
            inp-perc="100%"
            class="box_input_checkbox"
            :border="true"
            :clearable="true"
            v-model="filterEx.Keywords"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>
          <znl-input
            title-width="70px"
            layout="left"
            ref="CurrencyCode"
            form-type="select"
            width="70px"
            placeholder="-关键字-"
            popper-class="popperClass"
            :border="true"
            :clearable="true"
            :select-options="filterEx.KeywordsFieldOptions"
            v-model="filterEx.KeywordsField"
          ></znl-input>

          <znl-input
            form-type="datepicker"
            :disabled="false"
            placeholder="制单时间从"
            value-format="yyyy-MM-dd"
            size="mini"
            layout="left"
            width="90px"
            :border="true"
            :clearable="true"
            v-model="filterEx.CreatedTimeStart"
          ></znl-input>
          <znl-input
            form-type="datepicker"
            :disabled="false"
            placeholder="制单时间止"
            value-format="yyyy-MM-dd"
            size="mini"
            layout="left"
            width="90px"
            :border="true"
            :clearable="true"
            inp-perc="100%"
            v-model="filterEx.CreatedTimeEnd"
          ></znl-input>
        </div>

        <div v-if="true">
          <znl-input
            form-type="datepicker"
            :disabled="false"
            placeholder="入库时间从"
            value-format="yyyy-MM-dd"
            size="mini"
            layout="left"
            width="90px"
            :border="true"
            :clearable="true"
            v-model="filterEx.StkInDateStart"
          ></znl-input>
          <znl-input
            form-type="datepicker"
            :disabled="false"
            placeholder="入库时间止"
            value-format="yyyy-MM-dd"
            size="mini"
            layout="left"
            width="90px"
            :border="true"
            :clearable="true"
            inp-perc="100%"
            v-model="filterEx.StkInDateEnd"
          ></znl-input>

          <znl-input
            title-width="70px"
            layout="left"
            ref="Shipper"
            form-type="select"
            width="70px"
            placeholder="销售员"
            popper-class="popperClass"
            :border="true"
            :clearable="true"
            :select-options="userList"
            v-model="filterFields.Shipper"
          ></znl-input>

          <znl-input
            from-type="input"
            :disable="false"
            placeholder="合伙人"
            size="mini"
            layout="left"
            width="80px"
            inp-perc="100%"
            class="box_input_checkbox"
            :border="true"
            :clearable="true"
            v-model="filterFields.Partner"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>

          <znl-input
            from-type="input"
            :disable="false"
            placeholder="采购员"
            size="mini"
            layout="left"
            width="80px"
            inp-perc="100%"
            class="box_input_checkbox"
            :border="true"
            :clearable="true"
            v-model="filterFields.BuyerName"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>

          <znl-input
            form-type="datepicker"
            :disabled="false"
            placeholder="收款日期从"
            value-format="yyyy-MM-dd"
            size="mini"
            layout="left"
            width="90px"
            :border="true"
            :clearable="true"
            v-model="filterFields.ReceiptTimeStart"
          ></znl-input>
          <znl-input
            form-type="datepicker"
            :disabled="false"
            placeholder="收款日期止"
            value-format="yyyy-MM-dd"
            size="mini"
            layout="left"
            width="90px"
            :border="true"
            :clearable="true"
            inp-perc="100%"
            v-model="filterFields.ReceiptTimeEnd"
          ></znl-input>

          <!-- 状态 -->
          <znl-input
            layout="left"
            form-type="select"
            :disabled="false"
            :border="true"
            width="100px"
            :height="22"
            placeholder="选择状态"
            popper-class="popperClass"
            :clearable="false"
            :multiple="true"
            :tags-clear="false"
            :select-options="statusSelectOptions"
            @change="val => {filterEx.FilterStatus = val}"
            v-model="filterEx.FilterStatus"
          ></znl-input>

          <znl-button type="multiple" :height="22" style-type="mac" class="box-left-btn search-btn">
            <znl-button-menu @click="onSearch()" tip="点击向下三角符号显示/隐藏表头搜索">
              <i class="iconfont icon-search_ic"></i>
              <span>搜索</span>
            </znl-button-menu>
            <znl-button-menu :width="20" tip="高级搜索" @click="onShowFiedsSearch()">
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button>

          <znl-button
            :height="22"
            style-type="mac"
            @click="onQuotedSta()"
            v-if="this.hasRes('Create')"
          >
            <i class="iconfont"></i>
            <span>生成对账单</span>
          </znl-button>

          <znl-button
            style-type="mac"
            :btns="exportBtns"
            :height="22"
            @click="onExport"
            tip="按搜索条件"
            v-if="this.hasRes('Export')"
          >
            <i class="iconfont icon-leading-out-wh_btn_i"></i>
            <span>导出</span>
          </znl-button>

          <znl-button style-type="mac" :isShow="hasRes('Print')" type="multiple">
            <znl-button-menu @click="onPrintData">
              <i class="iconfont icon-print_btn_ic"></i>
              <span>打印对账单</span>
            </znl-button-menu>
            <znl-button-menu :width="16" tip="打印设置" @click="znlPrintVisible=true">
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button>
        </div>
      </div>

      <div slot="footer" class="v-table-footer-total stsSalesOut-footer">
        <div v-on:mouseenter="dataDetails(1)" v-on:mouseleave="dataDetails(0)">
          <div v-show="this.detailedvshow" class="detail-div">
            <el-row
              class="footer-row-box"
              v-for="(item, key) in outStatisticSearchsList"
              :key="key"
            >
              <el-col :span="24">
                <span>
                  <span class="stsPurchaseIn-currencyCode">[{{item.CurrencyCode}}]</span>&nbsp;&nbsp;
                  <span class="sts-item">
                    出货量:
                    <span class="stsPurchaseIn-amount">{{ moneyFormat(item.TotalQty) }}</span>
                  </span>
                  <span class="sts-item" v-if="viewSalesPrice">
                    销售金额:
                    <span class="stsPurchaseIn-amount">{{ moneyFormat(item.TotalAmount)}}</span>
                  </span>
                  <span class="sts-item" v-if="viewSalesPrice">
                    已收:
                    <span
                      class="stsPurchaseIn-amount"
                    >{{ moneyFormat(item.TotalReceivedAmount) }}</span>
                  </span>
                  <span class="sts-item" v-if="viewSalesPrice">
                    未收:
                    <span
                      class="stsPurchaseIn-amount"
                    >{{ moneyFormat(item.TotalUnReceiveAmount) }}</span>
                  </span>
                  <span class="sts-item">
                    其他:
                    <span
                      class="stsPurchaseIn-amount"
                    >{{ moneyFormat(item.TotalOtherFeeAmount) }}</span>
                  </span>
                  <span class="sts-item" v-if="!noSeeProfit&&viewBuyPrice">
                    毛利:
                    <span class="stsPurchaseIn-amount">{{ moneyFormat(item.TotalGrossProfit) }}</span>
                  </span>
                  <span class="sts-item" v-if="!noSeeProfit&&viewBuyPrice">
                    净利:
                    <span
                      class="stsPurchaseIn-amount"
                    >{{ moneyFormat(item.TotalNetGrossProfit) }}</span>
                  </span>
                </span>
              </el-col>
            </el-row>
          </div>
          <div :class="detailedvshow ? 'summary-show' : ''">
            <el-row class="footer-row-box">
              <el-col :span="24">
                <ul class="summary">
                  <li class="stsPurchaseIn-currencyCode">{{'[汇总]'}}&nbsp;&nbsp;</li>
                  <li class="sts-item" v-if="viewSalesPrice">
                    销售总金额:
                    <span
                      class="stsPurchaseIn-amount"
                    >{{ moneyFormat(outStatisticSearchsTotal.TotalTaxAmount) }}</span>
                  </li>
                  <li class="sts-item">
                    其他:
                    <span
                      class="stsPurchaseIn-amount"
                    >{{ moneyFormat(outStatisticSearchsTotal.TotalOtherFeeAmount) }}</span>
                  </li>
                  <li class="sts-item" v-if="!noSeeProfit&&viewBuyPrice">
                    毛利:
                    <span
                      class="stsPurchaseIn-amount"
                    >{{ moneyFormat(outStatisticSearchsTotal.TotalGrossProfit) }}</span>
                  </li>
                  <li class="sts-item" v-if="!noSeeProfit&&viewBuyPrice">
                    净利:
                    <span
                      class="stsPurchaseIn-amount"
                    >{{ moneyFormat(outStatisticSearchsTotal.TotalNetGrossProfit) }}</span>
                  </li>
                  <li class="sts-item" v-show="!detailedvshow">
                    <i class="icon iconfont icon-table_error_ic more"></i>
                  </li>
                </ul>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </znl-table>
    <export-column
      :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="defaultConfig"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="()=>{setExportColumnVisible=false}"
      @confirm="(v)=>{setExportColumnVisible=false}"
    ></export-column>

    <!-- 打印对账单 -->
    <znl-print
      :visible.sync="znlPrintVisible"
      :printInfo="printInfo"
      :loading="printLoading"
      :is-get-default-param="printInfo.isGetDefaultParam"
      print-type="Statement"
      title="对账单打印设置"
      page-code="StsSalesOut"
      @set-print-param="setPrintParam"
      @confirm-print="confirmPrint"
    ></znl-print>

    <!-- 生成对账单 -->
    <created-statement
      ref="create"
      :statementvisible="envisible"
      @close="()=>{envisible=false}"
      @save-success="onRefresh"
    ></created-statement>
  </div>
</template>
<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import ExportColumn from "@c_modules/Crm/setExportColumn";
import CreatedStatement from "@c_modules/Sts/CreatedStatement";
import {
  exportData,
  erpTableSetHandler,
  numberFormat,
  mixin as commonMixin
} from "@scripts/methods/common";
import ZnlPrint from "@c_common/znlGrid/commonComponents/printTemplates";

const CONFIG = {
  configURL: "StsSalesOut/GetConfig",
  saveConfigURL: "StsSalesOut/SaveConfig",
  searchURL: "StsSalesOut/Search",
  OutStatisticSearchUrl: "StsSalesOut/StatisticSearch"
};

export default {
  name: "StsSalesOut",
  mixins: [
    getCommonDataMixin,
    exportData,
    erpTableSetHandler,
    numberFormat,
    commonMixin
  ],
  config: CONFIG,
  components: {
    ExportColumn,
    CreatedStatement,
    ZnlPrint
  },
  data() {
    return {
      accurateType: false, // 是否精确
      isLikeSearch: true,
      isShowFiedsSearch: false,
      envisible: false, // 对账单
      columns: [],
      itemSource: [],
      statusSelectOptions: [
        { key: "已出库", value: "已出库" },
        { key: "未出库", value: "未出库" },
        { key: "已撤单", value: "已撤单" },
        { key: "已收款", value: "已收款" },
        { key: "部分收款", value: "部分收款" },
        { key: "未收款", value: "未收款" },
        { key: "负利润", value: "负利润" },
        { key: "含税", value: "含税" },
        { key: "未税", value: "未税" },
        { key: "已开票", value: "已开票" },
        { key: "部分开票", value: "部分开票" },
        { key: "未开票", value: "未开票" }
      ],
      status: [],
      filterFields: {
        startOutOn: "",
        endOutOn: "",
        recevieTimeStart: "",
        recevieTimeEnd: "",
        CustomerName: "", // 客户名称
        MatchOperation: "",
        CurrencyCode: "", // 币种
        StkoutStatus: "", // 出货状态
        Shipper: "", // 销售员
        BuyerName: "", // 采购员
        Partner: "" // 合伙人
      },
      filterEx: {
        CreatedTimeStart: "", // 制单时间开始
        CreatedTimeEnd: "", // 制单时间结束
        StkInDateStart: "", // 入库日期开始
        StkInDateEnd: "", // 入库日期结束
        ReceiptTimeStart: "", // 收款时间开始
        ReceiptTimeEnd: "", // 收款时间结束
        Keywords: "", // 关键字
        KeywordsField: "", // 关键字对应的列
        KeywordsFieldOptions: [
          { key: "BillNo", value: "单号" },
          { key: "Shipper", value: "销售员" },
          // { key: 'ReceiptType', value: '收款方式' },
          { key: "STK_Out_Remark", value: "单据备注" },
          { key: "TransportCompany", value: "快递公司" },
          { key: "ShippingNo", value: "快递单号" },
          { key: "TaxRate", value: "税点", type: 2 },
          // { key: 'CurrencyCode', value: '币种' },
          { key: "TicketNo", value: "发票号" },
          { key: "SalCompany", value: "销售公司" },
          { key: "CreatedByName", value: "制单人" },
          { key: "Brand", value: "品牌" },
          { key: "Packaging", value: "封装" },
          { key: "MakeYear", value: "年份" },
          { key: "SupplierInfo", value: "供应商信息" },
          { key: "Remark", value: "备注" },
          { key: "CustomerPartNo", value: "客户料号" },
          { key: "DeliveryDate", value: "货期" },
          { key: "InvType", value: "库存类型" },
          { key: "SalesModel", value: "销售型号" },
          { key: "Contact", value: "联系人" },
          { key: "Address", value: "收货地址" },
          { key: "CustomerOrderNo", value: "客户订单号" },
          { key: "ProductCategory", value: "产品类型" },
          { key: "Remark2", value: "备注2" },
          { key: "Remark3", value: "备注3" },
          { key: "StorageName", value: "出库位置" }
        ],
        ReceiptTypeArray: 0, // 收款方式
        FilterStatus: [] // 请选择状态
      },
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [], // 权限列表，,
      // 绑定列表中的下拉框
      dataMapSourse: {
        UnitSource: [], // 单位列表数据源
        InvTypeSource: [] // 库存类型列表数据源
      },
      outStatisticSearchsList: [], // 统计集合,
      outStatisticSearchsTotal: {
        TotalOtherFeeAmount: "",
        TotalReceivedAmount: "",
        TotleGrossProfit: "",
        TotalNetGrossProfit: ""
      }, // 汇总
      detailedvshow: false, // 其他统计
      parameterConfig: [],

      isByCustomer: false, // 是否分客户打印
      printBtns: [
        {
          name: "分客户打印",
          click: () => {
            this.isByCustomer = true;
            this.onPrintData();
          }
        }
      ],
      exportBtns: [
        {
          name: "设置导出列",
          click: () => {
            this.showExportColumn();
          }
        }
      ],

      setExportColumnVisible: false,
      addColumnsList: [],
      defaultConfig: {},
      setSaveConfigURL: "",
      loading: false,

      // 权限
      viewBuyPrice: false, // 看采购价
      viewSalesPrice: false, // 看销售价
      noSeeProfit: true, // 不看利润
      stockResource: [], // 库存权限
      onQuotedRows: {
        CustomerGUID: "",
        CustomerName: "",
        SupplierName: "",
        CurrencyCode: "",
        CurrencyName: "",
        StartOutOn: "",
        EndOutOn: "",
        MatchOperation: "Equal"
      }, // 弹出对账单
      saleAccountTitle: "",
      isExport: false,
      currencyList: [],
      userList: [],
      taxRateList: [],
      qtyUnitList: [],
      recType: [],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,

      // 打印模块参数
      printLoading: false,
      znlPrintVisible: false,
      printInfo: {
        isGetDefaultParam: true, // 是否已经从打印模板获取过默认值
        title: 0, // 公司抬头
        template: "" // 打印模板类型
      } // end 打印模块参数
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  async created() {},
  async mounted() {
    this.currencyList = _.map(await this.getCurrencyCodeListAsync, function(
      obj,
      index
    ) {
      return { key: obj.value, value: obj.key };
    });
    this.userList = _.map(this.selectOptionsAllUserURL, function(obj) {
      return { key: obj.value, value: obj.value };
    });
    // 税点
    this.taxRateList = await this.getTaxRateListAsync;
    // 单位
    this.qtyUnitList = await this.selectOptionsQtyUnit;
    // 收款方式
    this.recType = await this.getRecPayTypeListAsync;

    let dateArr = this.getfromtoDate(new Date());
    this.filterFields.startOutOn = dateArr[0];
    this.filterFields.endOutOn = dateArr[1];
    await this.onInit();
  },
  methods: {
    async onExport() {
      this.loading = true;
      this.loading = await this.exportData(
        "StsSalesOut",
        this.getSearchWhere(1),
        this.columns,
        "销售出库统计"
      );
    },
    showExportColumn() {
      this.setExportColumnVisible = true;
      this.addColumnsList = this.columns;
      this.setSaveConfigURL = this.$options.config.saveConfigURL;
    },
    async onInit() {
      let config = await this.isSaveStorage("stsSaleOut", CONFIG.configURL);

      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }

      let columns = config.ColumnConfigs;
      this.parameterConfig = config.ParamJosn;

      let colIndex = _.findIndex(columns, item => item.BindField === "Status");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 60,
          options: [
            { key: "已出库", value: 3 },
            { key: "未出库", value: 1 },
            { key: "已撤消", value: 9 }
          ]
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "IsReceived");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 60,
          options: [
            { key: "未收款", value: "未收款" },
            { key: "部分收款", value: "部分收款" },
            { key: "已收款", value: "已收款" }
          ]
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "IsStatement");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 60,
          options: [
            { key: "是", value: true },
            { key: "否", value: false }
          ]
        });
      }
      colIndex = _.findIndex(
        columns,
        item => item.BindField === "CurrencyCode"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.currencyList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "Unit");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.qtyUnitList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "TaxRate");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.taxRateList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "ReceiptType");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.recType
        });
      }

      // 处理特殊权限
      this.viewBuyPrice = this.getSpecialResourceByCode("ViewBuyPrice");
      this.viewSalesPrice = this.getSpecialResourceByCode("ViewSalesPrice");
      this.noSeeProfit = this.getSpecialResourceByCode("NoSeeProfit");
      let noSeeCustomer = this.getSpecialResourceByCode("NoSeeCustomer");
      let noSeeSupplier = this.getSpecialResourceByCode("NoSeeSupplier");

      _.remove(columns, item => {
        // 不看客户名称
        if (noSeeCustomer && item.BindField === "CustomerName") {
          return true;
        }
        // 不看利润
        if (
          this.noSeeProfit &&
          (item.BindField === "GrossProfit" ||
            item.BindField === "GrossProfitRate")
        ) {
          return true;
        }
        // 不看采购价
        if (
          !this.viewBuyPrice &&
          (item.BindField === "BuyPrice" ||
            item.BindField === "GrossProfit" ||
            item.BindField === "PurchaseAmount")
        ) {
          return true;
        }
        // 不看销售价
        if (
          !this.viewSalesPrice &&
          (item.BindField === "Price" ||
            item.BindField === "PriceInTax" ||
            item.BindField === "ReceiveAmount" ||
            item.BindField === "Amount")
        ) {
          return true;
        }
        // 不看供应商资料
        if (noSeeSupplier && item.BindField === "SupplierInfo") {
          return true;
        }
      });

      if (noSeeSupplier) {
        _.remove(
          this.filterEx.KeywordsFieldOptions,
          item => item === "SupplierInfo"
        );
      }

      this.parameterConfig = config.ParamJosn;
      this.columns = columns;
      this.defaultConfig = config;
      this.$refs.table.init();
      if (this.initData) {
        await this.onBindData(1);
      }
      this.paramConfiguration();
      this.$emit("page-loading", false);
    },
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },

    // 获取参数配置
    paramConfiguration() {
      // 打印单据模板
      let info = this.getPrintInfo(this.parameterConfig);
      this.printInfo.title = info.title;
      this.printInfo.template = info.template;
      console.log(this.printInfo, 'printinfo');

    },

    // 打印参数，选中的打印模板
    setPrintParam(printInfo) {
      this.printInfo.title = printInfo.title;
      this.printInfo.template = printInfo.template;
      this.printInfo.isGetDefaultParam = true;
    },
    confirmPrint(data) {
      this.onPrintData();
    },

    onPrintData() {
      if (
        this.filterFields.startOutOn === "" ||
        this.filterFields.endOutOn === ""
      ) {
        this.$message({ message: "请选择发货日期范围", type: "error" });
        return false;
      }

      if (!this.hasValue(this.printInfo.template)) {
        this.$message({ message: "请选择打印模板!", type: "warning" });
        this.znlPrintVisible = true;
      } else if (!this.printInfo.isGetDefaultParam) {
        // 通知打印模板模块，获取打印所需参数
        this.printInfo.isGetDefaultParam = true;
      } else {
        let param = {
          TableName: this.printInfo.template, // 模板id
          SubCompanyId: this.printInfo.title, // 子公司（抬头）
          StartOutOn: this.filterFields.startOutOn,
          EndOutOn: this.filterFields.endOutOn,
          RecevieTimeStart: this.filterFields.recevieTimeStart,
          RecevieTimeEnd: this.filterFields.recevieTimeEnd,
          IsByCustomer: this.isByCustomer
        };
        this.filterStatusMap(param);
        param = _.extend({}, this.filterFields, param);
        console.log(param, "start to print sales order");
        this.printStatement(param);
      }
    },
    filterStatusMap(param) {
      if (!this.filterEx.FilterStatus) return
      const Status = [], ReceiveStatus = [], TicketStatus = []//, AuditedStatus = []
      this.filterEx.FilterStatus.forEach(e => {
        switch (e) {
          case "已出库": Status.push(3); break;
          case "未出库": Status.push(1); break;
          case "已撤单": Status.push(9); break;
          case "已收款": ReceiveStatus.push(3); break;
          case "部分收款": ReceiveStatus.push(2); break;
          case "未收款": ReceiveStatus.push(1); break;
          case "负利润": param.IsNegativeProfit = true; break;
          case "含税": param.HasTax = true; break;
          case "未税": param.HasTax = false; break;
          case "已开票": TicketStatus.push(3); break;
          case "部分开票": TicketStatus.push(2); break;
          case "未开票": TicketStatus.push(1); break;
          default: console.error("未知状态filterEx.FilterStatus", e)
        }
      })
      if (Status.length) param.Status = Status.sort().join();
      if (ReceiveStatus.length) param.ReceiveStatus = ReceiveStatus.sort().join();
      if (TicketStatus.length) param.TicketStatus = TicketStatus.sort().join();
      // console.error(Status, ReceiveStatus, TicketStatus, param);

    },
    dataDetails(index) {
      if (index === 1) {
        this.detailedvshow = true;
      } else {
        this.detailedvshow = false;
      }
    },
    onHeadSearch(params) {
      this.filterFields.Model = params.Model;
      return this.onBindData(1);
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.filterFields = _.extend({}, this.filterFields, params);
      }
      return this.onBindData(1);
    },
    onRefresh() {
      return this.onBindData(this.pageIndex);
    },
    onShowFiedsSearch() {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true;
      } else {
        this.isShowFiedsSearch = false;
      }
    },
    getSearchWhere(pageIndex) {
      let matchOperation = this.isLikeSearch === true ? "Like" : "Equal";
      this.pageIndex = pageIndex;
      this.filterFields.MatchOperation = matchOperation;
      let filterLast = {};
      // 制单时间
      if (this.filterEx.CreatedTimeStart || this.filterEx.CreatedTimeEnd) {
        this.filterFields.CreatedTime = " - ";
        if (this.filterEx.CreatedTimeStart) {
          this.filterFields.CreatedTime =
            this.filterEx.CreatedTimeStart + this.filterFields.CreatedTime;
        }
        if (this.filterEx.CreatedTimeEnd) {
          this.filterFields.CreatedTime += this.filterEx.CreatedTimeEnd;
        }
      } else {
        this.filterFields.CreatedTime = "";
      }

      // 收款时间
      if (
        this.filterFields.ReceiptTimeStart ||
        this.filterFields.ReceiptTimeEnd
      ) {
        this.filterFields.ReceiveOn = " - ";
        if (this.filterFields.ReceiptTimeStart) {
          this.filterFields.ReceiveOn =
            this.filterFields.ReceiptTimeStart + this.filterFields.ReceiveOn;
        }
        if (this.filterFields.ReceiptTimeEnd) {
          this.filterFields.ReceiveOn += this.filterFields.ReceiptTimeEnd;
        }
      } else {
        this.filterFields.ReceiveOn = "";
      }

      if (
        this.filterEx.Keywords &&
        this.filterEx.Keywords !== "" &&
        this.filterEx.KeywordsField !== ""
      ) {
        let field = this.filterEx.KeywordsFieldOptions.find(
          m => m.key === this.filterEx.KeywordsField
        );
        if (field) {
          let value = this.filterEx.Keywords;
          if (field.type && field.type === 2) {
            value = this.toFloat(this.filterEx.Keywords);
          }
          filterLast[this.filterEx.KeywordsField] = value;
        }
      }
      filterLast = _.extend({}, this.filterFields, filterLast);
      console.log(this.filterEx);
      let binddata = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(filterLast),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          // 外部搜索条件
          StartOutOn: this.filterFields.startOutOn,
          EndOutOn: this.filterFields.endOutOn,
          MatchOperation: this.filterFields.MatchOperation,
          CustomerName: this.filterFields.CustomerName,
          Status: this.status,
          ReceiptType: this.filterEx.ReceiptTypeArray,
          // 入库日期
          StkInDateStart: this.filterEx.StkInDateStart,
          StkInDateEnd: this.filterEx.StkInDateEnd,
          Status: this.filterEx.FilterStatus // 选择状态
        }
      );
      return binddata;
    },
    onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      let binddata = this.getSearchWhere(pageIndex);
      this.loading = true;
      this.$post(CONFIG.searchURL, binddata, data => {
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
      }).finally(() => {
        this.$emit("grid-show", true);
        this.$emit("page-loading", false);
        setTimeout(() => {
          this.loading = false;
        }, 800);
      });
      if (pageIndex === 1) {
        this.$post(CONFIG.OutStatisticSearchUrl, binddata, data => {
          if (data.length > 0) {
            let _totalTaxAmountSum = 0;
            let _totalGrossProfitSum = 0;
            let _totalOtherFeeAmountSum = 0;
            let _totalNetGrossProfitSum = 0;
            _.each(data, item => {
              _totalTaxAmountSum =
                _totalTaxAmountSum + item.TotalTaxAmount || 0;
              _totalGrossProfitSum =
                _totalGrossProfitSum + item.TotalGrossProfit || 0;
              _totalOtherFeeAmountSum =
                _totalOtherFeeAmountSum + item.TotalOtherFeeAmount || 0;
              _totalNetGrossProfitSum =
                _totalNetGrossProfitSum + item.TotalNetGrossProfit || 0;
            });
            this.outStatisticSearchsTotal.TotalTaxAmount = this.toFloat(
              _totalTaxAmountSum,
              2
            );
            this.outStatisticSearchsTotal.TotalOtherFeeAmount = _totalOtherFeeAmountSum;
            this.outStatisticSearchsTotal.TotalGrossProfit = _totalGrossProfitSum;
            this.outStatisticSearchsTotal.TotalNetGrossProfit = _totalNetGrossProfitSum;
          }
          this.outStatisticSearchsList = data;
        });
      }
    },
    onChange(val) {
      if (val) {
        this.isLikeSearch = false;
      } else {
        this.isLikeSearch = true;
      }
    },
    onChangeStatus(val, item) {
      if (item.key === 0) {
        this.status = [0];
      } else {
        let checkeds = [];
        _.each(this.status, m => {
          if (m !== 0) {
            checkeds.push(m);
          }
        });
        this.status = checkeds;
      }
    },

    onQuotedSta() {
      if (
        !this.hasValue(this.filterFields.startOutOn) ||
        !this.hasValue(this.filterFields.endOutOn)
      ) {
        this.$message({
          message: "请选择入库开始日期和结束日期",
          type: "error"
        });
        return;
      }
      this.envisible = true;
      let row = this.$refs.table.getActiveRow();
      if (row && !this.hasValue(row.CustomerName)) {
        row = this.itemSource[0];
      }
      this.onQuotedRows = {};
      let systemtime = new Date();
      let dateStart = null;
      let dateEnd = null;
      let dateArr = this.getfromtoDate(systemtime);
      // if (this.hasValue(this.filterFields.startOutOn)) {
      //   this.dateStart = this.filterFields.startOutOn
      // } else {
      //   this.dateStart = dateArr[0]
      // }
      // if (this.hasValue(this.filterFields.endOutOn)) {
      //   this.dateEnd = this.filterFields.endOutOn
      // } else {
      //   this.dateEnd = dateArr[1]
      // }
      // console.log("row",row)
      if (this.hasValue(row.CustomerName)) {
        this.onQuotedRows.CustomerName = row.CustomerName;
        this.onQuotedRows.CurrencyCode = row.CurrencyCode;
        this.onQuotedRows.CurrencyName = row.CurrencyName;

        // 将日期字符串转为Date
        let strdate = row.ShipTime;
        let savedate = strdate.replace(/-/g, "/");
        let date = new Date(savedate);
        // global方法获取月头月尾
        dateArr = this.getfromtoDate(date);
      } else if (this.hasValue(this.filterFields.CustomerName)) {
        this.onQuotedRows.CustomerName = this.filterFields.CustomerName
        this.onQuotedRows.CurrencyCode = "RMB";
        this.onQuotedRows.CurrencyName = "人民币";
      } else {
        dateArr = this.getfromtoDate(systemtime);
        this.onQuotedRows.CurrencyCode = "RMB";
        this.onQuotedRows.CurrencyName = "人民币";
      }
      this.onQuotedRows.StartOutOn = dateArr[0];
      this.onQuotedRows.EndOutOn = dateArr[1];
      this.onQuotedRows.StartOutOn = this.filterFields.startOutOn;
      this.onQuotedRows.EndOutOn = this.filterFields.endOutOn;
      this.$nextTick(function() {
        this.envisible = true;
        this.$refs.create.onQuotedRows(this.onQuotedRows, 1);
      });
    },

    // 本月
    setCurrentMonth() {
      let date = new Date();
      let y = date.getFullYear();
      let m = date.getMonth();
      this.filterFields.startOutOn = new Date(y, m, 1);
      this.filterFields.endOutOn = new Date(y, m + 1, 0);
    },
    // 上个月
    setPreMonth() {
      let date = new Date();
      let y = date.getFullYear();
      let m = date.getMonth();
      this.filterFields.startOutOn = new Date(y, m - 1, 1);
      this.filterFields.endOutOn = new Date(y, m, 0);
    },
    // 最近三个月
    setLast3Month() {
      let date = new Date();
      let y = date.getFullYear();
      let m = date.getMonth();
      let d = date.getDate();
      this.filterFields.startOutOn = new Date(y, m - 3, d);
      this.filterFields.endOutOn = date;
    },

    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("StsSalesOut", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("StsSalesOut", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("StsSalesOut", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("StsSalesOut");
    }
  }
};
</script>

<style lang="scss">
.stsSalesOut-footer {
  position: absolute;
  bottom: 0;
  z-index: 10;
  left: -5px;
  text-align: left;
  padding: 0px;

  .stsPurchaseIn-currencyCode {
    color: rgb(19, 88, 237);
  }
  .stsPurchaseIn-amount {
    color: rgb(255, 0, 0);
  }
  .detail-div {
    background-color: #fff;
    border: 1px solid #ccc;
    border-bottom: 0;
    padding-top: 5px;
    padding-left: 0px !important;
  }
  .footer-row-box {
    padding-left: 8px;
    .sts-item {
      margin-right: 7px;
      .more {
        font-size: 18px;
        color: #ee7700;
      }
    }
  }
  .summary {
    line-height: 20px;
    li {
      line-height: 19px;
      float: left;
      margin-right: 7px;
    }
  }
  .summary-show {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background-color: #fff;
  }
}
.m-stkout-sts {
  .font12 {
    margin-left: 0;
  }
}
</style>

