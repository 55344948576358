<template>
    <znl-layout-spa
      :page-loading="$store.state.pageLoading && pageLoading"
      >
      <sts-customer
          slot="znlLayoutSpa"
          class="znlLayoutSpa"
          @page-loading="(d)=>{pageLoading=d}"></sts-customer>
      <!-- <customer-stk-out-chart
                        :searchModel="modelSummarySearchModel"
                        :refresh="refreshModel"
                        slot="right"
                        :initData="true"
                        ></customer-stk-out-chart> -->
     </znl-layout-spa>
</template>

<script>
import StsCustomer from '@c_modules/Sts/StsCustomer'
// import CustomerStkOutChart from '@c_modules/Rpt/CustomerStkOutChart'

export default {
  name: 'VCustomerAnalyze',
  components: {
    StsCustomer
    // CustomerStkOutChart
  },
  data () {
    return {
      stsHotQuoteModels: {},
      pageData: [],
      customerSearchModel: {},
      modelSummarySearchModel: {},
      refreshModel: true,
      salesModel: {},
      pageLoading: true
    }
  },
  methods: {
    async getPageData () {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      return await this.$post(pageUrl, {PageCode: 'VCustomerAnalyze'})
    },
    stsModelChange (item) {
      this.modelSummarySearchModel = item
      this.refreshModel = !this.refreshModel
    },
    hasRight (code) {
      return _.some(this.pageData, page => page.Code === code)
    }
  },
  async created () {
    this.getPageData().then((data) => {
      this.pageData = data
      this.$nextTick(() => {
        initData(this)
      })
    }).catch((e) => {
      // console.log(e)
    })

    const initData = (component) => {
      if (component.$options.config && component.$options.config.configURL) {
        const moduleName = component.$options.config.configURL.split('/')[0]
        const config = _.find(this.pageData, page => page.Code === moduleName)
        if (config && component.onInit) {
          component.onInit(config.UICPageGridConfig)
        } else {
          component.onInit()
        }
      }
      _.each(component.$children, child => {
        initData(child)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'app/assets/styles/_variables.scss';
</style>
