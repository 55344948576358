<template>
  <div class="module-wrapper">
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :search-fields="onSearch"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :total-count="totalCount"
      @totalAmount-click="onShowTotalAmount"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('StsUnReceive')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          form-type="input"
          :disabled="false"
          placeholder="请输入客户名称"
          size="mini"
          layout="left"
          width="180px"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="filterFields.customerName"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>
        <znl-input
          :border="true"
          v-model="filterFields.shipperName"
          form-type="select"
          width="100px"
          :clearable="true"
          :select-options="userOptions"
          placeholder="选择销售员"
        ></znl-input>
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="出货日期"
          size="mini"
          layout="left"
          width="100px"
          :clearable="true"
          :border="true"
          inp-perc="100%"
          v-model="filterFields.startOutOn"
        ></znl-input>
        <span>到</span>
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="出货日期"
          size="mini"
          layout="left"
          width="100px"
          :border="true"
          :clearable="true"
          inp-perc="100%"
          v-model="filterFields.endOutOn"
        ></znl-input>
        <znl-button type="multiple" :height="22" style-type="mac" class="box-left-btn search-btn">
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
        </znl-button>
        <znl-button style-type="mac" :btns="exportBtns" :height="22" @click="onExport" tip="按搜索条件">
          <i class="iconfont icon-leading-out-wh_btn_i"></i>
          <span>导出</span>
        </znl-button>
        <el-checkbox
          v-model="filterFields.OnlyCountUnReceive"
          size="small"
          @change="()=>{onSearch()}"
        >仅显示未收</el-checkbox>
          <el-checkbox
          v-model="filterFields.IsCustomerSummary"
          size="small"
          @change="()=>{onCustomerChange()}"
        >汇总客户</el-checkbox>
      </div>
      <div slot="footer" class="v-table-footer-total">
        <el-row>
          <el-col :span="24">
            <span>
              未收总金额:
              <span
                class="stsPurchaseIn-amount color-red"
              >{{ moneyFormat(SumSearchList.UnReceiveAmountByCustomer) }}</span>
            </span>
            <span style="padding-left: 20px;">
              销售总金额:
              <span
                class="stsPurchaseIn-amount color-red"
              >{{ moneyFormat(SumSearchList.TotalAmountByCustomer) }}</span>
            </span>
            <span style="padding-left: 20px;">
              含税总金额:
              <span
                class="stsPurchaseIn-amount color-red"
              >{{ moneyFormat(SumSearchList.InTaxAmountByCustomer) }}</span>
            </span>
          </el-col>
        </el-row>
      </div>
    </znl-table>
    <StsUnReceiveSalesDetail
      ref="StsUnReceiveDetail"
      v-if="showStsDetail"
      :visible="showStsDetail"
      :onlyCountUnReceive="filterFields.OnlyCountUnReceive"
      :stkCustomerName="stsCustomerName"
      :stkSalesName="stsSalesName"
      :startTime="filterFields.startOutOn"
      :endTime="filterFields.endOutOn"
      @close="()=>{showStsDetail=false}"
    ></StsUnReceiveSalesDetail>
    <export-column
      :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="defaultConfig"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="()=>{setExportColumnVisible=false}"
      @confirm="(v)=>{setExportColumnVisible=false}"
    ></export-column>
  </div>
</template>

<script>

import StsUnReceiveSalesDetail from '@c_modules/Sts/StsUnReceiveSalesDetail'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { exportData, erpTableSetHandler, numberFormat } from '@scripts/methods/common'
import ExportColumn from "@c_modules/Crm/setExportColumn";

const CONFIG = {
  configURL: 'StsUnReceive/GetConfig',
  saveConfigURL: 'StsUnReceive/SaveConfig',
  searchURL: 'StsUnReceive/Search',
  searchSumListURL: 'StsUnReceive/SumReceiveList'
}

export default {
  name: 'StsUnReceive',
  mixins: [getCommonDataMixin, erpTableSetHandler, numberFormat, exportData],
  config: CONFIG,
  components: {
    StsUnReceiveSalesDetail,
    ExportColumn
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      searchFields: {},
      filterFields: {
        customerName: '',
        shipperName: '',
        startOutOn: '',
        endOutOn: '',
        OnlyCountUnReceive: true,
        IsCustomerSummary:false
      },
      exportBtns: [
        {
          name: "设置导出列",
          click: () => {
            this.showExportColumn();
          }
        }
      ],
      setExportColumnVisible: false,
      addColumnsList: [],
      setSaveConfigURL: "",
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      showStsDetail: false, // 是否显示日志
      stsCustomerName: '', // 传递客户名称
      resourceList: [], // 权限列表，
      SumSearchList: [], // 统计集合,
      loading: false,
      stsSalesName: '', // 传递销售员名称
      userOptions: [],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
    }
  },

  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  async created() {
  },
  async mounted() {
    await this.onInit()
  },
  methods: {
    onInit: async function () {
      let config = await this.isSaveStorage('StsUnReceive', CONFIG.configURL)
      if (this.hasValue(config.ResourceList) && config.ResourceList.length > 0) {
        this.resourceList = config.ResourceList.map(item => item.Code)
      }
      // 业务员
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })
      this.userOptions = UserAllowUsers
      let columns = config.ColumnConfigs
      // 表格值的点击 start
      _.extend(columns[_.findIndex(columns, item => item.BindField === 'TotalAmountByCustomer')], {
        event: 'totalAmount-click',
        className: 'v-cell-link'
      })

      this.defaultConfig = config
      this.columns = columns
      this.pageSize = config.PageSize
      this.$refs.table.init()
      if (this.initData) {
        await this.onBindData(1)
      }
      this.$emit('page-loading', false)
    },
    onShowTotalAmount(e) {
      if (e.field !== 'TotalAmountByCustomer') {
        return
      }
      if (this.hasValue(e.row.CustomerName)) {
        this.stsCustomerName = e.row.CustomerName
        this.stsSalesName = e.row.Shipper
        this.$nextTick(() => {
          this.showStsDetail = true
        })
      }
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.searchFields = _.extend({}, this.searchFields, params)
      }
      return this.onBindData(1)
    },
    async onBindData(pageIndex = 1, ) {
      //this.$refs.table.cleanSort()
      this.loading = true
      let filterFields = {}
      if (this.filterFields) {
        _.each(this.filterFields, (value, key) => {
          filterFields[key.substring(0, 1).toUpperCase() + key.substring(1)] = value
        })
      }
      if (pageIndex === 1) {
        this.SumSearchList = await this.$post(CONFIG.searchSumListURL, _.extend({
          FieldWhereString: JSON.stringify(this.searchFields),
          PageIndex: pageIndex,
          PageSize: this.pageSize,
          OrderBy: this.OrderBySearchParams
        }, filterFields))
      }
      let response = await this.$post(CONFIG.searchURL, _.extend({
        FieldWhereString: JSON.stringify(this.searchFields),
        PageIndex: pageIndex,
        PageSize: this.pageSize,
        OrderBy: this.OrderBySearchParams
      }, filterFields))
      this.pageIndex = pageIndex
      this.itemSource = response.ResultList
      this.totalCount = response.TotalCount
      setTimeout(() => {
        this.loading = false
      }, 800);

    },

    hasRes(code) {
      return _.includes(this.resourceList, code)
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("StsUnReceive", e.col)
    },
    onDragDone(col) {
      this.setTableColWidth("StsUnReceive", col)
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols
      this.saveErpTableConfig("StsUnReceive", cols, isDelay)
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("StsUnReceive")
    },
    onCustomerChange(){
      var colIndex = _.findIndex(this.columns, item => item.BindField === 'Shipper')
      if (colIndex > -1 ) {
        _.extend(this.columns[colIndex], {
          IsShow:!this.filterFields.IsCustomerSummary
        })
      }
      this.onSearch();
    },
    async onExport() {
      this.loading = true;
      this.loading = await this.exportData(
        "StsUnReceive",
        this.getSearchWhere(1),
        this.columns,
        "未收款统计"
      );
    },
    showExportColumn() {
      this.setExportColumnVisible = true;
      this.addColumnsList = this.columns;
      this.setSaveConfigURL = this.$options.config.saveConfigURL;
    },
    getSearchWhere(pageIndex) {
      let bindData = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.filterFields),
          PageIndex: pageIndex,
          PageSize: this.pageSize,
          customerName: this.filterFields.customerName,
          shipperName: this.filterFields.shipperName,
          startOutOn: this.filterFields.startOutOn,
          endOutOn: this.filterFields.endOutOn,
          OnlyCountUnReceive: this.filterFields.OnlyCountUnReceive,
          IsCustomerSummary: this.filterFields.IsCustomerSummary
        }
      );
      return bindData;
    }
  }
}
</script>

<style lang="scss">
.znl-stsunreceive-box {
  .el-col-12 {
    width: 100%;
  }
  .stsPurchaseIn-amount {
    color: rgb(255, 0, 0);
  }
}
</style>

