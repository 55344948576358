<template>
  <znl-gridmodule ref="flexGrid"
              header="客户分析"
              height="100%"
              :columns="columns"
              :item-source="itemSource"
              :column-colors="columnColors"
              :row-colors="rowColors"
              :summary-columns="summaryColumns"
              :is-init="false"
              :is-fields-search="true"
              :is-multi-rows-check="false"
              :search-fields="searchModel"
              :page-size="pageSize"
              :page-index="pageIndex"
              :total-count="totalCount"
              :role="role"
              :on-init="onInit"
              :on-refresh="onRefresh"
              :on-search="onSearch"
              :on-save-setting="onSaveSetting"
              :on-save-config='onSaveConfig'
              :on-reset-setting="onResetSetting"
              :on-page-changed="onBindData"
              :has-znl-actions='true'
              :is-btn='true'
              @selection-changed="d=>{$emit('selection-changed',d)}">
                  <!-- <el-dropdown @command="handleCommand">
                    <el-button  type="default" class="form-group znl-btn-mini-auxiliary" size="mini">
                        <i class="iconfont icon-sort_btn_ic"></i><span class="ver">排序方式</span>
                    </el-button>
                      <el-dropdown-menu slot="dropdown" >
                        <el-dropdown-item command=1>最近下单时间升序</el-dropdown-item>
                        <el-dropdown-item command=2>最近下单时间降序</el-dropdown-item>
                        <el-dropdown-item command=3>当月下单量升序</el-dropdown-item>
                        <el-dropdown-item command=4>当月下单量降序</el-dropdown-item>
                        <el-dropdown-item command=5>当月下单额升序</el-dropdown-item>
                        <el-dropdown-item command=6>当月下单额降序</el-dropdown-item>
                      </el-dropdown-menu>
                  </el-dropdown> -->
          <div slot="action-form">
            <!-- <el-button  type="default" class="form-group znl-btn-mini-auxiliary" size="mini">
               <i class="iconfont icon-sort_btn_ic"></i><span class="ver">排序方式</span>
            </el-button> -->
            <znl-input size="mini"
                        width='105px'
                        layout="left"
                        form-type="select"
                        placeholder="最近下单时间"
                        popper-class="popperClass"
                        :is-must-fill="false"
                        :clearable="true"
                         @change="OrderBychange"
                        :select-options="TimeData"
                        v-model="fieldsFilter.timeOrderBy">
            </znl-input>
                <znl-input size="mini"
                        width='90px'
                        layout="left"
                        form-type="select"
                        placeholder="最近下单额"
                        popper-class="popperClass"
                        :is-must-fill="false"
                        :clearable="true"
                         @change="OrderBychange"
                        :select-options="QtyData"
                        v-model="fieldsFilter.amountOrderBy">
                </znl-input>
                 <znl-input size="mini"
                        width='90px'
                        layout="left"
                        form-type="select"
                        placeholder="当月下单额"
                        popper-class="popperClass"
                        :is-must-fill="false"
                        :clearable="true"
                         @change="OrderBychange"
                        :select-options="QtyData"
                        v-model="fieldsFilter.monthAmountOrderBy">
                </znl-input>
                 <znl-input size="mini"
                        width='90px'
                        layout="left"
                        form-type="select"
                        placeholder="当月下单量"
                        popper-class="popperClass"
                        :is-must-fill="false"
                        :clearable="true"
                          @change="OrderBychange"
                        :select-options="QtyData"
                        v-model="fieldsFilter.monthQtyOrderBy">
                </znl-input>
                <customer-stk-out-chart
                        :visible="modelStkInChartVisible"
                        :searchModel="modelSummarySearchModel"
                        :refresh="refreshModel"
                        @close="()=>{modelStkInChartVisible=false}"
                        :initData="true"
                        ></customer-stk-out-chart>
            </div>
  </znl-gridmodule>
</template>
<script>
import CustomerStkOutChart from '@c_modules/Rpt/CustomerStkOutChart'
const CONFIG = {
  configURL: 'AlyCustomer/GetConfig',
  saveConfigURL: 'AlyCustomer/SaveConfig',
  resetConfigURL: 'AlyCustomer/ResetConfig',
  searchURL: 'AlyCustomer/Search'
}

export default {
  name: 'StsCustomer',
  mixins: [],
  config: CONFIG,
  components: {
    CustomerStkOutChart
  },
  data () {
    return {
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {
        timeOrderBy: 0, // 最近下单时间
        amountOrderBy: 0, // 最近下单金额
        monthAmountOrderBy: 0, // 当月下单金额
        monthQtyOrderBy: 0 // 当月下单数量
      },
      searchFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      loadingInstance: {},
      command: '',
      gridShow: false,
      modelStkInChartVisible: false,
      modelSummarySearchModel: {},
      refreshModel: false,
      TimeData:
      [
        { key: 1, value: '由近到远' },
        { key: 2, value: '由远到近' }
      ],
      QtyData:
      [
        { key: 1, value: '由大到小' },
        { key: 2, value: '由小到大' }
      ]
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String
  },
  methods: {
    OrderBychange () {
      setTimeout(() => {
        this.onBindData(1)// 查询
      }, 100)
    },
    async onInit (config, refresh) {
      if (!config || config === undefined) {
        // config = await this.$post(this.$options.config.configURL)
        config = await this.isSaveStorage('stsCustomer', this.$options.config.configURL)
      }
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      this.defaultConfig = config
      this.columns = columns
      _.each(columns, column => {
        if (column.binding === 'MonthOrderAmount') {
          column.buttons = [{
            'content': (row) => {
              return row.MonthOrderAmount || ''
            },
            'className': 'znl-table-btn-success',
            'when': true,
            'click': (row) => {
              this.modelSummarySearchModel = row
              this.refreshModel = !this.refreshModel
              this.modelStkInChartVisible = true
              // console.log(this.modelStkInChartVisible)
            }
          }]
          column.dataType = 5
        }
      })
      this.role = config.Role
      this.pageSize = config.PageSize
      this.resourceList = config.ResourceList.map(item => item.Code)
      if (this.initData || refresh) {
        this.onBindData()
      }
    },
    async onBindData (pageIndex = 1) {
      let filterFields = {
          // 外部搜索条件
        TimeOrderBy: this.fieldsFilter.timeOrderBy === '' ? 0 : this.fieldsFilter.timeOrderBy,
        AmountOrderBy: this.fieldsFilter.amountOrderBy === '' ? 0 : this.fieldsFilter.amountOrderBy,
        MonthAmountOrderBy: this.fieldsFilter.monthAmountOrderBy === '' ? 0 : this.fieldsFilter.monthAmountOrderBy,
        MonthQtyOrderBy: this.fieldsFilter.monthQtyOrderBy === '' ? 0 : this.fieldsFilter.monthQtyOrderBy
      }
      if (this.filterFields) {
        _.each(this.filterFields, (value, key) => {
          filterFields[key.substring(0, 1).toUpperCase() + key.substring(1)] = value
        })
      }
      let response = await this.$post(this.$options.config.searchURL, _.extend({
        FieldWhereString: JSON.stringify(this.searchFields),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }, filterFields))
      this.pageIndex = pageIndex
      this.itemSource = response.ResultList
      this.totalCount = response.TotalCount
      this.checkedRows = []
      this.checkedRowsCount = 0
      this.$emit('page-loading', false)
      this.gridShow = true
    },
    async onSearch (params = {}) {
      this.searchFields = {}
      _.extend(this.searchFields, params)
      await this.onBindData(1)
      this.checkedRows = []
      this.checkedRowsCount = 0
    },
    async onSaveSetting (columns, pageSize) {
      localStorage.removeItem('stsCustomer')
      await this.$post(this.$options.config.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.defaultConfig))
    },
    async onResetSetting () {
      localStorage.removeItem('stsCustomer')
      await this.$post(this.$options.config.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('stsCustomer')
      return this.$post(this.$options.config.saveConfigURL, this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig))
    },
    async onRefresh () {
      await this.onBindData(this.pageIndex)
    }

  }
}
</script>

<style lang="scss">

</style>

