<template>
  <!-- 入库统计 -->
  <div class="module-wrapper m-sts-purchase-in">
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :is-fields-search="isShowFiedsSearch"
      :search-fields="onSearch"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      box-class="m-sts-purchase-in"
      :total-count="totalCount"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('StsPurchaseIn')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar search-box">
        <div>
          <znl-input
            form-type="datepicker"
            :disabled="false"
            value-format="yyyy-MM-dd"
            placeholder="入库日期从"
            size="mini"
            layout="left"
            width="100px"
            :clearable="true"
            inp-perc="100%"
            :border="true"
            v-model="filterFields.startReceiveTime"
          ></znl-input>
          <znl-input
            form-type="datepicker"
            :disabled="false"
            value-format="yyyy-MM-dd"
            placeholder="入库日期止"
            size="mini"
            layout="left"
            width="100px"
            :clearable="true"
            :border="true"
            inp-perc="100%"
            v-model="filterFields.endReceiveTime"
          ></znl-input>

          <znl-input
            from-type="input"
            :disable="false"
            placeholder="请输入型号"
            size="mini"
            layout="left"
            width="120px"
            inp-perc="100%"
            :border="true"
            class="box_input_checkbox"
            :clearable="true"
            v-model="filterFields.Model"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>

          <znl-input
            from-type="input"
            :disable="false"
            placeholder="请输入供应商名称"
            size="mini"
            layout="left"
            width="150px"
            inp-perc="100%"
            :border="true"
            class="box_input_checkbox"
            :clearable="true"
            v-model="filterFields.SupplierName"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>
          <el-checkbox
            class="accurate-checbox"
            @change="onChange"
            size="small"
            v-model="accurateType"
          >精确</el-checkbox>

          <znl-input
            from-type="input"
            :disable="false"
            placeholder="库位"
            size="mini"
            layout="left"
            width="70px"
            inp-perc="100%"
            :border="true"
            class="box_input_checkbox ml5"
            :clearable="true"
            v-model="filterFields.StorageName"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>

          <!-- 搜索关键值 -->
          <znl-input
            from-type="input"
            :disable="false"
            placeholder="搜索关键值"
            size="mini"
            layout="left"
            width="100px"
            inp-perc="100%"
            class="box_input_checkbox"
            :border="true"
            :clearable="true"
            v-model="filterEx.Keywords"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>
          <znl-input
            title-width="70px"
            layout="left"
            ref="CurrencyCode"
            form-type="select"
            width="80px"
            placeholder="- 关键字 -"
            popper-class="popperClass"
            :border="true"
            :clearable="true"
            :select-options="filterEx.KeywordsFieldOptions"
            v-model="filterEx.KeywordsField"
          ></znl-input>
        </div>

        <div>
          <znl-input
            form-type="datepicker"
            :disabled="false"
            value-format="yyyy-MM-dd"
            placeholder="付款日期从"
            size="mini"
            layout="left"
            width="100px"
            :clearable="true"
            inp-perc="100%"
            :border="true"
            v-model="filterEx.PaidTimeStart"
          ></znl-input>
          <znl-input
            form-type="datepicker"
            :disabled="false"
            value-format="yyyy-MM-dd"
            placeholder="付款日期止"
            size="mini"
            layout="left"
            width="100px"
            :clearable="true"
            :border="true"
            inp-perc="100%"
            v-model="filterEx.PaidTimeEnd"
          ></znl-input>

          <znl-input
            title-width="70px"
            layout="left"
            ref="BuyerName"
            form-type="select"
            width="120px"
            placeholder="采购员"
            popper-class="popperClass"
            :border="true"
            :clearable="true"
            :select-options="userList"
            v-model="filterFields.Receiver"
          ></znl-input>

          <znl-input
            from-type="input"
            :disable="false"
            placeholder="单据备注"
            size="mini"
            layout="left"
            width="150px"
            inp-perc="100%"
            :border="true"
            class="box_input_checkbox"
            :clearable="true"
            v-model="filterFields.STK_In_Remark"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>

          <!-- 状态 -->
          <znl-input
            layout="left"
            form-type="select"
            :disabled="false"
            :border="true"
            width="115px"
            :height="22"
            placeholder="选择状态"
            popper-class="popperClass"
            :clearable="false"
            :multiple="true"
            :tags-clear="false"
            :select-options="selectOptions"
            @change="onChangeStatus"
            v-model="status"
          ></znl-input>
          <znl-button type="multiple" :height="22" style-type="mac" class="box-left-btn search-btn">
            <znl-button-menu @click="onSearch()">
              <i class="iconfont icon-search_ic"></i>
              <span>搜索</span>
            </znl-button-menu>
            <znl-button-menu :width="20" tip="高级搜索" @click="onShowFiedsSearch()">
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button>

          <znl-button
            :height="22"
            style-type="mac"
            @click="onQuotedSta()"
            v-if="this.hasRes('Create')"
          >
            <i class="iconfont"></i>
            <span>生成对账单</span>
          </znl-button>

          <!-- 生成对账单 -->
          <created-statement
            ref="create"
            :statementvisible="envisible"
            @close="()=>{envisible=false}"
            @save-success="onRefresh"
          ></created-statement>

          <znl-button
            style-type="mac"
            :btns="printznlbtns"
            :height="22"
            tip="按搜索条件"
            @click="onExport"
          >
            <i class="iconfont icon-leading-out-wh_btn_i"></i>
            <span>导出</span>
          </znl-button>
        </div>
      </div>
      <div slot="footer" class="v-table-footer-total stsPurchaseIn-footer">
        <div v-on:mouseenter="dataDetails(1)" v-on:mouseleave="dataDetails(0)">
          <div v-show="this.detailedvshow" class="detail-div">
            <el-row class="footer-row-box" v-for="(item, key) in inStatisticSearchsList" :key="key">
              <el-col :span="24">
                <span>
                  <span class="stsPurchaseIn-currencyCode">[{{item.CurrencyCode}}]</span>&nbsp;&nbsp; 数量:
                  <span
                    class="stsPurchaseIn-amount"
                  >{{ moneyFormat(item.TotalQty) }}</span>&nbsp;&nbsp; 应付:
                  <span
                    class="stsPurchaseIn-amount"
                  >{{ moneyFormat(item.TotalPayablesAmount) }}</span>&nbsp;&nbsp; 已付:
                  <span
                    class="stsPurchaseIn-amount"
                  >{{ moneyFormat(item.TotalPaidAmount) }}</span>&nbsp;&nbsp; 未付:
                  <span
                    class="stsPurchaseIn-amount"
                  >{{ moneyFormat(item.TotalUnPaidAmount) }}</span>
                </span>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-row class="footer-row-box">
              <el-col :span="24">
                <span>
                  <span
                    class="stsPurchaseIn-currencyCode"
                  >[{{inStatisticSearchsTotal.CurrencyCode}}]</span>&nbsp;&nbsp; 数量:
                  <span
                    class="stsPurchaseIn-amount"
                  >{{ moneyFormat(inStatisticSearchsTotal.TotalQty) }}</span>&nbsp;&nbsp; 应付:
                  <span
                    class="stsPurchaseIn-amount"
                  >{{ moneyFormat(inStatisticSearchsTotal.TotalPayablesAmount) }}</span>&nbsp;&nbsp; 已付:
                  <span
                    class="stsPurchaseIn-amount"
                  >{{ moneyFormat(inStatisticSearchsTotal.TotalPaidAmount) }}</span>&nbsp;&nbsp; 未付:
                  <span
                    class="stsPurchaseIn-amount"
                  >{{ moneyFormat(inStatisticSearchsTotal.TotalUnPaidAmount) }}</span>
                </span>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </znl-table>
    <export-column
      :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="defaultConfig"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="()=>{setExportColumnVisible=false}"
      @confirm="(v)=>{setExportColumnVisible=false}"
    ></export-column>
  </div>
</template>

<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import ExportColumn from "@c_modules/Crm/setExportColumn";
import CreatedStatement from "@c_modules/Sts/CreatedStatement";
import {
  exportData,
  erpTableSetHandler,
  numberFormat
} from "@scripts/methods/common";

const CONFIG = {
  configURL: "StsPurchaseIn/GetConfig",
  saveConfigURL: "StsPurchaseIn/SaveConfig",
  searchURL: "StsPurchaseIn/Search",
  DropDownURL: "DictItem/GetItemValue",
  InStatisticSearchUrl: "StsPurchaseIn/StatisticSearch"
};

export default {
  name: "StsPurchaseIn",
  mixins: [
    znlMethodsMixin,
    getCommonDataMixin,
    exportData,
    erpTableSetHandler,
    numberFormat
  ],
  config: CONFIG,
  components: {
    ExportColumn,
    CreatedStatement
  },
  data() {
    return {
      accurateType: false, // 是否精确
      isLikeSearch: true,
      envisible: false, // 生成对账单
      isShowFiedsSearch: false,
      columns: [],
      itemSource: [],
      searchFields: {},
      columnColors: [],
      selectOptions: [
        { key: "已入库", value: "已入库" },
        { key: "未入库", value: "未入库" },
        { key: "已撤单", value: "已撤单" },
        { key: "已付款", value: "已付款" },
        { key: "部分付款", value: "部分付款" },
        { key: "未付款", value: "未付款" },
        { key: "已开票", value: "已开票" },
        { key: "部分开票", value: "部分开票" },
        { key: "未开票", value: "未开票" },
        { key: "含税", value: "含税" },
        { key: "未税", value: "未税" }
      ],
      status: [],
      filterFields: {
        Model: "",
        StorageName: "",
        startReceiveTime: "",
        endReceiveTime: "",
        SupplierName: "",
        MatchOperation: "",
        Receiver: null,
        STK_In_Remark: ""
      },
      filterEx: {
        Keywords: "", // 关键字
        KeywordsField: "", // 关键字对应的列
        KeywordsFieldOptions: [
          { key: "BillNo", value: "单号" },
          { key: "Payer", value: "付款人" },
          { key: "PaymentType", value: "付款方式" },
          { key: "TaxRate", value: "税率" },
          { key: "CurrencyCode", value: "币种" },
          { key: "TicketNo", value: "发票号" },
          { key: "PurCompany", value: "采购公司" },
          { key: "Brand", value: "品牌" },
          { key: "Packaging", value: "封装" },
          { key: "MakeYear", value: "年份" },
          { key: "Remark", value: "备注" },
          { key: "Remark2", value: "备注2" },
          { key: "Remark3", value: "备注3" },
          { key: "Contact", value: "联系人" },
          { key: "Telephone", value: "联系电话" },
          { key: "ProductCategory", value: "产品类型" },
        ],
        PaidTimeStart: "",
        PaidTimeEnd: ""
      },
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [], // 权限列表，
      inStatisticSearchsList: [], // 统计集合,
      inStatisticSearchsTotal: {
        CurrencyCode: "汇总",
        TotalPayablesAmount: 0,
        TotalQty: 0,
        TotalPaidAmount: 0,
        TotalUnPaidAmount: 0
      }, // 汇总
      detailedvshow: false, // 其他统计
      printznlbtns: [
        {
          name: "设置导出列",
          click: () => {
            this.showExportColumn();
          }
        }
      ],
      setExportColumnVisible: false,
      addColumnsList: [],
      defaultConfig: {},
      setSaveConfigURL: "",
      loading: false,
      onQuotedRows: {
        CustomerGUID: "",
        CustomerName: "",
        SupplierName: "",
        CurrencyCode: "",
        CurrencyName: "",
        StartOutOn: "",
        EndOutOn: "",
        MatchOperation: "Equal"
      }, // 弹出对账单
      isExport: false,
      userList: [],
      currencyList: [],
      qtyUnitList: [],
      taxRateList: [],
      payType: [],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  async created() {},
  async mounted() {
    this.currencyList = _.map(await this.getCurrencyCodeListAsync, function(
      obj,
      index
    ) {
      return { key: obj.value, value: obj.key };
    });
    this.userList = _.map(this.selectOptionsAllUserURL, function(obj) {
      return { key: obj.value, value: obj.value };
    });
    this.qtyUnitList = await this.selectOptionsQtyUnit;
    this.taxRateList = await this.getTaxRateListAsync;
    this.payType = await this.getRecPayTypeListAsync;

    let dateArr = this.getfromtoDate(new Date());
    this.filterFields.startReceiveTime = dateArr[0];
    this.filterFields.endReceiveTime = dateArr[1];
    await this.onInit();
  },
  methods: {
    onInit: async function() {
      let config = await this.isSaveStorage("stsPurchaseIn", CONFIG.configURL);
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      let columns = config.ColumnConfigs;

      let colIndex = _.findIndex(columns, item => item.BindField === "Status");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 60,
          options: [
            { key: "已入库", value: 3 },
            { key: "未入库", value: 1 },
            { key: "已撤消", value: 9 }
          ]
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "IsStatement");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 60,
          options: [
            { key: "是", value: true },
            { key: "否", value: false }
          ]
        });
      }

      colIndex = _.findIndex(columns, item => item.BindField === "Receiver");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.userList
        });
      }
      colIndex = _.findIndex(
        columns,
        item => item.BindField === "CurrencyCode"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.currencyList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "Unit");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.qtyUnitList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "TaxRate");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.taxRateList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "PaymentType");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.payType
        });
      }

      // 处理特殊权限
      let viewSalesPrice = this.getSpecialResourceByCode("ViewSalesPrice");
      let noSeeProfit = this.getSpecialResourceByCode("NoSeeProfit");

      _.remove(columns, item => {
        if (!viewSalesPrice && item.BindField === "SalesPrice") {
          return true;
        }
        if (noSeeProfit && item.BindField === "GrossProfit") {
          return true;
        }
      });

      this.parameterConfig = config.ParamJosn;
      this.columns = columns;
      this.defaultConfig = config;
      this.$refs.table.init();
      if (this.initData) {
        await this.onBindData(1);
      }
      this.$emit("page-loading", false);
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.filterFields = _.extend({}, this.filterFields, params);
      }
      return this.onBindData(1);
    },
    onRefresh() {
      return this.onBindData(this.pageIndex);
    },
    onShowFiedsSearch() {
      this.isShowFiedsSearch = !this.isShowFiedsSearch;
    },
    onSaveSetting(columns, pageSize) {
      localStorage.removeItem("stsPurchaseIn");
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, CONFIG.default)
      );
    },
    onResetSetting() {
      localStorage.removeItem("stsPurchaseIn");
      return this.$post(CONFIG.resetConfigURL);
    },
    // 右键点击表头设置列
    onSaveConfig(column) {
      localStorage.removeItem("stsPurchaseIn");
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, CONFIG.default)
      );
    },
    async onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      this.loading = true;
      let binddata = this.getSearchWhere(pageIndex);
      await this.$post(CONFIG.searchURL, binddata, data => {
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 800);
      });
      if (pageIndex === 1) {
        this.$post(CONFIG.InStatisticSearchUrl, binddata, data => {
          this.inStatisticSearchsList = data;
          this.inStatisticSearchsTotal.CurrencyCode = "汇总";
          this.inStatisticSearchsTotal.TotalPayablesAmount = 0;
          this.inStatisticSearchsTotal.TotalQty = 0;
          this.inStatisticSearchsTotal.TotalPaidAmount = 0;
          this.inStatisticSearchsTotal.TotalUnPaidAmount = 0;

          if (
            this.inStatisticSearchsList &&
            this.inStatisticSearchsList.length > 0
          ) {
            data.forEach(m => {
              this.inStatisticSearchsTotal.TotalPayablesAmount +=
                m.TotalPayablesAmount;
              this.inStatisticSearchsTotal.TotalQty += m.TotalQty;
              this.inStatisticSearchsTotal.TotalPaidAmount += m.TotalPaidAmount;
              this.inStatisticSearchsTotal.TotalUnPaidAmount +=
                m.TotalUnPaidAmount;
            });
          }
        });
      }
    },
    getSearchWhere(pageIndex) {
      let matchOperation = this.isLikeSearch === true ? "Like" : "Equal";
      this.filterFields.MatchOperation = matchOperation;
      let filterLast = {};

      if (
        this.filterEx.Keywords &&
        this.filterEx.Keywords !== "" &&
        this.filterEx.KeywordsField !== ""
      ) {
        let field = this.filterEx.KeywordsFieldOptions.find(
          m => m.key === this.filterEx.KeywordsField
        );
        if (field) {
          let value = this.filterEx.Keywords;
          if (field.type && field.type === 2) {
            value = this.toFloat(this.filterEx.Keywords);
          }
          filterLast[this.filterEx.KeywordsField] = value;
        }
      }
      filterLast = _.extend({}, this.filterFields, filterLast);

      let binddata = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(filterLast),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          // 外部搜索条件
          StartReceiveTime: this.filterFields.startReceiveTime,
          EndReceiveTime: this.filterFields.endReceiveTime,
          MatchOperation: this.filterFields.MatchOperation,
          SupplierName: this.filterFields.SupplierName,
          Status: this.status,
          StartPaidTime: this.filterEx.PaidTimeStart,
          EndPaidTime: this.filterEx.PaidTimeEnd
        }
      );
      return binddata;
    },
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    dataDetails(index) {
      if (index === 1) {
        this.detailedvshow = true;
      } else {
        this.detailedvshow = false;
      }
    },
    async onExport() {
      this.loading = true;
      this.loading = await this.exportData(
        "StsPurchaseIn",
        this.getSearchWhere(1),
        this.columns,
        "采购入库统计"
      );
    },
    showExportColumn() {
      this.setExportColumnVisible = true;
      this.addColumnsList = this.columns;
      this.setSaveConfigURL = this.$options.config.saveConfigURL;
    },
    onChange(val) {
      if (val) {
        this.isLikeSearch = false;
      } else {
        this.isLikeSearch = true;
      }
      // this.$emit('bar-search-type', this.searchKeys, this.isLike)
    },
    onChangeStatus(val) {
      this.checkStatus = val;
    },
    onQuotedSta() {
      if (
        !this.hasValue(this.filterFields.startReceiveTime) ||
        !this.hasValue(this.filterFields.endReceiveTime)
      ) {
        this.$message({
          message: "请选择入库开始日期和结束日期",
          type: "error"
        });
        return;
      }
      this.envisible = true;
      let row = this.$refs.table.getActiveRow();
      this.onQuotedRows = {};
      let systemtime = new Date();
      let dateStart = null;
      let dateEnd = null;
      let dateArr = this.getfromtoDate(systemtime);

      // if (this.hasValue(this.filterFields.startOutOn)) {
      //   this.dateStart = this.filterFields.startOutOn
      // } else {
      //   this.dateStart = dateArr[0]
      // }
      // if (this.hasValue(this.filterFields.endOutOn)) {
      //   this.dateEnd = this.filterFields.endOutOn
      // } else {
      //   this.dateEnd = dateArr[1]
      // }
      if (this.hasValue(row.SupplierName)) {
        this.onQuotedRows.CustomerName = row.SupplierName;
        this.onQuotedRows.CurrencyCode = row.CurrencyCode;
        this.onQuotedRows.CurrencyName = row.CurrencyName;
        // 将日期字符串转为Date
        let strdate = row.ReceiveTime; // 改字段
        let savedate = strdate.replace(/-/g, "/");
        let date = new Date(savedate);
        // global方法获取月头月尾
        let dateArr = this.getfromtoDate(date);
        this.onQuotedRows.StartOutOn = dateArr[0];
        this.onQuotedRows.EndOutOn = dateArr[1];
      } else if (this.hasValue(this.filterFields.SupplierName)) {
        this.onQuotedRows.CustomerName = this.filterFields.SupplierName
        let dateArr = this.getfromtoDate(systemtime);
        this.onQuotedRows.StartOutOn = this.dateStart;
        this.onQuotedRows.EndOutOn = this.dateEnd;
        this.onQuotedRows.CurrencyCode = "RMB";
        this.onQuotedRows.CurrencyName = "人民币";
      } else {
        let dateArr = this.getfromtoDate(systemtime);
        this.onQuotedRows.StartOutOn = dateArr[0];
        this.onQuotedRows.EndOutOn = dateArr[1];
        this.onQuotedRows.CurrencyCode = "RMB";
        this.onQuotedRows.CurrencyName = "人民币";
      }

      this.onQuotedRows.StartOutOn = this.filterFields.startReceiveTime;
      this.onQuotedRows.EndOutOn = this.filterFields.endReceiveTime;
      this.$nextTick(function() {
        this.envisible = true;
        this.$refs.create.onQuotedRows(this.onQuotedRows, 2);
      });
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("StsPurchaseIn", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("StsPurchaseIn", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("StsPurchaseIn", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("StsPurchaseIn");
    }
  }
};
</script>

<style lang="scss">
.stsPurchaseIn-footer {
  position: absolute;
  bottom: 0;
  z-index: 10;
  left: -5px;
  text-align: left;
  padding: 0px;

  .stsPurchaseIn-currencyCode {
    color: rgb(19, 88, 237);
  }
  .stsPurchaseIn-amount {
    color: rgb(255, 0, 0);
  }
  .detail-div {
    background-color: #fff;
    border: 1px solid #ccc;
    border-bottom: 0;
    padding-top: 5px;
    padding-right: 5px;
    line-height: 22px;
    padding-left: 0px !important;
  }
  .footer-row-box {
    padding-left: 8px;
  }
}
</style>

