<template>
  <!-- 未付款统计 -->
  <div class="module-wrapper">
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :search-fields="onSearch"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :total-count="totalCount"
      @totalAmount-click="onShowTotalAmount"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('StsUnPayment')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          form-type="input"
          :disabled="false"
          placeholder="请输入供应商名称"
          size="mini"
          layout="left"
          width="180px"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="filterFields.SupplierName"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>
        <znl-input
          :border="true"
          v-model="filterFields.ReceiverName"
          form-type="select"
          width="100px"
          :clearable="true"
          :select-options="userOptions"
          placeholder="选择收货员"
        ></znl-input>
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="入库日期"
          size="mini"
          layout="left"
          width="100px"
          :clearable="true"
          inp-perc="100%"
          :border="true"
          v-model="filterFields.StartReceiveTime"
        ></znl-input>
        <span>到</span>
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="入库日期"
          size="mini"
          layout="left"
          width="100px"
          :clearable="true"
          inp-perc="100%"
          :border="true"
          v-model="filterFields.EndReceiveTime"
        ></znl-input>
        <znl-button type="multiple" :height="22" style-type="mac" class="box-left-btn search-btn">
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
        </znl-button>
        <znl-button style-type="mac" :btns="exportBtns" :height="22" @click="onExport" tip="按搜索条件">
          <i class="iconfont icon-leading-out-wh_btn_i"></i>
          <span>导出</span>
        </znl-button>
        <el-checkbox
          v-model="filterFields.OnlyCountUnPayment"
          size="small"
          @change="()=>{onSearch()}"
        >仅显示未付</el-checkbox>
        <el-checkbox
          v-model="filterFields.IsCustomerSummary"
          size="small"
          @change="()=>{onCustomerChange()}"
        >汇总供应商</el-checkbox>
      </div>
      <div slot="footer" class="v-table-footer-total">
        <el-row>
          <el-col :span="24">
            <span>
              未付总金额:
              <span
                class="stsPurchaseIn-amount"
              >{{ moneyFormat(this.SumSearchList.UnPaymentAmountBySupplier) }}</span>
            </span>
            <span style="padding-left: 20px;">
              采购总金额:
              <span
                class="stsPurchaseIn-amount"
              >{{ moneyFormat(this.SumSearchList.TotalAmountBySupplier) }}</span>
            </span>
            <span style="padding-left: 20px;">
              含税总金额:
              <span
                class="stsPurchaseIn-amount"
              >{{ moneyFormat(this.SumSearchList.InTaxAmountBySupplier) }}</span>
            </span>
          </el-col>
        </el-row>
      </div>
    </znl-table>
    <StsUnPaymentDetail
      ref="stsUnPaymentDetail"
      v-if="showStsDetail"
      :visible="showStsDetail"
      :onlyCountUnPayment="filterFields.OnlyCountUnPayment"
      :stkSupplierName="stsSupplierName"
      :stkReceiverName="stkReceiverName"
      :startTime="filterFields.StartReceiveTime"
      :endTime="filterFields.EndReceiveTime"
      @close="()=>{showStsDetail=false}"
    ></StsUnPaymentDetail>
    <export-column
      :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="defaultConfig"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="()=>{setExportColumnVisible=false}"
      @confirm="(v)=>{setExportColumnVisible=false}"
    ></export-column>
  </div>
</template>
<script>

import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import StsUnPaymentDetail from '@c_modules/Sts/StsUnPaymentDetail'
import { exportData, erpTableSetHandler, numberFormat } from '@scripts/methods/common'
import ExportColumn from "@c_modules/Crm/setExportColumn";

const CONFIG = {
  configURL: 'StsUnPayment/GetConfig',
  searchURL: 'StsUnPayment/Search',
  searchSumListURL: 'StsUnPayment/SumList',
  saveConfigURL: 'StsUnPayment/SaveConfig',
}

export default {
  name: 'StsUnPayment',
  mixins: [getCommonDataMixin, erpTableSetHandler, numberFormat, exportData],
  config: CONFIG,
  components: {
    StsUnPaymentDetail,
    ExportColumn
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      searchFields: {},
      columnColors: [],
      filterFields: {
        SupplierName: '',
        ReceiverName: '',
        StartReceiveTime: '',
        EndReceiveTime: '',
        OnlyCountUnPayment: true,
        IsCustomerSummary:false
      },
      exportBtns: [
        {
          name: "设置导出列",
          click: () => {
            this.showExportColumn();
          }
        }
      ],
      setExportColumnVisible: false,
      addColumnsList: [],
      setSaveConfigURL: "",
      SumSearchList: [], // 统计集合,
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      showStsDetail: false, // 是否显示日志
      stsSupplierName: '', // 传递供应商名称
      stkReceiverName: '', // 收货员 采购员
      resourceList: [], // 权限列表，
      loading: false,
      userOptions: [],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  async created() {
  },
  async mounted() {
    await this.onInit()
  },
  methods: {
    onInit: async function () {
      let config = await this.isSaveStorage('StsUnPayment', CONFIG.configURL)
      if (this.hasValue(config.ResourceList) && config.ResourceList.length > 0) {
        this.resourceList = config.ResourceList.map(item => item.Code)
      }
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })
      this.userOptions = UserAllowUsers
      let columns = config.ColumnConfigs
      // 表格值的点击 start
      _.extend(columns[_.findIndex(columns, item => item.BindField === 'TotalAmountBySupplier')], {
        event: 'totalAmount-click',
        className: 'v-cell-link'
      })

      this.defaultConfig = config
      this.columns = columns
      this.pageSize = config.PageSize
      this.$refs.table.init()
      if (this.initData) {
        await this.onBindData(1)
      }
      this.$emit('page-loading', false)
    },
    onShowTotalAmount(e) {
      if (e.field !== 'TotalAmountBySupplier') {
        return
      }
      if (this.hasValue(e.row.SupplierName)) {
        this.stsSupplierName = e.row.SupplierName
        this.stkReceiverName = e.row.Receiver
        this.$nextTick(() => {
          this.showStsDetail = true
        })
      }
    },
    async onBindData(pageIndex = 1, type) {
      if (!type) {
        this.loading = true
      }
      //this.$refs.table.cleanSort()
      let filterFields = {}
      if (this.filterFields) {
        _.each(this.filterFields, (value, key) => {
          filterFields[key.substring(0, 1).toUpperCase() + key.substring(1)] = value
        })
      }

      this.SumSearchList = await this.$post(CONFIG.searchSumListURL, _.extend({
        FieldWhereString: JSON.stringify(this.searchFields),
        PageIndex: pageIndex,
        PageSize: this.pageSize,
        OrderBy: this.OrderBySearchParams
      }, filterFields))
      let response = await this.$post(CONFIG.searchURL, _.extend({
        FieldWhereString: JSON.stringify(this.searchFields),
        PageIndex: pageIndex,
        PageSize: this.pageSize,
        OrderBy: this.OrderBySearchParams
      }, filterFields))

      this.pageIndex = pageIndex
      this.itemSource = response.ResultList
      this.totalCount = response.TotalCount
      this.checkedRowsCount = 0
      setTimeout(() => {
        this.loading = false
      }, 800);
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.filterFields = _.extend({}, this.filterFields, params)
      }
      return this.onBindData(1)
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("StsUnPayment", e.col)
    },
    onDragDone(col) {
      this.setTableColWidth("StsUnPayment", col)
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols
      this.saveErpTableConfig("StsUnPayment", cols, isDelay)
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("StsUnPayment")
    },
    onCustomerChange(){
      var colIndex = _.findIndex(this.columns, item => item.BindField === 'Receiver')
      if (colIndex > -1 ) {
        _.extend(this.columns[colIndex], {
          IsShow:!this.filterFields.IsCustomerSummary
        })
      }
      this.onSearch();
    },
    async onExport() {
      this.loading = true;
      this.loading = await this.exportData(
        "StsUnPayment",
        this.getSearchWhere(1),
        this.columns,
        "未付款统计"
      );
    },
    showExportColumn() {
      this.setExportColumnVisible = true;
      this.addColumnsList = this.columns;
      this.setSaveConfigURL = this.$options.config.saveConfigURL;
    },
    getSearchWhere(pageIndex) {
      let bindData = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.filterFields),
          PageIndex: pageIndex,
          PageSize: this.pageSize,
          SupplierName: this.filterFields.SupplierName,
          StartReceiveTime: this.filterFields.StartReceiveTime,
          EndReceiveTime: this.filterFields.EndReceiveTime,
          OnlyCountUnPayment: this.filterFields.OnlyCountUnPayment,
          IsCustomerSummary: this.filterFields.IsCustomerSummary
        }
      );
      return bindData;
    }
  }
}
</script>
<style lang="scss">
.stsPurchaseIn-amount {
  color: red;
}
</style>

