<template>
      <div>
        <znl-chart :xyoption="line"
            titles="客户统计走势图"
            :on-ready="onReady" :loading="loading"></znl-chart>
      </div>
</template>

<script>
// import znlChart from '@c_common/znlGrid/znlChart'
export default {
  name: 'SaleQtyByMonthLine',
  components: {
    // znlChart
  },
  data () {
    return {
      config: {
        getMonthSaleQty: 'SaleRpt/GetMonthSaleQty'
      },
      loading: false,
      line: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['下单量', '下单总金额', '退货量', '退货总金额']
        },
        grid: {
          left: '40px',
          right: '20px',
          top: '30px',
          bottom: '30px'
        },
        xAxis: {
          axisLine: {
            show: true
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#c5c5c5'
            }
          },
          data: []
        },
        yAxis: {
          axisLine: {
            show: true
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#c5c5c5'
            }
          }
        },
        series: [
          {
            name: '下单量',
            type: 'line',
            data: []
          },
          {
            name: '下单总金额',
            type: 'line',
            data: []
          },
          {
            name: '退货量',
            type: 'line',
            data: []
          },
          {
            name: '退货总金额',
            type: 'line',
            data: []
          }
        ]
      },
      users: []
    }
  },
  watch: {
    searchModel () {
      this.loading = true
      this.getChartData()
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    height: String
  },
  methods: {
    onReady () {
      this.initXAxis()
    },
    // 初始化横轴
    initXAxis () {
      let currentDate = new Date()
      let currentMonth = currentDate.getMonth()
      let months = []
      for (let i = 0; i < 6; i++) {
        let m = currentMonth - i
        if (m <= 0) {
          m = 12 + m
        }
        months.unshift(m)
      }
      this.line.xAxis.data = _.map(months, item => { return item + '月' })
    },
    // 获取图表数据
    getChartData () {
      if (!_.isEmpty(this.searchModel.DiagramJson)) {
        let data = JSON.parse(this.searchModel.DiagramJson)
        // console.log('图表', data)

        this.line.series[0].data = _.map(data, item => { return item.OrderCount })
        this.line.series[1].data = _.map(data, item => { return item.OrderAmount })
        this.line.series[2].data = _.map(data, item => { return item.ReturnCount })
        this.line.series[3].data = _.map(data, item => { return item.ReturnAmount })
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
