<template>
<!-- <div>
  <znl-un-payment v-if='hasRight("StsUnPayment")' :searchModel="stsUnPayment" height="97%">
  </znl-un-payment>
</div> -->
 <znl-layout-spa :page-loading="$store.state.pageLoading && pageLoading" >
          <znl-un-payment
          slot="znlLayoutSpa"
          class="znlLayoutSpa"
          v-if='hasRight("StsUnPayment")'
          :searchModel="stsUnPayment"
          @page-loading="(d)=>{pageLoading=d}"
          ></znl-un-payment>
       </znl-layout-spa>
</template>

<script>
import ZnlUnPayment from '@c_modules/Sts/StsUnPayment'

export default {
  name: 'VUnPayment',
  components: {
    ZnlUnPayment
  },
  data () {
    return {
      stsUnPayment: {},
      pageData: [],
      pageLoading: true
    }
  },
  methods: {
    async getPageData () {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      return await this.$post(pageUrl, {PageCode: 'VUnPayment'})
    },
    hasRight (code) {
      return _.some(this.pageData, page => page.Code === code)
    }
  },
  async created () {
    this.getPageData().then((data) => {
      this.pageData = data
      this.$nextTick(() => {
        initData(this)
      })
    }).catch((e) => {
      console.log(e)
    })

    const initData = (component) => {
      if (component.$options.config && component.$options.config.configURL) {
        const moduleName = component.$options.config.configURL.split('/')[0]
        const config = _.find(this.pageData, page => page.Code === moduleName)
        if (config && component.onInit) {
          component.onInit(config.UICPageGridConfig)
        } else {
          component.onInit()
        }
      }
      _.each(component.$children, child => {
        initData(child)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'app/assets/styles/_variables.scss';
</style>
