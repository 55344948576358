<template>
    <znl-chart
  titles="库存预警统计"
  height="100%"
  :grid-show="gridShow"
  :loading="loading"
  :on-init="onInit"
  @page-loading="d=>{$emit('page-loading', d)}"
  :xyoption="StatisticsChartOption">
</znl-chart>
</template>
<script>
const CONFIG = {
  searchURL: 'StsStock/StockWarningView'
}

export default {
  name: 'StatisticSearchView',
  config: CONFIG,
  components: {

  },
  data () {
    return {
      searchFields: {},
      logtype: '',
      startTime: new Date(((new Date()).valueOf() + (-6 * 24 * 60 * 60 * 1000))),
      endTime: new Date(),
      companyGuid: '',
      loading: false,
      gridShow: false,
      echartWidth: 0,
      echartHeigth: 500,
      StatisticsChartOption: {
        width: '80%',
        height: '80%',
        title: {
          subtext: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: [{name: '预警数'}]
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            dataView: {readOnly: true},
            magicType: {type: ['line', 'bar']},
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: [{
          type: 'value',
          name: '预警数',
          position: 'left',
          axisLabel: {
            formatter: '{value} 个'
          }
        }],
        series: [
          {
            name: '预警数',
            type: 'line',
            data: [],
            symbol: 'emptyCircle',
            symbolSize: 4,
            showSymbol: true,
            smooth: true,
            label: {
              normal: {
                show: true,
                position: 'top',
                formatter: '{c}'
              }
            }
          }
        ]
      },
      chartHeightCom: '',
      echartsHeight: ''
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {}
      }
    },
    chartHeight: {
      type: [Number, String]
    },
    height: {
      type: String
    }
  },
  watch: {
    chartHeight () {
      this.chartHeightCom = this.chartHeight
    }
  },
  methods: {
    onInit: async function () {
      return this.initData && this.onBindData()
    },
    onReady (instance) {
    },
    onClick (event, instance, echarts) {
    },
    getHeight (height) {
      this.echartsHeight = height - 80 + 'px'
    },

    async onBindData () {
      let filterFields = {}
      if (this.filterFields) {
        _.each(this.filterFields, (value, key) => {
          filterFields[key.substring(0, 1).toUpperCase() + key.substring(1)] = value
        })
      }

      let response = await this.$post(this.$options.config.searchURL, _.extend(filterFields))
      this.loading = false
      this.gridShow = true
      this.$emit('page-loading', false)
      this.SetChartOption(response)
      return
    },
    SetChartOption (data) {
      let xDatas = []
      if (data && data.length > 0) {
        _.each(data, item => {
          xDatas.push(item.StatDateStr)
        })
      }
      this.StatisticsChartOption.xAxis.data = data.xAxis.data
      let TotalCount = []
      _.each(data.yData, (item, index) => {
        TotalCount.push(item.TotalCount)
      })
      this.StatisticsChartOption.series[0].data = TotalCount
    }
  },
  mounted () {
    this.chartHeightCom = this.chartHeight
    if (this.$store.state.loadingInstance) {
      this.$store.state.loadingInstance.close()
      this.$store.state.loadingFlag = false
      this.$store.state.isloadingFlag[this.$store.state.activeTab] = true
    }
    window.onresize = () => {
      this.echartWidth = document.body.clientWidth
      this.echartHeigth = document.body.clientHeight
    }
  },
  created () {
    this.echartHeigth = document.body.clientHeight
  }
}
</script>

<style lang="scss" scoped>

</style>

