<template>
      <znl-dialog
      :visible="visible"
      height="400px"
      width="500px"
      @close="()=>{$emit('close')}"
      :is-footer-show="false"
      >
         <znl-chart-mini
          titles="客户分析走势图"
           slot="znl-dialog"
          :xyoption="line"
          :loading="false"
          :grid-show="true"
          width="100%"
          height="380px"
          :on-init="onInit"
          backgroundColor="transparent"
          class="index-charts"
          :on-refresh="pageSearch">
         </znl-chart-mini>
      </znl-dialog>
</template>

<script>

export default {
  name: 'customer-stk-out-chart',
  components: {
    // znlChart
  },
  data () {
    return {
      config: {
        getCompanyUsersURL: 'CompanyAccount/GetUserAllowCompanyUser'
      },
      userid: '',
      users: [],
      loading: true,
      line: {
        title: {
          text: ''
        },
        legend: {
          data: [
            {name: '发货金额'},
            {name: '发货单数'},
            {name: '退货金额'},
            {name: '退货单数'}
          ]
        },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '80px',
          right: '20px',
          top: '30px',
          bottom: '30px'
        },
        xAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#c5c5c5'
            }
          },
          data: []
        },
        yAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#c5c5c5'
            }
          }
        },
        series: [{
          name: '发货金额',
          type: 'line',
          data: [],
          width: '500px',
          symbolSize: 8,
          itemStyle: {
            normal: {
              color: '#1BB934',
              lineStyle: { color: '#1BB934' },
              areaStyle: {
                type: 'default',
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgba(0,0,0,0)' // 0% 处的颜色
                  }, {
                    offset: 1, color: 'rgba(0,0,0,0)' // 100% 处的颜色
                  }]
                }
              }
            }
          }
        }, {
          name: '发货单数',
          type: 'line',
          data: [],
          width: '500px',
          symbolSize: 8,
          itemStyle: {
            normal: {
              color: '#008080',
              lineStyle: { color: '#008080' },
              areaStyle: {
                type: 'default',
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgba(0,0,0,0)' // 0% 处的颜色
                  }, {
                    offset: 1, color: 'rgba(0,0,0,0)' // 100% 处的颜色
                  }]
                }
              }
            }
          }
        }, {
          name: '退货金额',
          type: 'line',
          data: [],
          width: '500px',
          symbolSize: 8,
          itemStyle: {
            normal: {
              color: '#778899',
              lineStyle: { color: '#778899' },
              areaStyle: {
                type: 'default',
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgba(0,0,0,0)' // 0% 处的颜色
                  }, {
                    offset: 1, color: 'rgba(0,0,0,0)' // 100% 处的颜色
                  }]
                }
              }
            }
          }
        }, {
          name: '退货单数',
          type: 'line',
          data: [],
          width: '500px',
          symbolSize: 8,
          itemStyle: {
            normal: {
              color: '#808000',
              lineStyle: { color: '#808000' },
              areaStyle: {
                type: 'default',
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgba(0,0,0,0)' // 0% 处的颜色
                  }, {
                    offset: 1, color: 'rgba(0,0,0,0)' // 100% 处的颜色
                  }]
                }
              }
            }
          }
        }]
      }
    }
  },
  watch: {
    searchModel () {
      // this.loading = true
      // this.getChartData()
    },
    refresh () {
      this.getChartData()
    }
  },
  props: {
    searchModel:
    {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    refresh: {type: Boolean, default: true},
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async onInit () {
      // this.onInitUserList()
      this.getChartData()
    },
    // 初始化员工列表
    onInitUserList () {
      return this.$post(this.config.getCompanyUsersURL, {}, (data) => {
        let allUsers = _.map(data.Users, user => {
          return {
            UserID: user.UserID,
            UserName: user.UserName
          }
        })
        if (allUsers.length > 1) {
          allUsers.unshift({ UserID: 0, UserName: '全部' })
          this.userid = 0
        } else {
          this.userid = allUsers[0].UserID
        }
        // this.loading = false
        // this.users = allUsers
        this.users = _.map(allUsers, item => {
          return {
            key: item.UserID,
            value: item.UserName
          }
        })
        // this.pageSearch()
      })
    },
    // 获取图表数据
    getChartData (userid) {
      this.loading = true
      let data = []
      if (!_.isEmpty(this.searchModel.DiagramJson)) {
        data = JSON.parse(this.searchModel.DiagramJson)
      } else {
        data = []
        let currentDate = new Date()
        let currentMonth = currentDate.getMonth()
        let months = []
        for (let i = 0; i < 6; i++) {
          let m = currentMonth - i
          if (m <= 0) {
            m = 12 + m
          }
          months.unshift(m)
        }
        for (let i = 0; i < 6; i++) {
          data.push({ 'Month': months[i], 'OrderCount': 0, 'OrderAmount': 0, 'ReturnCount': 0, 'ReturnAmount': 0 })
        }
      }

      this.line.xAxis.data = _.map(data, item => { return item.Month + '月' })

      this.line.series[0].data = _.map(data, item => { return item.OrderAmount })
      this.line.series[1].data = _.map(data, item => { return item.OrderCount })
      this.line.series[2].data = _.map(data, item => { return item.ReturnAmount })
      this.line.series[3].data = _.map(data, item => { return item.ReturnCount })

      _.delay(() => {
        this.loading = false
      }, 200)
    },
    // 搜索
    pageSearch (val) {
      this.getChartData(_.isUndefined(val) ? null : val.key)
    }
  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
.searchCol {
  width: 150px;
//  padding: 0 1px;
}
</style>
