<template>
  <znl-gridmodule ref="flexGrid"
              header="客户跟进记录"
              :columns="columns"
              height="100%"
              :grid-show="gridShow"
              :has-znl-actions="true"
              :item-source="itemSource"
              :column-colors="columnColors"
              :row-colors="rowColors"
              :summary-columns="summaryColumns"
              :is-fields-search="isShowFiedsSearch"
              :search-fields="searchModel"
              :page-size="pageSize"
              :page-index="pageIndex"
              :total-count="totalCount"
              :role="role"
              :users="$store.state.users"
              :on-init="onInit"
              :on-refresh="onRefresh"
              :on-search="onSearch"
              :is-action-from="false"
              :is-btn="false"
              :on-page-changed="onBindData"
              :on-save-setting="onSaveSetting"
              :on-save-config='onSaveConfig'
              :on-reset-setting="onResetSetting"
              @page-changed="onBindData">
     <div slot="action-form">
        <znl-input
            form-type="input"
            :disabled="false"
            placeholder="客户名称 "
            size="mini"
            layout="left"
            width='120px'
            inp-perc="100%"
             v-model="searchFields.inputModel"
            @keyup.enter.native="$refs.flexGrid.search()"
            type="text">
        </znl-input>
    <znl-button type="multiple" :height='22' style-type='mac' >
              <znl-button-menu
              @click="$refs.flexGrid.search()">
              <span><i class="iconfont icon-search_ic"></i>搜索</span>
              </znl-button-menu>
              <znl-button-menu :width='7' tip="高级搜索" @click="onShowFiedsSearch()"><i class="iconfont icon-arrow-drop-down_ic"></i></znl-button-menu>
            </znl-button>
    </div>
  </znl-gridmodule>

</template>

<script>

import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'

const CONFIG = {
  configURL: 'StsCustFollows/GetConfig',
  saveConfigURL: 'StsCustFollows/SaveConfig',
  resetConfigURL: 'StsCustFollows/ResetConfig',
  default: null,
  searchURL: 'StsCustFollows/Search'
}
export default {
  name: 'StsCustFollows',
  mixins: [znlMethodsMixin],
  config: CONFIG,
  components: {
  },
  data () {
    return {
      isShowFiedsSearch: false,
      columns: [],
      itemSource: [],
      searchFields: { inputModel: '' },
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      gridShow: false
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String
  },
  methods: {
    onSearch (params) {
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    onShowFiedsSearch () {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true
      } else {
        this.isShowFiedsSearch = false
      }
    },
    onBindData (pageIndex = 1) {
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: pageIndex,
          PageSize: this.pageSize,
          CCompanyName: this.searchFields.inputModel
        },
        {
          // 外部搜索条件
          CCompanyName: this.searchFields.inputModel
        }
      )
      return this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
        this.$emit('page-loading', false)
        this.gridShow = true
      })
    }
  }
}
</script>

<style lang="scss">
</style>

