<template>
  <znl-table
    ref="table"
    gridtype="base"
    :columns="columns"
    :item-source="itemSource"
    :on-page-changed="onBindData"
    :page-size="pageSize"
    :page-index="pageIndex"
    :search-fields="onSearch"
    v-loading="loading"
    element-loading-text="数据加载中,请稍后..."
    :total-count="totalCount"
  >
    <div slot="header" class="v-table-toolbar">
      <znl-input
        form-type="input"
        :disabled="false"
        placeholder="请输入型号"
        size="mini"
        layout="left"
        width="180px"
        inp-perc="100%"
        :clearable="true"
        :border="true"
        v-model="filterFields.model"
        @keyup.enter.native="onSearch()"
        type="text"
      ></znl-input>
      <znl-input
        form-type="datepicker"
        :disabled="false"
        placeholder="开始日期"
        size="mini"
        layout="left"
        width="100px"
        :clearable="true"
        inp-perc="100%"
        :border="true"
        v-model="filterFields.quotedTimeByStart"
      ></znl-input>
      <span>到</span>
      <znl-input
        form-type="datepicker"
        :disabled="false"
        placeholder="结束日期"
        size="mini"
        layout="left"
        width="100px"
        :clearable="true"
        :border="true"
        inp-perc="100%"
        v-model="filterFields.quotedTimeByEnd"
      ></znl-input>
      <znl-button type="multiple" :height="22" style-type="mac" class="box-left-btn search-btn">
        <znl-button-menu @click="onSearch()">
          <i class="iconfont icon-search_ic"></i>
          <span>搜索</span>
        </znl-button-menu>
      </znl-button>
    </div>
  </znl-table>
</template>

<script>

const CONFIG = {
  configURL: 'StsHotQuoteModels/GetConfig',
  saveConfigURL: 'StsHotQuoteModels/SaveConfig',
  resetConfigURL: 'StsHotQuoteModels/ResetConfig',
  searchURL: 'StsHotQuoteModels/GetHotQStatistics'
}
export default {
  name: 'StsHotQuoteModels',
  mixins: [],
  config: CONFIG,
  components: {
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      filterFields: {
        quotedTimeByStart: '',
        quotedTimeByEnd: '',
        model: ''
      },
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      loading: false
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
  },
  async created() {
  },
  async mounted() {
    await this.onInit()
  },
  methods: {
    onInit: async function () {
      let config = await this.isSaveStorage('StsHotQuoteModels', CONFIG.configURL)
      let columns = config.ColumnConfigs
      this.columns = columns
      this.$refs.table.init()
      if (this.initData) {
        await this.onBindData(1)
      }
      this.$emit('page-loading', false)
    },
    onShowFiedsSearch() {
      this.isShowFiedsSearch = !this.isShowFiedsSearch
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.filterFields = _.extend({}, this.filterFields, params)
      }
      return this.onBindData(1)
    },
    async onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      let data = _.extend({}, {
        PageIndex: pageIndex,
        PageSize: this.pageSize,
        QuotedTimeByStart: this.filterFields.quotedTimeByStart,
        QuotedTimeByEnd: this.filterFields.quotedTimeByEnd,
        Model: this.filterFields.model
      })
      this.loading = true
      return await this.$post(CONFIG.searchURL, data, (data) => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 800);
      })
    }
  }
}
</script>

<style lang="scss">
</style>

