<template>
  <znl-gridmodule
    ref="flexGrid"
    :columns="columns"
    height="100%"
    class="znl-stspurchasein"
    :grid-show="gridShow"
    :has-znl-actions="true"
    :is-btn="false"
    :item-source="itemSource"
    :column-colors="columnColors"
    :row-colors="rowColors"
    :summary-columns="summaryColumns"
    :is-fields-search="isShowFiedsSearch"
    :search-fields="searchModel"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    :show-add-row-right-menu="false"
    :show-delete-row-right-menu="false"
    :role="role"
    :on-init="onInit"
    :is-init="true"
    :loading="loading"
    :on-refresh="onRefresh"
    :on-search="onSearch"
    :on-page-changed="onBindData"
    :on-save-setting="onSaveSetting"
    :on-save-config="onSaveConfig"
    :on-reset-setting="onResetSetting"
    :users="$store.state.users"
    @page-loading="d=>{$emit('page-loading', d)}"
    @page-changed="onBindData"
  >
    <div slot="heading-slot">
      <znl-input
        form-type="datepicker"
        :disabled="false"
        value-format="yyyy-MM-dd"
        placeholder="入库日期"
        size="mini"
        layout="left"
        width="100px"
        :clearable="true"
        inp-perc="100%"
        :border="true"
        v-model="filterFields.startReceiveTime"
      ></znl-input>
      <span>到</span>
      <znl-input
        form-type="datepicker"
        :disabled="false"
        value-format="yyyy-MM-dd"
        placeholder="入库日期"
        size="mini"
        layout="left"
        width="100px"
        :clearable="true"
        :border="true"
        inp-perc="100%"
        v-model="filterFields.endReceiveTime"
      ></znl-input>
      <div>
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="请输入供应商名称"
          size="mini"
          layout="left"
          width="180px"
          inp-perc="100%"
          :border="true"
          class="box_input_checkbox"
          :clearable="true"
          v-model="filterFields.SupplierName"
          @keyup.enter.native="$refs.flexGrid.search()"
          type="text"
        ></znl-input>
        <el-checkbox
          class="accurate-checbox"
          @change="onChange"
          size="small"
          v-model="accurateType"
        >精确</el-checkbox>
      </div>
      <!-- 状态 -->
      <znl-input
        layout="left"
        form-type="select"
        :disabled="false"
        :border="true"
        width="90px"
        :height="22"
        placeholder="选择状态"
        popper-class="popperClass"
        :clearable="false"
        :multiple="true"
        :tags-clear="false"
        :select-options="selectOptions"
        @change="onChangeStatus"
        v-model="status"
      ></znl-input>
      <znl-button type="multiple" :height="22" style-type="mac" class="box-left-btn search-btn">
        <znl-button-menu @click="$refs.flexGrid.search()">
          <i class="iconfont icon-search_ic"></i>
          <span>搜索</span>
        </znl-button-menu>
        <znl-button-menu :width="12" tip="高级搜索" @click="onShowFiedsSearch()">
          <i class="iconfont icon-arrow-drop-down_ic"></i>
        </znl-button-menu>
      </znl-button>

      <znl-button :height="22" style-type="mac" @click="onQuotedSta()" v-if="this.hasRes('Create')">
        <i class="iconfont"></i>
        <span>生成对账单</span>
      </znl-button>

      <!-- 生成对账单 -->
      <created-statement
        ref="create"
        :statementvisible="envisible"
        @close="()=>{envisible=false}"
        @save-success="onRefresh"
      ></created-statement>

      <znl-button style-type="mac" :btns="printznlbtns" :height="22" tip="按搜索条件" @click="onExport">
        <i class="iconfont icon-leading-out-wh_btn_i"></i>
        <span>导出</span>
      </znl-button>
    </div>
    <div slot="znl-footer-slot" class="StsPurchaseIn-footer">
      <div v-on:mouseenter="dataDetails(1)" v-on:mouseleave="dataDetails(0)">
        <div v-show="this.detailedvshow" class="detail-div">
          <el-row
            class="footer-row-box"
            v-for="(item, key) in inStatisticSearchsList"
            :key="key"
            v-if="key>0"
          >
            <el-col :span="12">
              <span>
                <span class="stsPurchaseIn-currencyCode">[{{item.CurrencyCode}}]</span>&nbsp;&nbsp; 数量:
                <span class="stsPurchaseIn-amount">{{item.TotalQty}}</span>&nbsp;&nbsp; 应付:
                <span class="stsPurchaseIn-amount">{{item.TotalPayablesAmount}}</span>&nbsp;&nbsp; 已付:
                <span class="stsPurchaseIn-amount">{{item.TotalPaidAmount}}</span>&nbsp;&nbsp; 未付:
                <span class="stsPurchaseIn-amount">{{item.TotalUnPaidAmount}}</span>
              </span>
            </el-col>
          </el-row>
        </div>
        <div>
          <el-row class="footer-row-box">
            <el-col :span="12">
              <span>
                <span class="stsPurchaseIn-currencyCode">[{{inStatisticSearchsTotal.CurrencyCode}}]</span>&nbsp;&nbsp; 数量:
                <span
                  class="stsPurchaseIn-amount"
                >{{inStatisticSearchsTotal.TotalQty}}</span>&nbsp;&nbsp; 应付:
                <span
                  class="stsPurchaseIn-amount"
                >{{inStatisticSearchsTotal.TotalPayablesAmount}}</span>&nbsp;&nbsp; 已付:
                <span
                  class="stsPurchaseIn-amount"
                >{{inStatisticSearchsTotal.TotalPaidAmount}}</span>&nbsp;&nbsp; 未付:
                <span
                  class="stsPurchaseIn-amount"
                >{{inStatisticSearchsTotal.TotalUnPaidAmount}}</span>
              </span>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <export-column
      :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="setConfigColumns"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="()=>{setExportColumnVisible=false}"
      @confirm="(v)=>{setExportColumnVisible=false}"
    ></export-column>
  </znl-gridmodule>
</template>

<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import ExportColumn from '@c_modules/Crm/setExportColumn'
import { mixin as common } from '~assets/scripts/methods/common'
import * as urls from '~/lib/urls'
import CreatedStatement from '@c_modules/Sts/CreatedStatement'
const CONFIG = {
  configURL: 'StsPurchaseIn/GetConfig',
  saveConfigURL: 'StsPurchaseIn/SaveConfig',
  resetConfigURL: 'StsPurchaseIn/ResetConfig',
  default: null,
  searchURL: 'StsPurchaseIn/Search',
  DropDownURL: 'DictItem/GetItemValue',
  InStatisticSearchUrl: 'StsPurchaseIn/StatisticSearch',
  apiBaseUrl: urls.getErpApiBaseUrl()
}
export default {
  name: 'StsPurchaseIn',
  mixins: [znlMethodsMixin, getCommonDataMixin, common],
  config: CONFIG,
  components: {
    ExportColumn,
    CreatedStatement
  },
  data () {
    return {
      accurateType: false, // 是否精确
      isLikeSearch: true,
      envisible: false, // 生成对账单
      isShowFiedsSearch: false,
      columns: [],
      itemSource: [],
      searchFields: {},
      columnColors: [],
      selectOptions: [{ key: '已入库', value: '已入库' },
      { key: '未入库', value: '未入库' },
      { key: '已撤单', value: '已撤单' },
      { key: '已付款', value: '已付款' },
      { key: '部分付款', value: '部分付款' },
      { key: '未付款', value: '未付款' },
      { key: '已开票', value: '已开票' },
      { key: '部分开票', value: '部分开票' },
      { key: '未开票', value: '未开票' },
      { key: '含税', value: '含税' },
      { key: '未税', value: '未税' }],
      status: [],
      filterFields: {
        startReceiveTime: '',
        endReceiveTime: '',
        SupplierName: '',
        MatchOperation: ''
      },
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      resourceList: [], // 权限列表，
      gridShow: false,
      inStatisticSearchsList: [], // 统计集合,
      inStatisticSearchsTotal: {
        CurrencyCode: '',
        TotalPayablesAmount: '',
        TotalQty: '',
        TotalPaidAmount: '',
        TotalUnPaidAmount: ''
      }, // 汇总
      detailedvshow: false, // 其他统计
      printznlbtns: [
        {
          name: '设置导出列',
          click: () => {
            this.showExportColumn()
          }
        }
      ],
      setExportColumnVisible: false,
      addColumnsList: [],
      setConfigColumns: {},
      setSaveConfigURL: '',
      searchCondition: {},
      loading: false,
      onQuotedRows: {
        CustomerGUID: '',
        CustomerName: '',
        SupplierName: '',
        CurrencyCode: '',
        CurrencyName: '',
        StartOutOn: '',
        EndOutOn: '',
        MatchOperation: 'Equal'
      },  // 弹出对账单
      isExport: false
    }
  },
  watch: {
    selectOptionsCurrencyCode (val) {
      let CurrencyNames = _.map(val, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })
      _.each(this.columns, column => {
        if (column.binding === 'CurrencyName') {
          this.$set(column, 'dataMap', CurrencyNames)
        }
      })
    },
    selectOptionsQtyUnit (val) {
      _.each(this.columns, column => {
        if (column.binding === 'Unit') {
          this.$set(column, 'dataMap', val)
        }
      })
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => { return {} }
    },
    height: String
  },
  methods: {
    onInit: async function () {
      let config = await this.isSaveStorage('stsPurchaseIn', CONFIG.configURL)
      // let config = await this.$post(CONFIG.configURL)
      this.resourceList = config.ResourceList.map(item => item.Code)
      // console.log(this.resourceList)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      // 库存类型
      let stkTypeColumn = _.find(columns, column => column.name === 'InvType')
      stkTypeColumn && _.extend(stkTypeColumn, {
        dataMap: this.selectStorageType,
        showDropDown: true
      })

      if (_.find(columns, function (column) { return column.binding === 'Status' })) {
        _.extend(_.find(columns, function (column) { return column.binding === 'Status' }),
          { dataMap: [{ 'key': 3, 'value': '已入库' },
            { 'key': 1, 'value': '未入库' },
            { 'key': 9, 'value': '已撤消' }],
            showDropDown: true })
      }

      if (_.find(columns, function (column) { return column.binding === 'IsPaid' })) {
        _.extend(_.find(columns, function (column) { return column.binding === 'IsPaid' }),
          { dataMap: [{ 'key': 1, 'value': '未付款' },
            { 'key': 2, 'value': '部分付款' },
            { 'key': 3, 'value': '已付款' }],
            showDropDown: true })
      }
      if (_.find(columns, function (column) { return column.binding === 'IsStatement' })) {
        _.extend(_.find(columns, function (column) { return column.binding === 'IsStatement' }),
          { dataMap: [{ 'key': false, 'value': '否' },
            { 'key': true, 'value': '是' }
          ],
            showDropDown: true })
      }
      // 采购员
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Receiver'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )
      // 付款人
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Payer'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )
      // 币种
      let CurrencyNames = _.map(this.selectOptionsCurrencyCode, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'CurrencyName'
        }),
        { dataMap: CurrencyNames, showDropDown: true }
      )
      // 单位
      let QtyUnitDropDown = await this.selectOptionsQtyUnit
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Unit'
        }),
        { dataMap: QtyUnitDropDown, showDropDown: true }
      )
      // 税点
      let TaxRate = this.selectOptionsTaxRate
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'TaxRate'
        }),
        { dataMap: TaxRate, showDropDown: true }
      )

      // 付款方式
      let PayType = this.selectOptionsPaymentTypeOptions
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'PaymentType'
        }),
        { dataMap: PayType, showDropDown: true }
      )
      this.defaultConfig = config
      // 不看利润和销售价
      _.each(columns, column => {
        if (this.getSpecialResourceByCode('NoSeeProfit')) {
          if (column.binding === 'GrossProfit') {
            column.hasRole = false
          }
          if (column.binding === 'SalesPrice') {
            column.hasRole = false
          }
        }
      })
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      this.initData && this.onBindData()
    },
    onSearch (params) {
      this.filterFields = _.extend({}, this.filterFields, params)
      return this.onBindData(1)
    },
    onRefresh () {
      return this.onBindData(this.pageIndex)
    },
    onShowFiedsSearch () {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true
      } else {
        this.isShowFiedsSearch = false
      }
    },
    onSaveSetting (columns, pageSize) {
      localStorage.removeItem('stsPurchaseIn')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, CONFIG.default))
    },
    onResetSetting () {
      localStorage.removeItem('stsPurchaseIn')
      return this.$post(CONFIG.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('stsPurchaseIn')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler([column], this.pageSize, CONFIG.default))
    },
    onBindData (pageIndex = 1) {
      this.loading = true
      let matchOperation = this.isLikeSearch === true ? 'Like' : 'Equal'
      this.filterFields.MatchOperation = matchOperation
      let binddata = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.filterFields),
          PageIndex: pageIndex,
          PageSize: this.pageSize,
          StartReceiveTime: this.filterFields.startReceiveTime,
          EndReceiveTime: this.filterFields.endReceiveTime
        },
        {
          // 外部搜索条件
          MatchOperation: this.filterFields.MatchOperation,
          SupplierName: this.filterFields.SupplierName,
          Status: this.status
        }
      )

      this.searchCondition = binddata
      this.stkoutSelectItem = null
      this.$post(CONFIG.searchURL, binddata, data => {
        this.loading = false
        // this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
        this.$emit('page-loading', false)
        this.gridShow = true
        this.$emit('grid-show', true)
      })
      if (pageIndex === 1) {
        this.$post(CONFIG.InStatisticSearchUrl, binddata, data => {
          this.loading = false
          this.inStatisticSearchsList = data
          if (this.inStatisticSearchsList.length > 0) {
            this.inStatisticSearchsTotal = this.inStatisticSearchsList[0]
          }
        })
      }
    },
    hasRes (code) {
      return _.includes(this.resourceList, code)
    },
    dataDetails (index) {
      if (index === 1) {
        this.detailedvshow = true
      } else {
        this.detailedvshow = false
      }
    },
    onExport () {
      let exportColumns = []
      let selectedColumns = _.filter(this.columns, function (item, index) { return !item.isSystem && (_.isUndefined(item.hasRole) || item.hasRole) && item.IsExprot })
      _.each(selectedColumns, item => {
        exportColumns.push({
          'Name': item.binding,
          'DisplayName': item.header
        })
      })
      this.searchCondition = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.filterFields),
          PageIndex: this.pageIndex,
          PageSize: this.pageSize
        },
        {
          // 外部搜索条件
          StartReceiveTime: this.filterFields.startReceiveTime,
          EndReceiveTime: this.filterFields.endReceiveTime,
          MatchOperation: this.filterFields.MatchOperation,
          SupplierName: this.filterFields.SupplierName,
          StatusJson: JSON.stringify(this.status)
        }
      )
      let postData = {
        'Name': 'StsPurchaseIn',
        'Conditions': this.searchCondition,
        'Columns': exportColumns
      }
      let that = this
      this.loading = true
      this.$post('export/excel', postData, (data, logic) => {
        if (logic.code === 200) {
          that.downloadUrl = `${CONFIG.apiBaseUrl}export/download?id=` + data.Id
          if (this.isClient()) {
            that.clientDownLoadFile(data.Id, that.downloadUrl)
          } else {
            window.open(that.downloadUrl) // 下载文件
          }
          this.loading = false
        }
      }).catch(e => {
        this.loading = false
      })
    },
    clientDownLoadFile (fileName, url) {
      if (!url) { return }
      this.downFile({ FileName: '采购入库统计.xls', FileUrl: url },
        function (res) {
          // console.log(res)
          this.dialogShow = false
        }
      )
    },
    isClient () {
      return window.cefQuery !== undefined
    },
    showExportColumn () {
      this.setExportColumnVisible = true
      this.addColumnsList = this.columns
      this.setConfigColumns = this.defaultConfig
      this.setSaveConfigURL = this.$options.config.saveConfigURL
    },
    onChange (val) {
      if (val) {
        this.isLikeSearch = false
      } else {
        this.isLikeSearch = true
      }
      // this.$emit('bar-search-type', this.searchKeys, this.isLike)
    },
    onChangeStatus (val) {
      this.checkStatus = val
    },
    onQuotedSta () {
      this.envisible = true
      let rows = this.$refs.flexGrid.getSelectedRows()
      this.onQuotedRows = {}
      let systemtime = new Date()
      if (this.hasValue(this.filterFields.startReceiveTime) || this.hasValue(this.filterFields.endReceiveTime)) {
        this.onQuotedRows.StartOutOn = this.filterFields.startReceiveTime
        this.onQuotedRows.EndOutOn = this.filterFields.endReceiveTime
        this.onQuotedRows.CustomerName = this.filterFields.SupplierName
        this.onQuotedRows.CurrencyCode = 'RMB'
        this.onQuotedRows.CurrencyName = '人民币'
      } else if (this.hasValue(this.filterFields.SupplierName) && !this.hasValue(this.filterFields.startReceiveTime) && !this.hasValue(this.filterFields.endReceiveTime)) {
        this.onQuotedRows.CustomerName = this.filterFields.SupplierName
        let dateArr = this.getfromtoDate(systemtime)
        this.onQuotedRows.StartOutOn = dateArr[0]
        this.onQuotedRows.EndOutOn = dateArr[1]
        this.onQuotedRows.CurrencyCode = 'RMB'
        this.onQuotedRows.CurrencyName = '人民币'
      } else if (rows.length > 0) {
        let row = JSON.parse(JSON.stringify(rows[0]))
        this.onQuotedRows.CustomerGUID = row.SupplierID
        this.onQuotedRows.CustomerName = row.SupplierName  // 改字段
        this.onQuotedRows.CurrencyCode = row.CurrencyCode
        this.onQuotedRows.CurrencyName = row.CurrencyName
        // 将日期字符串转为Date
        let strdate = row.ReceiveTime   // 改字段
        let savedate = strdate.replace(/-/g, '/')
        let date = new Date(savedate)
        // global方法获取月头月尾
        let dateArr = this.getfromtoDate(date)
        this.onQuotedRows.StartOutOn = dateArr[0]
        this.onQuotedRows.EndOutOn = dateArr[1]
        this.onQuotedRows.MatchOperation = 'Equal'
      } else {
        let dateArr = this.getfromtoDate(systemtime)
        this.onQuotedRows.StartOutOn = dateArr[0]
        this.onQuotedRows.EndOutOn = dateArr[1]
        this.onQuotedRows.CurrencyCode = 'RMB'
        this.onQuotedRows.CurrencyName = '人民币'
      }
      this.$nextTick(function () {
        this.envisible = true
        this.$refs.create.onQuotedRows(this.onQuotedRows, 2)
      })
    }
  }
}
</script>

<style lang="scss">
.znl-stspurchasein {
  .el-col-12 {
    width: 100%;
  }
  .StsPurchaseIn-footer {
    position: absolute;
    width: 780px;
    bottom: 0;
    z-index: 10;
    left: -5px;
    text-align: left;
    padding: 0px;
  }
  .stsPurchaseIn-currencyCode {
    color: rgb(19, 88, 237);
  }
  .stsPurchaseIn-amount {
    color: rgb(255, 0, 0);
  }
  .detail-div {
    background-color: #fff;
    border: 1px solid #ccc;
    border-bottom: 0;
    padding-top: 5px;
    line-height: 22px;
    padding-left: 0px !important;
  }
  .footer-row-box {
    padding-left: 8px;
  }
}
</style>

