<template>
  <!-- 报价记录 -->
  <znl-gridmodule ref="flexGrid"
                  :header="header"
                  :height="layoutType?height:'100%'"
                  :layout-type="layoutType?layoutType:'spa'"
                  :has-znl-actions="false"
                  :action-btns="actionBtns"
                  :columns="columns"
                  :btn-mouse-enter-menus="btnMouseEnterMenus"
                  :item-source="itemSource"
                  :column-colors="columnColors"
                  :row-colors="rowColors"
                  :is-multi-rows-check="isShowSelect"
                  :summary-columns="summaryColumns"
                  :is-fields-search="isShowFiedsSearch"
                  :search-fields="searchModel"
                  :page-size="pageSize"
                  :page-index="pageIndex"
                  :total-count="totalCount"
                  :role="role"
                  :users="$store.state.users"
                  :is-init="initData"
                  :on-init="onInit"
                  :grid-show="gridShow"
                  :on-refresh="onRefresh"
                  :loading="loading"
                  :on-save-setting="onSaveSetting"
                  :on-save-config='onSaveConfig'
                  :on-reset-setting="onResetSetting"
                  :show-add-row-right-menu="false"
                  :show-delete-row-right-menu="false"
                  :on-search="onSearch"
                  :on-page-changed="onBindData"
                  @selection-changed="d=>{this.currentItem=d, $emit('selection-changed',d)}">
    <div slot="heading-slot">
      <znl-input v-if="modelShow"
                 form-type="input"
                 :disabled="false"
                 placeholder="请输入型号"
                 size="mini"
                 layout="left"
                 width='120px'
                 inp-perc="100%"
                 :clearable="true"
                 :border="true"
                 v-model="inputModel"
                 @keyup.enter.native="$refs.flexGrid.search()"
                 type="text">
      </znl-input>
      <znl-input v-if="dateShow"
                 form-type="datepicker"
                 :disabled="false"
                 placeholder="报价日期"
                 :border="true"
                 size="mini"
                 layout="left"
                 width='100px'
                 :clearable="true"
                 inp-perc="100%"
                 v-model="QuotedTimeByStart">
      </znl-input>
      <span v-if="dateShow">到</span>
      <!-- <el-date-picker size="mini"
                      type="date"
                      v-model="filterFields.createdTimeByEnd"
                      placeholder="报价日期"
                      class="form-group">
      </el-date-picker> -->
      <znl-input v-if="dateShow"
                 form-type="datepicker"
                 :disabled="false"
                 placeholder="报价日期"
                 size="mini"
                 layout="left"
                 width='100px'
                 :border="true"
                 :clearable="true"
                 inp-perc="100%"
                 v-model="QuotedTimeByEnd">
      </znl-input>
      <znl-button type="multiple"
                  :height='22'
                  style-type='mac'
                  class="box-left-btn search-btn">
        <znl-button-menu @click="$refs.flexGrid.search()">
          <i class="iconfont icon-search_ic"></i>
          <span>搜索</span>
        </znl-button-menu>
        <znl-button-menu :width='12'
                         tip="高级搜索"
                         @click="onShowFiedsSearch()">
          <i class="iconfont icon-arrow-drop-down_ic"></i>
        </znl-button-menu>
      </znl-button>

      <znl-button style-type="mac"
                  :btns='printznlbtns'
                  :height='22'
                  @click="onExport"
                  tip="按搜索条件">
        <i class="iconfont icon-leading-out-wh_btn_i"></i>
        <span>导出</span>
      </znl-button>

    </div>
    <div>
      <stk-in-edit :title="StkInAddTitle"
                   :editvisible="stkInEditVisible"
                   v-if="stkInEditVisible"
                   :edit-type="editType"
                   height="500px"
                   @confirm="stkInEditVisible=false"
                   @close="stkInEditVisible=false">
      </stk-in-edit>
      <stk-out-edit :title="StkOutAddTitle"
                    :editvisible="stkOutEditVisible"
                    v-if="stkOutEditVisible"
                    :edit-type="editType"
                    height="500px"
                    @confirm="stkOutEditVisible=false"
                    @close="stkOutEditVisible=false"></stk-out-edit>
    </div>
    <export-column :visible="setExportColumnVisible"
                   :addColumns="addColumnsList"
                   :addConfigColumns="setConfigColumns"
                   :saveConfigURL="setSaveConfigURL"
                   v-if="setExportColumnVisible"
                   title="设置导出列"
                   @close="()=>{setExportColumnVisible=false}"
                   @confirm="(v)=>{setExportColumnVisible=false}">
    </export-column>
  </znl-gridmodule>
</template>
<script>
import { mixin as znlMethodsMinix } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import StkInEdit from '@c_modules/Stk/StkInEdit'
import StkOutEdit from '@c_modules/Stk/StkOutEdit'

import ExportColumn from '@c_modules/Crm/setExportColumn'
import * as urls from '~/lib/urls'

const CONFIG = {
  configURL: 'HisOfferPrice/GetConfig',
  saveConfigURL: 'HisOfferPrice/SaveConfig',
  resetConfigURL: 'HisOfferPrice/ResetConfig',
  searchURL: 'HisOfferPrice/Search',
  TaxRateCodeURL: 'DictItem/GetItemValue',
  apiBaseUrl: urls.getErpApiBaseUrl()
}
export default {
  name: 'WaitStkInList',
  config: CONFIG,
  mixins: [znlMethodsMinix, getCommonDataMixin],
  components: {
    StkInEdit,
    StkOutEdit,
    ExportColumn
  },
  data () {
    return {
      isShowFiedsSearch: false,
      editType: 'edit',
      StkOutAddTitle: '新增发货单',
      StkInAddTitle: '新增入库单',
      stkInEditVisible: false, // 编辑入库单
      stkOutEditVisible: false, // 编辑发货单
      stkstockSelectItem: [], // 选中的库存数据
      inputModel: '', // 需要查询的型号
      QuotedTimeByStart: '',
      QuotedTimeByEnd: '',
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      searchFields: {},
      filterFields: {},
      columnColors: [],
      rowColors: [],
      resourceList: [],
      summaryColumns: [],
      pageSize: 20,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      actionBtns: [],
      isShow: false,
      gridShow: false,
      btnMouseEnterMenus: {
        List: []
      },
      printznlbtns: [
        {
          name: '设置导出列',
          click: () => {
            this.showExportColumn()
          }
        }
      ],
      setExportColumnVisible: false,
      addColumnsList: [],
      setConfigColumns: {},
      setSaveConfigURL: '',
      searchCondition: {},
      loading: false
    }
  },
  computed: {
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    isMini: { type: Boolean, default: () => { return false } },
    layoutType: String,
    searchModel: {
      type: Object,
      default: () => { return {} }
    },
    height: String,
    isFieldsSearch: {
      type: Boolean,
      default: () => { return true }
    },
    hasOperateBtn: { type: Boolean, default: () => { return false } },
    isRefresh: Boolean, // 刷新页面
    header: String,
    isShowSelect: {
      type: Boolean,
      default: false
    },
    btnShow: {
      type: Boolean,
      default: false
    },
    modelShow: {
      type: Boolean,
      default: false
    },
    dateShow: {
      type: Boolean,
      default: false
    },
    openFrom: String
  },
  watch: {
    searchModel (val) {
      this.onBindData()
    },
    itemSource () {
      this.$emit('page-loading', false)
    }
  },
  methods: {
    onInit: async function () {
      let config = await this.isSaveStorage('hisOfferPrice', CONFIG.configURL)
      // let config = await this.$post(CONFIG.configURL)

      this.resourceList = config.ResourceList
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)

      // 报价人
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })

      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'QuotedByName'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )
      // 录入人
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'CreatedName'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )

      // 需求来源
      if (_.find(columns, function (column) { return column.binding === 'RequestSource' })) {
        // this.$post(CONFIG.TaxRateCodeURL, { DictCode: 'RequestSource' }, (data) => {
        //   this.RequestSourceOptions = _.map(data, items => { return { key: items.ItemValue, value: items.ItemName } })
        // })

        let TaxRateCode = await this.$post(CONFIG.TaxRateCodeURL, { DictCode: 'RequestSource' })

        // console.log(123123123, TaxRateCode)
        if (_.isArray(TaxRateCode)) {
          let dateMap = _.map(TaxRateCode, function (obj, index) {
            return { 'key': obj.ItemValue, 'value': obj.ItemName }
          })
          _.extend(_.find(columns, function (column) { return column.binding === 'RequestSource' }),
            { dataMap: dateMap, showDropDown: true })
        }
      }
      // 币种
      let CurrencyNames = _.map(this.selectOptionsCurrencyCode, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'CurrencyName'
        }),
        { dataMap: CurrencyNames, showDropDown: true }
      )
      if (this.hasOperateBtn) {
        this.actionBtns = [
          {
            name: '销售出库',
            isShow: this.hasRes('onAddStkOut'),
            iconName: 'icon-sell-wh_btn_ic',
            click: () => { this.stkOutAddRows() }
          },
          {
            name: '采购入库',
            isShow: this.hasRes('onStkInadd'),
            iconName: 'icon-buy-wh_btn_ic',
            click: () => { this.stkInAddRows() }
          }]
        columns.unshift({// 操作列
          name: 'operate',
          dataType: 5,
          binding: 'operate',
          width: 36,
          header: '操作',
          visible: true,
          isReadOnly: true,
          isFieldsSearch: false,
          isSystem: true,
          align: 'left',
          className: 'znlerp-operate znl-text-center',
          buttons: [
            {
              content: '<i class="iconfont icon-left_nav_show_btn"></i>',
              className: 'znl-table-btn',
              click: () => { }
            }
          ]
        })
        this.btnMouseEnterMenus = {
          List: [
            {
              name: '销售出货',
              isShow: this.hasRes('onAddStkOut'),
              click: row => {
                let guid = []
                guid.push(row.BDLineGUID)
                this.onAddStkOut(guid)
              }
            },
            {
              name: '采购入库',
              isShow: this.hasRes('onStkInadd'),
              click: row => {
                let rows = []
                rows.push(row)
                this.onStkInadd(rows)
              }
            }
          ],
          Binding: 'operate'
        }
      }
      if (this.isShowSelect) { this.isShow = true }

      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.pageSize = 20

      this.initData && this.onBindData()
    },
    onShowFiedsSearch () {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true
      } else {
        this.isShowFiedsSearch = false
      }
    },
    onBindData (pageIndex = 1) {
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.fieldsFilter),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      },
        {
          PriceModel: this.inputModel,
          QuotedTimeByStart: this.QuotedTimeByStart,
          QuotedTimeByEnd: this.QuotedTimeByEnd
        })
      if (!this.btnShow) {
        data.PriceModel = this.searchModel.Model
      }
      this.stkstockSelectItem = []// 选中的库存数据
      // console.log(data)
      this.searchCondition = data
      this.loading = true
      return this.$post(CONFIG.searchURL, data, (data) => {
        this.$emit('page-loading', false)
        this.$emit('set-total-count', data.TotalCount)
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
        this.gridShow = true
        this.loading = false
      })
    },
    onSaveSetting (columns, pageSize) {
      localStorage.removeItem('hisOfferPrice')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, CONFIG.default))
    },
    onResetSetting () {
      localStorage.removeItem('hisOfferPrice')
      return this.$post(CONFIG.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('hisOfferPrice')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler([column], this.pageSize, CONFIG.default))
    },
    onAddStkOut (rows) {
      this.BDLineGuids = rows
      let order = { BDLineGuids: rows }
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.$refs.flexGrid.clearSelection()
        this.stkOutEditVisible = true // 显示编辑页面
      })
    },
    onStkInadd (rows) { // 采购入库传值方法
      this.$store.state.visibled = false
      let order = { stockLine: rows }
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.stkInEditVisible = true
      })
    },
    stkInAddRows () { // 批量采购入库
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length > 0) {
        this.onStkInadd(rows)
        this.clearSelectedRows()
      }
    },
    stkOutAddRows () { // 批量销售出库
      let guid = this.onSelectRow() // 选中行的bdlineguid数组
      if (guid.length > 0) {
        let order = { BDLineGuids: guid }
        this.$store.commit('setParamOrder', order)
        this.$store.state.visibled = false
        this.$nextTick(function () {
          this.$refs.flexGrid.clearSelection()
          this.stkOutEditVisible = true
        })
      }
    },
    onSelectRow () {
      let guid = [] // 选中行的bdlineguid数组
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (_.isUndefined(rows) || rows.length === 0 || _.isUndefined(rows[0])) {
        if (_.isUndefined(this.CurrentItem) || _.isUndefined(this.CurrentItem.BDLineGUID)) {
          // 选中行
          return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
        } else {
          this.$refs.flexGrid.clearSelection()
          guid.push(this.CurrentItem.BDLineGUID)
        }
      } else {
        // 判断选中行的客户是否一致
        var lastCustomerName = rows[0].CustomerName
        var isDiff = false
        _.each(rows, r => {
          if (lastCustomerName !== r.CustomerName) {
            isDiff = true
          }
        })
        if (isDiff) {
          return this.$message({ message: '请选择相同客户的数据进行操作', type: 'error' })
        }
        guid = _.map(rows, item => {
          return item.BDLineGUID
        })
      }
      return guid
    },
    clearSelectedRows () {
      this.$refs.flexGrid.clearSelection()
    },

    onSearch (params) {
      this.fieldsFilter = {}
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    hasRes (code) {
      // console.log(222, code)
      return _.some(this.resourceList, page => page.Code === code)
    },
    onExport () {
      let exportColumns = []
      let selectedColumns = _.filter(this.columns, function (item, index) { return !item.isSystem && (_.isUndefined(item.hasRole) || item.hasRole) && item.IsExprot })
      _.each(selectedColumns, item => {
        exportColumns.push({
          'Name': item.binding,
          'DisplayName': item.header
        })
      })
      let postData = {
        'Name': 'HisOfferPrice',
        'Conditions': this.searchCondition,
        'Columns': exportColumns
      }
      let that = this
      this.log = true
      this.loading = true
      this.$post('export/excel', postData, (data, logic) => {
        if (logic.code === 200) {
          that.downloadUrl = `${CONFIG.apiBaseUrl}export/download?id=` + data.Id
          if (this.isClient()) {
            that.clientDownLoadFile(data.Id, that.downloadUrl)
          } else {
            window.open(that.downloadUrl) // 下载文件
          }
          this.loading = false
        }
      }).catch(e => {
        this.loading = false
      })
    },
    clientDownLoadFile (fileName, url) {
      if (!url) { return }
      this.downFile({ FileName: '报价统计.xls', FileUrl: url },
        function (res) {
          // console.log(res)
          this.dialogShow = false
        }
      )
    },
    isClient () {
      return window.cefQuery !== undefined
    },
    showExportColumn () {
      this.setExportColumnVisible = true
      this.addColumnsList = this.columns
      this.setConfigColumns = this.defaultConfig
      this.setSaveConfigURL = this.$options.config.saveConfigURL
    }
  }
}
</script>

<style lang="scss">

</style>
